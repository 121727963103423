let donationAmount = 0;
let ppfField = undefined;

export function getDonationAmount() {
    return donationAmount;
}

export function setDonationAmount(amount) {
    donationAmount = amount;
    if(ppfField)
        ppfField.onDonationAmountUpdated();
}

export function setAssociatedPPFField(object) {
    ppfField = object;
}

export function getTotalAmount() {
    if(!ppfField)
        return donationAmount;
    else
        return ppfField.getTotalAmount();
}

export function reset() {
    donationAmount = 0;
    ppfField = undefined;
}