import i18next from 'i18next';
import { notification } from 'antd';
import { get } from 'lodash';

import {
  requestApiGet,
  requestApiPut,
  requestApiDelete
} from 'Parts/common/requestUtils';
import { getSessionIp } from 'Parts/session/sessionUtils';
import { getCdnUrl } from "Parts/common/environment";
import { getLanguageIdByKey } from 'Parts/common/langUtils';

const cdnUrl = getCdnUrl();

export const FETCH_PLATFORM_START = 'FETCH_PLATFORM_START';
export const FETCH_PLATFORM_SUCCESS = 'FETCH_PLATFORM_SUCCESS';
export const FETCH_PLATFORM_ERROR = 'FETCH_PLATFORM_ERROR';

export const UPDATE_PLATFORM_START = 'UPDATE_PLATFORM_START';
export const UPDATE_PLATFORM_SUCCESS = 'UPDATE_PLATFORM_SUCCESS';
export const UPDATE_PLATFORM_ERROR = 'UPDATE_PLATFORM_ERROR';

export const FETCH_PLATFORM_MEDIA_START = 'FETCH_PLATFORM_MEDIA_START';
export const FETCH_PLATFORM_MEDIA_SUCCESS = 'FETCH_PLATFORM_MEDIA_SUCCESS';
export const FETCH_PLATFORM_MEDIA_ERROR = 'FETCH_PLATFORM_MEDIA_ERROR';

export const UPDATE_PLATFORM_MEDIA_START = 'UPDATE_PLATFORM_MEDIA_START';
export const UPDATE_PLATFORM_MEDIA_SUCCESS = 'UPDATE_PLATFORM_MEDIA_SUCCESS';
export const UPDATE_PLATFORM_MEDIA_ERROR = 'UPDATE_PLATFORM_MEDIA_ERROR';

export const DELETE_PLATFORM_MEDIA_START = 'DELETE_PLATFORM_MEDIA_START';
export const DELETE_PLATFORM_MEDIA_SUCCESS = 'DELETE_PLATFORM_MEDIA_SUCCESS';
export const DELETE_PLATFORM_MEDIA_ERROR = 'DELETE_PLATFORM_MEDIA_ERROR';

export const FETCH_PLATFORM_DESCRIPTION_START = 'FETCH_PLATFORM_DESCRIPTION_START';
export const FETCH_PLATFORM_DESCRIPTION_SUCCESS = 'FETCH_PLATFORM_DESCRIPTION_SUCCESS';
export const FETCH_PLATFORM_DESCRIPTION_ERROR = 'FETCH_PLATFORM_DESCRIPTION_ERROR';

export const UPDATE_PLATFORM_DESCRIPTION_START = 'UPDATE_PLATFORM_DESCRIPTION_START';
export const UPDATE_PLATFORM_DESCRIPTION_SUCCESS = 'UPDATE_PLATFORM_DESCRIPTION_SUCCESS';
export const UPDATE_PLATFORM_DESCRIPTION_ERROR = 'UPDATE_PLATFORM_DESCRIPTION_ERROR';

export const DELETE_PLATFORM_DESCRIPTION_START = 'DELETE_PLATFORM_DESCRIPTION_START';
export const DELETE_PLATFORM_DESCRIPTION_SUCCESS = 'DELETE_PLATFORM_DESCRIPTION_SUCCESS';
export const DELETE_PLATFORM_DESCRIPTION_ERROR = 'DELETE_PLATFORM_DESCRIPTION_ERROR';

export const FETCH_PLATFORM_LANGUAGES_START = 'FETCH_PLATFORM_LANGUAGES_START';
export const FETCH_PLATFORM_LANGUAGES_SUCCESS = 'FETCH_PLATFORM_LANGUAGES_SUCCESS';
export const FETCH_PLATFORM_LANGUAGES_ERROR = 'FETCH_PLATFORM_LANGUAGES_ERROR';

export const UPDATE_PLATFORM_LANGUAGES_START = 'UPDATE_PLATFORM_LANGUAGES_START';
export const UPDATE_PLATFORM_LANGUAGES_SUCCESS = 'UPDATE_PLATFORM_LANGUAGES_SUCCESS';
export const UPDATE_PLATFORM_LANGUAGES_ERROR = 'UPDATE_PLATFORM_LANGUAGES_ERROR';

export const DELETE_PLATFORM_LANGUAGES_START = 'DELETE_PLATFORM_LANGUAGES_START';
export const DELETE_PLATFORM_LANGUAGES_SUCCESS = 'DELETE_PLATFORM_LANGUAGES_SUCCESS';
export const DELETE_PLATFORM_LANGUAGES_ERROR = 'DELETE_PLATFORM_LANGUAGES_ERROR';

export const FETCH_PLATFORM_SETTINGS_START = 'FETCH_PLATFORM_SETTINGS_START';
export const FETCH_PLATFORM_SETTINGS_SUCCESS = 'FETCH_PLATFORM_SETTINGS_SUCCESS';
export const FETCH_PLATFORM_SETTINGS_ERROR = 'FETCH_PLATFORM_SETTINGS_ERROR';

export const UPDATE_PLATFORM_SETTINGS_START = 'UPDATE_PLATFORM_SETTINGS_START';
export const UPDATE_PLATFORM_SETTINGS_SUCCESS = 'UPDATE_PLATFORM_SETTINGS_SUCCESS';
export const UPDATE_PLATFORM_SETTINGS_ERROR = 'UPDATE_PLATFORM_SETTINGS_ERROR';

export const FETCH_PLATFORM_CONTENT_START = 'FETCH_PLATFORM_CONTENT_START';
export const FETCH_PLATFORM_CONTENT_SUCCESS = 'FETCH_PLATFORM_CONTENT_SUCCESS';
export const FETCH_PLATFORM_CONTENT_ERROR = 'FETCH_PLATFORM_CONTENT_ERROR';

export const UPDATE_PLATFORM_CONTENT_START = 'UPDATE_PLATFORM_CONTENT_START';
export const UPDATE_PLATFORM_CONTENT_SUCCESS = 'UPDATE_PLATFORM_CONTENT_SUCCESS';
export const UPDATE_PLATFORM_CONTENT_ERROR = 'UPDATE_PLATFORM_CONTENT_ERROR';

export const DELETE_PLATFORM_CONTENT_START = 'DELETE_PLATFORM_CONTENT_START';
export const DELETE_PLATFORM_CONTENT_SUCCESS = 'DELETE_PLATFORM_CONTENT_SUCCESS';
export const DELETE_PLATFORM_CONTENT_ERROR = 'DELETE_PLATFORM_CONTENT_ERROR';

export const FETCH_PLATFORM_NOTE_START = 'FETCH_PLATFORM_NOTE_START';
export const FETCH_PLATFORM_NOTE_SUCCESS = 'FETCH_PLATFORM_NOTE_SUCCESS';
export const FETCH_PLATFORM_NOTE_ERROR = 'FETCH_PLATFORM_NOTE_ERROR';

export const UPDATE_PLATFORM_NOTE_START = 'UPDATE_PLATFORM_NOTE_START';
export const UPDATE_PLATFORM_NOTE_SUCCESS = 'UPDATE_PLATFORM_NOTE_SUCCESS';
export const UPDATE_PLATFORM_NOTE_ERROR = 'UPDATE_PLATFORM_NOTE_ERROR';

export const DELETE_PLATFORM_NOTE_START = 'DELETE_PLATFORM_NOTE_START';
export const DELETE_PLATFORM_NOTE_SUCCESS = 'DELETE_PLATFORM_NOTE_SUCCESS';
export const DELETE_PLATFORM_NOTE_ERROR = 'DELETE_PLATFORM_NOTE_ERROR';

export const FETCH_PLATFORM_FEATURED_CAMPAIGNS_START = 'FETCH_PLATFORM_FEATURED_CAMPAIGNS_START';
export const FETCH_PLATFORM_FEATURED_CAMPAIGNS_SUCCESS =
  'FETCH_PLATFORM_FEATURED_CAMPAIGNS_SUCCESS';
export const FETCH_PLATFORM_FEATURED_CAMPAIGNS_ERROR = 'FETCH_PLATFORM_FEATURED_CAMPAIGNS_ERROR';

export const UPDATE_PLATFORM_FEATURED_CAMPAIGNS_START = 'UPDATE_PLATFORM_FEATURED_CAMPAIGNS_START';
export const UPDATE_PLATFORM_FEATURED_CAMPAIGNS_SUCCESS =
  'UPDATE_PLATFORM_FEATURED_CAMPAIGNS_SUCCESS';
export const UPDATE_PLATFORM_FEATURED_CAMPAIGNS_ERROR = 'UPDATE_PLATFORM_FEATURED_CAMPAIGNS_ERROR';

export const FETCH_PLATFORM_SECTION_START = 'FETCH_PLATFORM_SECTION_START';
export const FETCH_PLATFORM_SECTION_SUCCESS = 'FETCH_PLATFORM_SECTION_SUCCESS';
export const FETCH_PLATFORM_SECTION_ERROR = 'FETCH_PLATFORM_SECTION_ERROR';

export const UPDATE_PLATFORM_SECTION_START = 'UPDATE_PLATFORM_SECTION_START';
export const UPDATE_PLATFORM_SECTION_SUCCESS = 'UPDATE_PLATFORM_SECTION_SUCCESS';
export const UPDATE_PLATFORM_SECTION_ERROR = 'UPDATE_PLATFORM_SECTION_ERROR';

export const DELETE_PLATFORM_SECTION_START = 'DELETE_PLATFORM_SECTION_START';
export const DELETE_PLATFORM_SECTION_SUCCESS = 'DELETE_PLATFORM_SECTION_SUCCESS';
export const DELETE_PLATFORM_SECTION_ERROR = 'DELETE_PLATFORM_SECTION_ERROR';

//////////////
// PLATFORM //
//////////////
export function fetchPlatform(plaformAliasOrId, authentication = false, params = {}) {
  return dispatch => {
    dispatch({
      type: FETCH_PLATFORM_START
    });

    // Add params object in the function call
    // params = {
    //   showDescription: true - Display title/description
    //   showPlugins: true - Display plugins subscription
    //   showSettings: true - Display settings.
    //   showMedia: true - Display banners, logo, thumbnail
    //   showChildrenCount: true - Display children count
    // }

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${plaformAliasOrId}`,
      params,
      headers
    )
      .then(payload => {
        return dispatch({
          type: FETCH_PLATFORM_SUCCESS,
          payload
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PLATFORM_ERROR,
          error
        });
      });
  };
}

export function updatePlatform(platformId, values) {
  return dispatch => {
    dispatch({ type: UPDATE_PLATFORM_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPut(
      `/platforms/${platformId}`,
      values,
      headers
    )
      .then(payload => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved')
        });
        return dispatch({
          type: UPDATE_PLATFORM_SUCCESS,
          payload: values
        });
      })
      .then(() => {
        return dispatch(fetchPlatform(platformId, true));
      })
      .catch(error => {
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
          description: error.description || error
        });
        return dispatch({
          type: UPDATE_PLATFORM_ERROR,
          error: error
        });
      });
  };
}

////////////////////
// PLATFORM MEDIA //
////////////////////
export function fetchPlatformMedia(platformId, authentication = false) {
  return dispatch => {
    dispatch({
      type: FETCH_PLATFORM_MEDIA_START
    });

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/media`,
      null,
      headers
    )
      .then(payload => {
        return dispatch({
          type: FETCH_PLATFORM_MEDIA_SUCCESS,
          payload
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PLATFORM_MEDIA_ERROR,
          error
        });
      });
  };
}

export function updatePlatformMedia(platformId, mediaType, mediaArray) {
  return dispatch => {
    dispatch({
      type: UPDATE_PLATFORM_MEDIA_START
    });

    // mediaType = 'banners' || 'logo' || 'thumbnail'

    // mediaArray = [
    //   {
    //     bannersId || logoId || thumbnailId: (int) (for update only)
    //     languageId: (int)*
    //     banner || logo || thumbnail: (data:)
    //     link: (string)
    //     default: (bool)
    //   }
    // ]

    const values = {
      [mediaType]: mediaArray
    };

    const imageKey =
      mediaType === 'banners' ?
      'banner' :
      mediaType === 'logo' ?
      'logo' :
      mediaType === 'thumbnail' ?
      'thumbnail' :
      null;

    const deleteValues = values[mediaType].filter(value => value[imageKey] === null);

    if (deleteValues.length) {
      let i = deleteValues.length;
      while (i--) {
        dispatch(
          deletePlatformMedia(
            platformId,
            mediaType,
            deleteValues[i].languageId,
            deleteValues[i].bannerId || null
          )
        );
      }
    }

    if (values[mediaType].length !== deleteValues.length) {
      const headers = {
        ...dispatch(getSessionIp())
      };

      return requestApiPut(
        `/platforms/${platformId}/media`,
        values,
        headers
      )
        .then(payload => {
          notification.success({
            message: i18next.t('API_NOTIFICATIONS:success'),
            description: i18next.t('API_NOTIFICATIONS:modifications-saved')
          });
          return dispatch({
            type: UPDATE_PLATFORM_MEDIA_SUCCESS,
            payload: payload
          });
        })
        .then(() => {
          return dispatch(fetchPlatformMedia(platformId, true));
        })
        .catch(error => {
          notification.error({
            message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
            description: error.description || error
          });
          return dispatch({
            type: UPDATE_PLATFORM_MEDIA_ERROR,
            error: error
          });
        });
    } else {
      dispatch({
        type: UPDATE_PLATFORM_MEDIA_SUCCESS
      });
      return dispatch(fetchPlatformMedia(platformId, true));
    }
  };
}

export function deletePlatformMedia(platformId, mediaType, languageId, id = null) {
  return dispatch => {
    dispatch({
      type: DELETE_PLATFORM_MEDIA_START
    });

    // mediaType = 'banners' || 'logo' || 'thumbnail'

    const values = {
      [mediaType]: {
        languageId: languageId,
        ...(id && mediaType === 'banners' ?
          {
            bannerId: id
          } :
          {})
      }
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiDelete(
      `/platforms/${platformId}/media`,
      values,
      headers
    )
      .then(payload => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved')
        });
        return dispatch({
          type: DELETE_PLATFORM_MEDIA_SUCCESS,
          payload: values
        });
      })
      .then(() => {
        return dispatch(fetchPlatformMedia(platformId, true));
      })
      .catch(error => {
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
          description: error.description || error
        });
        return dispatch({
          type: DELETE_PLATFORM_MEDIA_ERROR,
          error: error
        });
      });
  };
}

//////////////////////////
// PLATFORM DESCRIPTION //
//////////////////////////
export function fetchPlatformDescription(platformId, authentication = false) {
  return dispatch => {
    dispatch({
      type: FETCH_PLATFORM_DESCRIPTION_START
    });

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/description`,
      null,
      headers
    )
      .then(payload => {
        return dispatch({
          type: FETCH_PLATFORM_DESCRIPTION_SUCCESS,
          payload
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PLATFORM_DESCRIPTION_ERROR,
          error
        });
      });
  };
}

export function updatePlatformDescription(platformId, values) {
  return dispatch => {
    if (values[0].title === null && values[0].description === null) {
      return dispatch(deletePlatformDescription(platformId, values[0].languageId));
    } else {
      dispatch({ type: UPDATE_PLATFORM_DESCRIPTION_START });

      const headers = {
        ...dispatch(getSessionIp())
      };

      return requestApiPut(
        `/platforms/${platformId}/description`,
        values,
        headers
      )
        .then(() => {
          return dispatch(fetchPlatformDescription(platformId, true));
        })
        .then(() => {
          notification.success({
            message: i18next.t('API_NOTIFICATIONS:success'),
            description: i18next.t('API_NOTIFICATIONS:modifications-saved')
          });
          return dispatch({
            type: UPDATE_PLATFORM_DESCRIPTION_SUCCESS
          });
        })
        .catch(error => {
          notification.error({
            message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
            description: error.description || error
          });
          return dispatch({
            type: UPDATE_PLATFORM_DESCRIPTION_ERROR,
            error: error
          });
        });
    }
  };
}

export function deletePlatformDescription(platformId, languageId = null) {
  return dispatch => {
    dispatch({
      type: DELETE_PLATFORM_DESCRIPTION_START
    });

    const values = {
      ...(languageId ?
        {
          languageId: languageId
        } :
        {})
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiDelete(
      `/platforms/${platformId}/description`,
      values,
      headers
    )
      .then(() => {
        return dispatch(fetchPlatformDescription(platformId, true));
      })
      .then(() => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved')
        });
        return dispatch({
          type: DELETE_PLATFORM_DESCRIPTION_SUCCESS
        });
      })
      .catch(error => {
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
          description: error.description || error
        });
        return dispatch({
          type: DELETE_PLATFORM_DESCRIPTION_ERROR,
          error: error
        });
      });
  };
}

////////////////////////
// PLATFORM LANGUAGES //
////////////////////////
export function fetchPlatformLanguages(platformId, authentication = false) {
  return dispatch => {
    dispatch({
      type: FETCH_PLATFORM_LANGUAGES_START
    });

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/languages`,
      null,
      headers
    )
      .then(payload => {
        return dispatch({
          type: FETCH_PLATFORM_LANGUAGES_SUCCESS,
          payload
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PLATFORM_LANGUAGES_ERROR,
          error
        });
      });
  };
}

export function updatePlatformLanguages(platformId, values) {
  return dispatch => {
    dispatch({
      type: UPDATE_PLATFORM_LANGUAGES_START
    });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPut(
      `/platforms/${platformId}/languages`,
      values,
      headers
    )
      .then(payload => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved')
        });
        return dispatch({
          type: UPDATE_PLATFORM_LANGUAGES_SUCCESS,
          payload: values
        });
      })
      .then(() => {
        return dispatch(fetchPlatformLanguages(platformId, true));
      })
      .catch(error => {
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
          description: error.description || error
        });
        return dispatch({
          type: UPDATE_PLATFORM_LANGUAGES_ERROR,
          error: error
        });
      });
  };
}

export function deletePlatformLanguages(platformId, lanuageId = null) {
  return dispatch => {
    dispatch({
      type: DELETE_PLATFORM_LANGUAGES_START
    });

    const values = {
      ...(lanuageId ? { lanuageId: lanuageId } : {})
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiDelete(
      `/platforms/${platformId}/languages`,
      values,
      headers
    )
      .then(payload => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved')
        });
        return dispatch({
          type: DELETE_PLATFORM_LANGUAGES_SUCCESS,
          payload: values
        });
      })
      .then(() => {
        return dispatch(fetchPlatformLanguages(platformId, true));
      })
      .catch(error => {
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
          description: error.description || error
        });
        return dispatch({
          type: DELETE_PLATFORM_LANGUAGES_ERROR,
          error: error
        });
      });
  };
}

///////////////////////
// PLATFORM SETTINGS //
///////////////////////
export function fetchPlatformSettings(platformId, authentication = false) {
  return dispatch => {
    dispatch({
      type: FETCH_PLATFORM_SETTINGS_START
    });

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/settings`,
      null,
      headers
    )
      .then(payload => {
        return dispatch({
          type: FETCH_PLATFORM_SETTINGS_SUCCESS,
          payload
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PLATFORM_SETTINGS_ERROR,
          error
        });
      });
  };
}

export function updatePlatformSettings(platformId, values) {
  return dispatch => {
    dispatch({
      type: UPDATE_PLATFORM_SETTINGS_START
    });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPut(
      `/platforms/${platformId}/settings`,
      values,
      headers
    )
      .then(payload => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved')
        });
        return dispatch({
          type: UPDATE_PLATFORM_SETTINGS_SUCCESS,
          payload: values
        });
      })
      .then(() => {
        return dispatch(fetchPlatformSettings(platformId, true));
      })
      .catch(error => {
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
          description: error.description || error
        });
        return dispatch({
          type: UPDATE_PLATFORM_SETTINGS_ERROR,
          error: error
        });
      });
  };
}

//////////////////////
// PLATFORM CONTENT //
//////////////////////
export function fetchPlatformContent(platformId, authentication = false) {
  return dispatch => {
    dispatch({ type: FETCH_PLATFORM_CONTENT_START });

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/content`,
      null,
      headers
    )
      .then(content => {
        return dispatch({
          type: FETCH_PLATFORM_CONTENT_SUCCESS,
          payload: content
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PLATFORM_CONTENT_ERROR,
          error: error
        });
      });
  };
}

export function updatePlatformContent(platformId, content, languageId = 1, contentTypeId = 1) {
  return dispatch => {
    dispatch({
      type: UPDATE_PLATFORM_CONTENT_START
    });

    // Content Type ID
    // 1 === Refund Policy
    // 2 === Guidelines

    const values = {
      content,
      languageId: typeof languageId === 'string' ? getLanguageIdByKey(languageId) : languageId,
      contentTypeId
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPut(
      `/platforms/${platformId}/content`,
      values ? [{ ...values }] : [],
      headers
    )
      .then(content => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved')
        });
        return dispatch({
          type: UPDATE_PLATFORM_CONTENT_SUCCESS,
          payload: content
        });
      })
      .then(() => {
        return dispatch(fetchPlatformContent(platformId, true));
      })
      .catch(error => {
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
          description: error.description || error
        });
        return dispatch({
          type: UPDATE_PLATFORM_CONTENT_ERROR,
          error: error
        });
      });
  };
}

export function deletePlatformContent(platformId, contentTypeId = 1, languageId = 1) {
  return dispatch => {
    dispatch({ type: DELETE_PLATFORM_CONTENT_START });

    // Content Type ID
    // 1 === Refund Policy
    // 2 === Guidelines

    const values = {
      contentTypeId: contentTypeId,
      languageId: languageId
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiDelete(
      `/platforms/${platformId}/content`,
      values ? values : {},
      headers
    )
      .then(content => {
        notification.success({ message: i18next.t('API_NOTIFICATIONS:success'), description: i18next.t('API_NOTIFICATIONS:modifications-saved') });
        return dispatch({ type: DELETE_PLATFORM_CONTENT_SUCCESS, payload: content })
      }).then(() => {
        return dispatch(fetchPlatformContent(platformId, true));
      }).catch(error => {
        notification.error({ message: i18next.t('API_NOTIFICATIONS:modifications-failed'), description: error.description || error });
        return dispatch({type: DELETE_PLATFORM_CONTENT_ERROR, error: error});
      });
  }
}

  ////////////////////
 // PLATFORM NOTES //
////////////////////
export function fetchPlatformNote(platformId, authentication = false) {
  return dispatch => {
    dispatch({ type: FETCH_PLATFORM_NOTE_START });

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/notes`,
      null,
      headers
    )
      .then(notes => {
        return dispatch({ type: FETCH_PLATFORM_NOTE_SUCCESS, payload: notes });
      })
      .catch(error => {
        return dispatch({type: FETCH_PLATFORM_NOTE_ERROR, error: error});
      });
  }
}

export function updatePlatformNote(platformId, note, languageId = 1, noteTypeId = 1) {
  return dispatch => {
    dispatch({ type: UPDATE_PLATFORM_NOTE_START });

    // Note Type ID
    // 1 ===

    const values = {
      note,
      languageId: typeof(languageId) === 'string' ? getLanguageIdByKey(languageId) : languageId,
      noteTypeId,
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPut(
      `/platforms/${platformId}/notes`,
      values ? [{ ...values }] : [],
      headers
    )
      .then(note => {
        notification.success({ message: i18next.t('API_NOTIFICATIONS:success'), description: i18next.t('API_NOTIFICATIONS:modifications-saved') });
        return dispatch({ type: UPDATE_PLATFORM_NOTE_SUCCESS, payload: note })
      }).then(() => {
        return dispatch(fetchPlatformNote(platformId, true));
      }).catch(error => {
        notification.error({ message: i18next.t('API_NOTIFICATIONS:modifications-failed'), description: error.description || error });
        return dispatch({type: UPDATE_PLATFORM_NOTE_ERROR, error: error});
      });
  }
}

export function deletePlatformNote(platformId, noteTypeId = 1, languageId = 1 ) {
  return dispatch => {
    dispatch({ type: DELETE_PLATFORM_NOTE_START });

    // Note Type ID
    // 1 ===

    const values = {
      noteTypeId: noteTypeId,
      languageId: languageId
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiDelete(
      `/platforms/${platformId}/notes`,
      values ? values : {},
      headers
    )
      .then(note => {
        notification.success({ message: i18next.t('API_NOTIFICATIONS:success'), description: i18next.t('API_NOTIFICATIONS:modifications-saved') });
        return dispatch({ type: DELETE_PLATFORM_NOTE_SUCCESS, payload: note })
      }).then(() => {
        return dispatch(fetchPlatformNote(platformId, true));
      }).catch(error => {
        notification.error({ message: i18next.t('API_NOTIFICATIONS:modifications-failed'), description: error.description || error });
        return dispatch({type: DELETE_PLATFORM_NOTE_ERROR, error: error});
      });
  };
}

/////////////////////////////////
// PLATFORM FEATURED CAMPAIGNS //
/////////////////////////////////
export function fetchPlatformFeaturedCampaigns(platformId) {
  return dispatch => {
    dispatch({
      type: FETCH_PLATFORM_FEATURED_CAMPAIGNS_START
    });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/featured/campaigns`,
      null,
      headers
    )
      .then(result => {
        const payload = result.results.map(campaign => ({
          ...campaign,
          goal: Number(get(campaign, 'goal', 0).toString().replace('NaN', 0)),
          collected: Number(get(campaign, 'collected', 0).toString().replace('NaN', 0)),
          avatar: `${cdnUrl}${campaign.banner.banner}`
        }));
        return dispatch({
          type: FETCH_PLATFORM_FEATURED_CAMPAIGNS_SUCCESS,
          payload
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PLATFORM_FEATURED_CAMPAIGNS_ERROR,
          error: error
        });
      });
  };
}

export function updatePlatformFeaturedCampaigns(platformId, values, sectionValues, languageId) {
  return dispatch => {
    dispatch({
      type: UPDATE_PLATFORM_FEATURED_CAMPAIGNS_START
    });

    let sectionValuesFeatured;
    if (sectionValues) {
      sectionValuesFeatured = [{
        sectionTypeId: 1,
        languageId: getLanguageIdByKey(languageId),
        title: sectionValues.title === '' ? null : sectionValues.title || undefined,
        description: sectionValues.description === '' ? null : sectionValues.description ||
          undefined,
        default: sectionValues.default
      }];
    }

    if (values || sectionValues) {
      const headers = {
        ...dispatch(getSessionIp())
      };

      return Promise.all([
        ...(values
          ? [requestApiPut(
              `/platforms/${platformId}/featured/campaigns`,
              values,
              headers
            )]
          : []
        ),
        ...(sectionValues
          ? !sectionValuesFeatured[0].title && !sectionValuesFeatured[0].description
            ? [dispatch(deletePlatformSections(platformId, sectionValuesFeatured.sectionTypeId, sectionValuesFeatured.languageId))]
            : [dispatch(updatePlatformSections(platformId, sectionValuesFeatured))]
          : []
        )
      ])
        .then(() => dispatch(fetchPlatformFeaturedCampaigns(platformId)))
        .then(() => {
          notification.success({
            message: i18next.t('API_NOTIFICATIONS:success'),
            description: i18next.t('API_NOTIFICATIONS:modifications-saved')
          });
          return dispatch({
            type: UPDATE_PLATFORM_FEATURED_CAMPAIGNS_SUCCESS
          });
        })
        .catch(error => {
          notification.error({
            message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
            description: error.description || error
          });
          return dispatch({
            type: UPDATE_PLATFORM_FEATURED_CAMPAIGNS_ERROR,
            error: error
          });
        });
    } else {
      return dispatch({
        type: UPDATE_PLATFORM_FEATURED_CAMPAIGNS_ERROR
      });
    }
  };
}

///////////////////////
// PLATFORM SECTIONS //
///////////////////////
export function fetchPlatformSections(platformId, authentication = false) {
  return dispatch => {
    dispatch({ type: FETCH_PLATFORM_SECTION_START });

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/section`,
      null,
      headers
    )
      .then(content => {
        return dispatch({
          type: FETCH_PLATFORM_SECTION_SUCCESS,
          payload: content
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PLATFORM_SECTION_ERROR,
          error: error
        });
      });
  };
}

export function updatePlatformSections(platformId, values) {
  return dispatch => {
    dispatch({
      type: UPDATE_PLATFORM_SECTION_START
    });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPut(
      `/platforms/${platformId}/section`,
      values,
      headers
    )
      .then(() => dispatch(fetchPlatformSections(platformId, true)))
      .then(() => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved')
        });
        return dispatch({
          type: UPDATE_PLATFORM_SECTION_SUCCESS
        });
      })
      .catch(error => {
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
          description: error.description || error
        });
        return dispatch({
          type: UPDATE_PLATFORM_SECTION_ERROR,
          error: error
        });
      });
  };
}

export function deletePlatformSections(platformId, sectionTypeId, languageId) {
  return dispatch => {
    dispatch({
      type: DELETE_PLATFORM_SECTION_START
    });

    const values = {
      sectionTypeId,
      languageId
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPut(
      `/platforms/${platformId}/section`,
      values,
      headers
    )
      .then(content => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved')
        });
        return dispatch({
          type: DELETE_PLATFORM_SECTION_SUCCESS,
          payload: content
        });
      })
      .catch(error => {
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
          description: error.description || error
        });
        return dispatch({
          type: DELETE_PLATFORM_SECTION_ERROR,
          error: error
        });
      });
  };
}
