import { get } from "lodash";

import {
  fetchPlatformDescription,
  fetchPlatformContent,
  fetchPlatformMedia,
  fetchPlatformFeaturedCampaigns,
  fetchPlatform,
  fetchPlatformSections,
  fetchPlatformLanguages,
  fetchPlatformNote
} from '../platform/platformActions';
import {
  fetchCampaignList,
  fetchCampaign,
  fetchCampaignDescription,
  fetchCampaignMedia,
  fetchCampaignSettings,
  fetchCampaignLogs,
  fetchCampaignNote
} from '../campaign/campaignActions';
import {
  fetchLastDonationsByPlatformId,
  fetchTransactionById,
  fetchTransactionLogs,
} from "../donation/donationActions";
import {
  fetchParticipant,
  fetchParticipantDescription,
  fetchParticipantSettings,
  fetchTeamParticipants,
  fetchCampaignParticipants,
  fetchMemberParticipations,
  fetchParticipantLogs,
  fetchParticipantByMemberId
} from '../participant/participantActions';
import {
  fetchTeams,
  fetchTeam,
  fetchTeamDescription,
  fetchTeamSettings,
  fetchTeamLogs
} from '../team/teamActions';
import {
  fetchPublicInformation
} from "../common/publicActions";
import {
  searchCampaigns,
  searchTeams,
  searchTeamsInCampaign,
  searchParticipants,
  searchParticipantsInCampaign,
  searchParticipantsInTeam,
  searchDonations,
  searchTransactions,
  searchTransactionsInCampaign,
  searchParticipations,
  searchMemberTransactions,
  searchMembers,
  searchPlatformMembersParticipations,
  searchPlatformMembersTransactions,
  searchPlugins,
  searchSettlementReports,
} from "../search/searchActions";
import {
  fetchCountries,
  fetchProvinces
} from "Parts/geo/geoActions";
import {
  checkForgotPasswordKey,
  checkRegistrationConfirmationKey,
  fetchMemberPlatformPermissions,
  fetchMemberCampaignPermissions,
  fetchMemberTeamPermissions,
  fetchMemberRoles,
  fetchMemberOrganizationRoles,
  fetchMemberPlatformRoles,
  fetchMemberCampaignRoles,
  isCampaignOwner,
} from "Parts/session/sessionActions";
import {
  DEFAULT_PAGER,
  DEFAULT_PAGER_SEARCH_PAGE
} from "Parts/search/searchUtils";
import {
  fetchPlatformMemberById,
  fetchMemberById
} from '../member/memberActions';
import {
  getAlgoliaToken
} from '../algolia/algoliaActions';

const nothing = (log) => {
  if (log) console.log("NOTHING - " + log);
  return {
    type: null
  };
};

const isPlatformAdmin = (roles) => {
  if (roles && roles.platform) {
    return roles.platform.SUPERADMIN || roles.platform.OWNER || false;
  }
  return false;
}

export default [{
    match: {
      path: '/login',
      exact: true
    },
    actionCreators: [
    ]
  },
  {
    match: {
      path: '/en/connect/twitch/:token',
      exact: true
    },
    actionCreators: [
    ]
  },
  {
    match: {
      path: '/fr/connect/twitch/:token',
      exact: true
    },
    actionCreators: [
    ]
  },
  {
    match: {
      path: '/en/login',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatform(
        get(state, 'platform.platform.id'),
        false,
        {
          showDescription: true,
          showMedia: true
        }
      ),
      (params, state) => fetchPlatformFeaturedCampaigns(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformSections(get(state, 'platform.platform.id')),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => fetchLastDonationsByPlatformId(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/fr/connexion',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatform(
        get(state, 'platform.platform.id'),
        false,
        {
          showDescription: true,
          showMedia: true
        }
      ),
      (params, state) => fetchPlatformFeaturedCampaigns(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformSections(get(state, 'platform.platform.id')),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => fetchLastDonationsByPlatformId(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/en/create-an-account',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatform(
        get(state, 'platform.platform.id'), false, {
          showDescription: true,
          showMedia: true
        }
      ),
      (params, state) => fetchPlatformFeaturedCampaigns(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformSections(get(state, 'platform.platform.id')),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => fetchLastDonationsByPlatformId(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/fr/creer-un-compte',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatform(
        get(state, 'platform.platform.id'), false, {
          showDescription: true,
          showMedia: true
        }
      ),
      (params, state) => fetchPlatformFeaturedCampaigns(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformSections(get(state, 'platform.platform.id')),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => fetchLastDonationsByPlatformId(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/en/create-an-account/confirmation',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatform(
        get(state, 'platform.platform.id'), false, {
          showDescription: true,
          showMedia: true
        }
      ),
      (params, state) => fetchPlatformFeaturedCampaigns(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformSections(get(state, 'platform.platform.id')),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => fetchLastDonationsByPlatformId(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/fr/creer-un-compte/confirmation',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatform(
        get(state, 'platform.platform.id'), false, {
          showDescription: true,
          showMedia: true
        }
      ),
      (params, state) => fetchPlatformFeaturedCampaigns(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformSections(get(state, 'platform.platform.id')),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => fetchLastDonationsByPlatformId(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/en/account-recovery',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatform(
        get(state, 'platform.platform.id'), false, {
          showDescription: true,
          showMedia: true
        }
      ),
      (params, state) => fetchPlatformFeaturedCampaigns(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformSections(get(state, 'platform.platform.id')),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => fetchLastDonationsByPlatformId(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/fr/reinitialisation-mot-de-passe',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatform(
        get(state, 'platform.platform.id'), false, {
          showDescription: true,
          showMedia: true
        }
      ),
      (params, state) => fetchPlatformFeaturedCampaigns(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformSections(get(state, 'platform.platform.id')),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => fetchLastDonationsByPlatformId(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/en/create-a-campaign',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => {
        let userTypeId = get(state, 'session.userTypeId')
        let userId = get(state, 'session.userId');
        return (userTypeId && userTypeId > 1)
          ? fetchMemberRoles(userId)
          : nothing()
      },
      (params, state) => {
        let userTypeId = get(state, 'session.userTypeId')
        let userId = get(state, 'session.userId');
        return (userTypeId && userTypeId > 1)
          ? fetchMemberOrganizationRoles(
              get(state, 'organization.organization.id'),
              userId
            )
          : nothing()
      },
      (params, state) => {
        let userTypeId = get(state, 'session.userTypeId')
        let userId = get(state, 'session.userId');
        return (userTypeId && userTypeId > 1)
          ? fetchMemberPlatformRoles(
              get(state, 'platform.platform.id'),
              userId
            )
          : nothing()
      },
    ]
  },
  {
    match: {
      path: '/fr/creer-une-campagne',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => {
        let userTypeId = get(state, 'session.userTypeId')
        let userId = get(state, 'session.userId');
        return (userTypeId && userTypeId > 1)
          ? fetchMemberRoles(userId)
          : nothing()
      },
      (params, state) => {
        let userTypeId = get(state, 'session.userTypeId')
        let userId = get(state, 'session.userId');
        return (userTypeId && userTypeId > 1)
          ? fetchMemberOrganizationRoles(
              get(state, 'organization.organization.id'),
              userId
            )
          : nothing()
      },
      (params, state) => {
        let userTypeId = get(state, 'session.userTypeId')
        let userId = get(state, 'session.userId');
        return (userTypeId && userTypeId > 1)
          ? fetchMemberPlatformRoles(
              get(state, 'platform.platform.id'),
              userId
            )
          : nothing()
      },
    ]
  },
  {
    match: {
      path: '/en/create-a-team',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        get(state, 'search.campaigns.search', null),
        get(state, 'search.campaigns.filters', {}),
        get(state, 'search.campaigns.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.campaigns.orderBy', null),
        false, true
      ),
    ]
  },
  {
    match: {
      path: '/fr/creer-une-equipe',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        get(state, 'search.campaigns.search', null),
        get(state, 'search.campaigns.filters', {}),
        get(state, 'search.campaigns.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.campaigns.orderBy', null),
        false, true
      ),
    ]
  },
  {
    match: {
      path: '/en/participant-registration',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        get(state, 'search.campaigns.search', null),
        get(state, 'search.campaigns.filters', {}),
        get(state, 'search.campaigns.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.campaigns.orderBy', null),
        false,
        true
      ),
    ]
  },
  {
    match: {
      path: '/fr/inscription-participant',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        get(state, 'search.campaigns.search', null),
        get(state, 'search.campaigns.filters', {}),
        get(state, 'search.campaigns.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.campaigns.orderBy', null),
        false,
        true
      ),
    ]
  },
  {
    match: {
      path: '/en/search/campaigns',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state, qs, from) => searchCampaigns(
        get(state, 'platform.platform.id'),
        get(state, 'search.campaigns.search', null),
        get(state, 'search.campaigns.filters', {}),
        get(state, 'search.campaigns.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.campaigns.orderBy', null),
        false,
        false,
        from === 'donation'
      ),
      (params, state, qs, from) => searchTeams(
        get(state, 'platform.platform.id'),
        get(state, 'search.teams.search', null),
        get(state, 'search.teams.filters', {}),
        get(state, 'search.teams.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.teams.orderBy', null),
        false,
        false,
        from === 'donation'
      ),
      (params, state, qs, from) => searchParticipants(
        get(state, 'platform.platform.id'),
        get(state, 'search.participants.search', null),
        get(state, 'search.participants.filters', {}),
        get(state, 'search.participants.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.participants.orderBy', null),
        false,
        false,
        from === 'donation'
      ),
    ]
  },
  {
    match: {
      path: '/en/give/campaigns',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state, qs, from) => searchCampaigns(
        get(state, 'platform.platform.id'),
        get(state, 'search.campaigns.search', null),
        get(state, 'search.campaigns.filters', {}),
        get(state, 'search.campaigns.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.campaigns.orderBy', null),
        false,
        false,
        true
      ),
      (params, state, qs, from) => searchTeams(
        get(state, 'platform.platform.id'),
        get(state, 'search.teams.search', null),
        get(state, 'search.teams.filters', {}),
        get(state, 'search.teams.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.teams.orderBy', null),
        false,
        false,
        true
      ),
      (params, state, qs, from) => searchParticipants(
        get(state, 'platform.platform.id'),
        get(state, 'search.participants.search', null),
        get(state, 'search.participants.filters', {}),
        get(state, 'search.participants.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.participants.orderBy', null),
        false,
        false,
        true
      ),
    ]
  },
  {
    match: {
      path: '/fr/recherche/campagnes',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state, qs, from) => searchCampaigns(
        get(state, 'platform.platform.id'),
        get(state, 'search.campaigns.search', null),
        get(state, 'search.campaigns.filters', {}),
        get(state, 'search.campaigns.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.campaigns.orderBy', null),
        false,
        false,
        from === 'donation'
      ),
      (params, state, qs, from) => searchTeams(
        get(state, 'platform.platform.id'),
        get(state, 'search.teams.search', null),
        get(state, 'search.teams.filters', {}),
        get(state, 'search.teams.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.teams.orderBy', null),
        false,
        false,
        from === 'donation'
      ),
      (params, state, qs, from) => searchParticipants(
        get(state, 'platform.platform.id'),
        get(state, 'search.participants.search', null),
        get(state, 'search.participants.filters', {}),
        get(state, 'search.participants.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.participants.orderBy', null),
        false,
        false,
        from === 'donation'
      ),
    ]
  },
  {
    match: {
      path: '/fr/donner/campagnes',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state, qs, from) => searchCampaigns(
        get(state, 'platform.platform.id'),
        get(state, 'search.campaigns.search', null),
        get(state, 'search.campaigns.filters', {}),
        get(state, 'search.campaigns.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.campaigns.orderBy', null),
        false,
        false,
        true
      ),
      (params, state, qs, from) => searchTeams(
        get(state, 'platform.platform.id'),
        get(state, 'search.teams.search', null),
        get(state, 'search.teams.filters', {}),
        get(state, 'search.teams.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.teams.orderBy', null),
        false,
        false,
        true
      ),
      (params, state, qs, from) => searchParticipants(
        get(state, 'platform.platform.id'),
        get(state, 'search.participants.search', null),
        get(state, 'search.participants.filters', {}),
        get(state, 'search.participants.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.participants.orderBy', null),
        false,
        false,
        true
      ),
    ]
  },
  {
    match: {
      path: '/en/search/teams',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state, qs, from) => searchCampaigns(
        get(state, 'platform.platform.id'),
        get(state, 'search.campaigns.search', null),
        get(state, 'search.campaigns.filters', {}),
        get(state, 'search.campaigns.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.campaigns.orderBy', null),
        false,
        false,
        from === 'donation'
      ),
      (params, state, qs, from) => searchTeams(
        get(state, 'platform.platform.id'),
        get(state, 'search.teams.search', null),
        get(state, 'search.teams.filters', {}),
        get(state, 'search.teams.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.teams.orderBy', null),
        false,
        false,
        from === 'donation'
      ),
      (params, state, qs, from) => searchParticipants(
        get(state, 'platform.platform.id'),
        get(state, 'search.participants.search', null),
        get(state, 'search.participants.filters', {}),
        get(state, 'search.participants.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.participants.orderBy', null),
        false,
        false,
        from === 'donation'
      ),
    ]
  },
  {
    match: {
      path: '/en/give/teams',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state, qs, from) => searchCampaigns(
        get(state, 'platform.platform.id'),
        get(state, 'search.campaigns.search', null),
        get(state, 'search.campaigns.filters', {}),
        get(state, 'search.campaigns.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.campaigns.orderBy', null),
        false,
        false,
        true
      ),
      (params, state, qs, from) => searchTeams(
        get(state, 'platform.platform.id'),
        get(state, 'search.teams.search', null),
        get(state, 'search.teams.filters', {}),
        get(state, 'search.teams.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.teams.orderBy', null),
        false,
        false,
        true
      ),
      (params, state, qs, from) => searchParticipants(
        get(state, 'platform.platform.id'),
        get(state, 'search.participants.search', null),
        get(state, 'search.participants.filters', {}),
        get(state, 'search.participants.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.participants.orderBy', null),
        false,
        false,
        true
      ),
    ]
  },
  {
    match: {
      path: '/fr/recherche/equipes',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state, qs, from) => searchCampaigns(
        get(state, 'platform.platform.id'),
        get(state, 'search.campaigns.search', null),
        get(state, 'search.campaigns.filters', {}),
        get(state, 'search.campaigns.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.campaigns.orderBy', null),
        false,
        false,
        from === 'donation'
      ),
      (params, state, qs, from) => searchTeams(
        get(state, 'platform.platform.id'),
        get(state, 'search.teams.search', null),
        get(state, 'search.teams.filters', {}),
        get(state, 'search.teams.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.teams.orderBy', null),
        false,
        false,
        from === 'donation'
      ),
      (params, state, qs, from) => searchParticipants(
        get(state, 'platform.platform.id'),
        get(state, 'search.participants.search', null),
        get(state, 'search.participants.filters', {}),
        get(state, 'search.participants.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.participants.orderBy', null),
        false,
        false,
        from === 'donation'
      ),
    ]
  },
  {
    match: {
      path: '/fr/donner/equipes',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state, qs, from) => searchCampaigns(
        get(state, 'platform.platform.id'),
        get(state, 'search.campaigns.search', null),
        get(state, 'search.campaigns.filters', {}),
        get(state, 'search.campaigns.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.campaigns.orderBy', null),
        false,
        false,
        true
      ),
      (params, state, qs, from) => searchTeams(
        get(state, 'platform.platform.id'),
        get(state, 'search.teams.search', null),
        get(state, 'search.teams.filters ', {}),
        get(state, 'search.teams.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.teams.orderBy', null),
        false,
        false,
        true
      ),
      (params, state, qs, from) => searchParticipants(
        get(state, 'platform.platform.id'),
        get(state, 'search.participants.search', null),
        get(state, 'search.participants.filters', {}),
        get(state, 'search.participants.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.participants.orderBy', null),
        false,
        false,
        true
      ),
    ]
  },
  {
    match: {
      path: '/en/search/participants',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state, qs, from) => searchCampaigns(
        get(state, 'platform.platform.id'),
        get(state, 'search.campaigns.search', null),
        get(state, 'search.campaigns.filters', {}),
        get(state, 'search.campaigns.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.campaigns.orderBy', null),
        false,
        false,
        from === 'donation'
      ),
      (params, state, qs, from) => searchTeams(
        get(state, 'platform.platform.id'),
        get(state, 'search.teams.search', null),
        get(state, 'search.teams.filters', {}),
        get(state, 'search.teams.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.teams.orderBy', null),
        false,
        false,
        from === 'donation'
      ),
      (params, state, qs, from) => searchParticipants(
        get(state, 'platform.platform.id'),
        get(state, 'search.participants.search', null),
        get(state, 'search.participants.filters', {}),
        get(state, 'search.participants.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.participants.orderBy', null),
        false,
        false,
        from === 'donation'
      ),
    ]
  },
  {
    match: {
      path: '/en/give/participants',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state, qs, from) => searchCampaigns(
        get(state, 'platform.platform.id'),
        get(state, 'search.campaigns.search', null),
        get(state, 'search.campaigns.filters', {}),
        get(state, 'search.campaigns.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.campaigns.orderBy', null),
        false,
        false,
        true
      ),
      (params, state, qs, from) => searchTeams(
        get(state, 'platform.platform.id'),
        get(state, 'search.teams.search', null),
        get(state, 'search.teams.filters', {}),
        get(state, 'search.teams.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.teams.orderBy', null),
        false,
        false,
        true
      ),
      (params, state, qs, from) => searchParticipants(
        get(state, 'platform.platform.id'),
        get(state, 'search.participants.search', null),
        get(state, 'search.participants.filters', {}),
        get(state, 'search.participants.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.participants.orderBy', null),
        false,
        false,
        true
      ),
    ]
  },
  {
    match: {
      path: '/fr/recherche/participants',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state, qs, from) => searchCampaigns(
        get(state, 'platform.platform.id'),
        get(state, 'search.campaigns.search', null),
        get(state, 'search.campaigns.filters', {}),
        get(state, 'search.campaigns.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.campaigns.orderBy', null),
        false,
        false,
        from === 'donation'
      ),
      (params, state, qs, from) => searchTeams(
        get(state, 'platform.platform.id'),
        get(state, 'search.teams.search', null),
        get(state, 'search.teams.filters', {}),
        get(state, 'search.teams.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.teams.orderBy', null),
        false,
        false,
        from === 'donation'
      ),
      (params, state, qs, from) => searchParticipants(
        get(state, 'platform.platform.id'),
        get(state, 'search.participants.search', null),
        get(state, 'search.participants.filters', {}),
        get(state, 'search.participants.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.participants.orderBy', null),
        false,
        false,
        from === 'donation'
      ),
    ]
  },
  {
    match: {
      path: '/fr/donner/participants',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state, qs, from) => searchCampaigns(
        get(state, 'platform.platform.id'),
        get(state, 'search.campaigns.search', null),
        get(state, 'search.campaigns.filters', {}),
        get(state, 'search.campaigns.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.campaigns.orderBy', null),
        false,
        false,
        true
      ),
      (params, state, qs, from) => searchTeams(
        get(state, 'platform.platform.id'),
        get(state, 'search.teams.search', null),
        get(state, 'search.teams.filters', {}),
        get(state, 'search.teams.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.teams.orderBy', null),
        false,
        false,
        true
      ),
      (params, state, qs, from) => searchParticipants(
        get(state, 'platform.platform.id'),
        get(state, 'search.participants.search', null),
        get(state, 'search.participants.filters', {}),
        get(state, 'search.participants.pagination', DEFAULT_PAGER_SEARCH_PAGE),
        get(state, 'search.participants.orderBy', null),
        false,
        false,
        true
      ),
    ]
  },
  {
    match: {
      path: '/en/:campaignAlias/individual-participant/:participantAlias/give',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPublicInformation(
        get(state, 'platform.platform.id'),
        params.campaignAlias,
        null,
        params.participantAlias
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      () => fetchCountries(),
      () => fetchProvinces(),
    ]
  },
  {
    match: {
      path: '/fr/:campaignAlias/participant-individuel/:participantAlias/donner',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPublicInformation(
        get(state, 'platform.platform.id'),
        params.campaignAlias,
        null,
        params.participantAlias
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      () => fetchCountries(),
      () => fetchProvinces(),
    ]
  },
  {
    match: {
      path: '/en/:campaignAlias/:teamAlias/:participantAlias/give',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPublicInformation(
        get(state, 'platform.platform.id'),
        params.campaignAlias,
        params.teamAlias,
        params.participantAlias
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      () => fetchCountries(),
      () => fetchProvinces(),
    ]
  },
  {
    match: {
      path: '/fr/:campaignAlias/:teamAlias/:participantAlias/donner',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPublicInformation(
        get(state, 'platform.platform.id'),
        params.campaignAlias,
        params.teamAlias,
        params.participantAlias
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      () => fetchCountries(),
      () => fetchProvinces(),
    ]
  },
  {
    match: {
      path: '/en/:campaignAlias/:teamAlias/give',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPublicInformation(
        get(state, 'platform.platform.id'),
        params.campaignAlias,
        params.teamAlias,
        null
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      () => fetchCountries(),
      () => fetchProvinces(),
    ]
  },
  {
    match: {
      path: '/fr/:campaignAlias/:teamAlias/donner',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPublicInformation(
        get(state, 'platform.platform.id'),
        params.campaignAlias,
        params.teamAlias,
        null
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      () => fetchCountries(),
      () => fetchProvinces(),
    ]
  },
  {
    match: { exact: true, path: '/en/:campaignAlias/ticket-purchase/details' },
    actionCreators: [
      (params, state) => fetchPublicInformation(
        get(state, 'platform.platform.id'),
        params.campaignAlias,
        null,
        null
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: { exact: true, path: '/fr/:campaignAlias/achat-de-billet/details' },
    actionCreators: [
      (params, state) => fetchPublicInformation(
        get(state, 'platform.platform.id'),
        params.campaignAlias,
        null,
        null
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: { exact: true, path: '/en/:campaignAlias/ticket-purchase/survey' },
    actionCreators: [
      (params, state) => fetchPublicInformation(
        get(state, 'platform.platform.id'),
        params.campaignAlias,
        null,
        null
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: { exact: true, path: '/fr/:campaignAlias/achat-de-billet/enquête' },
    actionCreators: [
      (params, state) => fetchPublicInformation(
        get(state, 'platform.platform.id'),
        params.campaignAlias,
        null,
        null
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: { exact: true, path: '/en/:campaignAlias/ticket-purchase/payment' },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: { exact: true, path: '/fr/:campaignAlias/achat-de-billet/paiement' },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: { exact: false, path: '/en/:campaignAlias/ticket-purchase/confirmation' },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: { exact: false, path: '/fr/:campaignAlias/achat-de-billet/confirmation' },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/en/organization/give',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      () => fetchCountries(),
      () => fetchProvinces(),
    ]
  },
  {
    match: {
      path: '/fr/organisation/donner',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      () => fetchCountries(),
      () => fetchProvinces(),
    ]
  },
  {
    match: {
      path: '/en/:campaignAlias/give',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaign(
        params.campaignAlias,
        false,
        get(state, 'platform.platform.id'),
        { showSettings: true }
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      () => fetchCountries(),
      () => fetchProvinces(),
    ]
  },
  {
    match: {
      path: '/fr/:campaignAlias/donner',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaign(
        params.campaignAlias,
        false,
        get(state, 'platform.platform.id'),
        { showSettings: true }
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      () => fetchCountries(),
      () => fetchProvinces(),
    ]
  },
  {
    match: {
      path: '/en/give',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
    ]
  },
  {
    match: {
      path: '/fr/donner',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
    ]
  },
  {
    match: {
      path: '/en/reset-password/:key',
      exact: true
    },
    actionCreators: [
      (params) => checkForgotPasswordKey(params.key),
    ]
  },
  {
    match: {
      path: '/fr/reinitialiser-mot-passe/:key',
      exact: true
    },
    actionCreators: [
      (params) => checkForgotPasswordKey(params.key),
    ]
  },
  {
    match: {
      path: '/en/registration-confirm/:key',
      exact: true
    },
    actionCreators: [
      (params) => checkRegistrationConfirmationKey(params.key),
    ]
  },
  {
    match: {
      path: '/fr/confirmation-inscription/:key',
      exact: true
    },
    actionCreators: [
      (params) => checkRegistrationConfirmationKey(params.key),
    ]
  },
  ////////////////////
  // ADMINISTRATION //
  ////////////////////
  {
    match: {
      path: '/en/d/transactions/filter',
      exact: false
    },
    actionsCreators: [
      (params, state) => getAlgoliaToken(get(state, 'platform.platform.id'), 'donations')
    ]
  },
  {
    match: {
      path: '/fr/d/transactions/filter',
      exact: false
    },
    actionsCreators: [
      (params, state) => getAlgoliaToken(get(state, 'platform.platform.id'), 'donations')
    ]
  },
  {
    match: {
      path: '/en/d/transactions/list',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => searchTransactions(
        get(state, 'platform.platform.id'),
        null,
        { 'parentId': 'null' },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' }
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/transactions/list',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => searchTransactions(
        get(state, 'platform.platform.id'),
        null,
        { 'parentId': 'null' },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' }
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/transactions/add',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      () => fetchCountries(),
      () => fetchProvinces(),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/transactions/add',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      () => fetchCountries(),
      () => fetchProvinces(),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/transactions/withdrawal-report',
      exact: true
    },
    actionCreators: [
      (params, state) => searchSettlementReports(
        get(state, 'organization.organization.id'),
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
    ]
  },
  {
    match: {
      path: '/fr/d/transactions/withdrawal-report',
      exact: true
    },
    actionCreators: [
      (params, state) => searchSettlementReports(
        get(state, 'organization.organization.id'),
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
    ]
  },
  {
    match: {
      path: '/en/d/transactions/:transactionID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchTransactionById(
        params.transactionID,
        isPlatformAdmin(get(state, 'session.roles'))
      ),
      params => fetchTransactionLogs(params.transactionID),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/transactions/:transactionID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchTransactionById(
        params.transactionID,
        isPlatformAdmin(get(state, 'session.roles'))
      ),
      params => fetchTransactionLogs(params.transactionID),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/transactions/:transactionID/donationreceipt',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchTransactionById(
        params.transactionID,
        isPlatformAdmin(get(state, 'session.roles'))
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/transactions/:transactionID/donationreceipt',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchTransactionById(
        params.transactionID,
        isPlatformAdmin(get(state, 'session.roles'))
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/transactions/:transactionID/invoices',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchTransactionById(
        params.transactionID,
        isPlatformAdmin(get(state, 'session.roles'))
      ),
      (params, state) => searchTransactions(
        get(state, 'platform.platform.id'),
        null,
        { 'parentId': params.transactionID },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' }
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/transactions/:transactionID/invoices',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchTransactionById(
        params.transactionID,
        isPlatformAdmin(get(state, 'session.roles'))
      ),
      (params, state) => searchTransactions(
        get(state, 'platform.platform.id'),
        null,
        { 'parentId': params.transactionID },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' }
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/transactions/:transactionID',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchTransactionById(
        params.transactionID,
        isPlatformAdmin(get(state, 'session.roles'))
      ),
      () => fetchCountries(),
      () => fetchProvinces(),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/transactions/:transactionID',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchTransactionById(
        params.transactionID,
        isPlatformAdmin(get(state, 'session.roles'))
      ),
      () => fetchCountries(),
      () => fetchProvinces(),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/teams/list',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => searchTeams(
        get(state, 'platform.platform.id'),
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/teams/list',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => searchTeams(
        get(state, 'platform.platform.id'),
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/teams/:teamID/registration',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      (params) => fetchTeam(
        params.teamID,
        null,
        true,
        {
          showChildrenCount: true,
          showLeader: true
        }
      ),
      (params) => searchParticipantsInTeam(
        params.teamID,
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/teams/:teamID/registration',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      (params) => fetchTeam(
        params.teamID,
        null,
        true,
        {
          showChildrenCount: true,
          showLeader: true
        }
      ),
      (params) => searchParticipantsInTeam(
        params.teamID,
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/teams/:teamID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      params => fetchTeam(params.teamID, null, true),
      params => fetchTeamLogs(params.teamID)
    ]
  },
  {
    match: {
      path: '/fr/d/teams/:teamID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      params => fetchTeam(params.teamID, null, true),
      params => fetchTeamLogs(params.teamID)
    ]
  },
  {
    match: {
      path: '/en/d/teams/:teamID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      params => fetchTeam(
        params.teamID,
        null,
        true,
        { showLeader: true }
      ),
      params => fetchTeamDescription(params.teamID, true),
      params => fetchTeamSettings(params.teamID, true),
      params => fetchTeamParticipants(params.teamID)
    ]
  },
  {
    match: {
      path: '/fr/d/teams/:teamID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      params => fetchTeam(
        params.teamID,
        null,
        true,
        { showLeader: true }
      ),
      params => fetchTeamDescription(params.teamID, true),
      params => fetchTeamSettings(params.teamID, true),
      params => fetchTeamParticipants(params.teamID)
    ]
  },
  {
    match: {
      path: '/en/d/participants/list',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => searchParticipants(
        get(state, 'platform.platform.id'),
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/participants/list',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => searchParticipants(
        get(state, 'platform.platform.id'),
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/participants/:participantID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      params => fetchParticipant(
        params.participantID,
        true,
        null,
        { showMember: true}
      ),
      params => fetchParticipantLogs(params.participantID)
    ]
  },
  {
    match: {
      path: '/fr/d/participants/:participantID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      params => fetchParticipant(
        params.participantID,
        true,
        null,
        { showMember: true}
      ),
      params => fetchParticipantLogs(params.participantID)
    ]
  },
  {
    match: {
      path: '/en/d/participants/:participantID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      params => fetchParticipant(
        params.participantID,
        true,
        null,
        { showMember: true }
      ),
      params => fetchParticipantDescription(params.participantID, true),
      params => fetchParticipantSettings(params.participantID, true)
    ]
  },
  {
    match: {
      path: '/fr/d/participants/:participantID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      params => fetchParticipant(
        params.participantID,
        true,
        null,
        { showMember: true }
      ),
      params => fetchParticipantDescription(params.participantID, true),
      params => fetchParticipantSettings(params.participantID, true)
    ]
  },
  {
    match: {
      path: '/en/d/contributions/list/donations',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => searchMemberTransactions(
        get(state, 'session.user.id'),
        null,
        {
          platformId: get(state, 'platform.platform.id'),
          type: "DONATION|DONATION_SUBSCRIPTION",
          parentId: "null"
        },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' }
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/contributions/list/donations',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => searchMemberTransactions(
        get(state, 'session.user.id'),
        null,
        {
          platformId: get(state, 'platform.platform.id'),
          type: "DONATION|DONATION_SUBSCRIPTION",
          parentId: "null"
        },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' }
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/contributions/donations/:donationID/invoices',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId')),
      params => fetchTransactionById(params.donationID, false),
      (params, state) => searchTransactions(
        get(state, 'platform.platform.id'),
        null,
        { 'parentId': params.donationID },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' }
      )
    ]
  },
  {
    match: {
      path: '/fr/d/contributions/donations/:donationID/invoices',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId')),
      params => fetchTransactionById(params.donationID, false),
      (params, state) => searchTransactions(
        get(state, 'platform.platform.id'),
        null,
        { 'parentId': params.donationID },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' }
      )
    ]
  },
  {
    match: {
      path: '/en/d/contributions/donations/:donationID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      params => fetchTransactionById(params.donationID, false),
      () => fetchCountries(),
      () => fetchProvinces(),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/contributions/donations/:donationID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      params => fetchTransactionById(params.donationID, false),
      () => fetchCountries(),
      () => fetchProvinces(),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/contributions/list/participations',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => searchParticipations(
        get(state, 'session.user.id'),
        get(state, 'platform.platform.id'),
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' }
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/contributions/list/participations',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => searchParticipations(
        get(state, 'session.user.id'),
        get(state, 'platform.platform.id'),
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' }
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/contributions/participations/:participantID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchParticipant(
        params.participantID,
        true,
        null,
        {
          showMember: true,
          showSettings: true
        }
      ),
      params => fetchParticipantDescription(params.participantID, true),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/contributions/participations/:participantID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchParticipant(
        params.participantID,
        true,
        null,
        {
          showMember: true,
          showSettings: true
        }
      ),
      params => fetchParticipantDescription(params.participantID, true),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/contributions/teams/:teamID/registration',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      (params) => fetchTeam(
        params.teamID,
        null,
        false,
        {
          showChildrenCount: true,
          showLeader: true
        }
      ),
      (params) => searchParticipantsInTeam(
        params.teamID,
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/contributions/teams/:teamID/registration',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      (params) => fetchTeam(
        params.teamID,
        null,
        false,
        {
          showChildrenCount: true,
          showLeader: true
        }
      ),
      (params) => searchParticipantsInTeam(
        params.teamID,
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/contributions/teams/:teamID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchTeam(params.teamID),
      (params) => fetchTeamLogs(params.teamID),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/contributions/teams/:teamID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchTeam(params.teamID),
      (params) => fetchTeamLogs(params.teamID),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/contributions/teams/:teamID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      params => fetchTeam(
        params.teamID,
        null,
        true,
        { showLeader: true }
      ),
      params => fetchTeamDescription(params.teamID, true),
      params => fetchTeamSettings(params.teamID, true),
      params => fetchTeamParticipants(params.teamID),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/contributions/teams/:teamID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      params => fetchTeam(
        params.teamID,
        null,
        true,
        { showLeader: true }
      ),
      params => fetchTeamDescription(params.teamID, true),
      params => fetchTeamSettings(params.teamID, true),
      params => fetchTeamParticipants(params.teamID),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/contributions/list/tickets',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => searchMemberTransactions(
        get(state, 'session.user.id'),
        null,
        {
          platformId: get(state, 'platform.platform.id'),
          type: "CAMPAIGN_EVENT_TICKET",
          status: ['APPROVED','REFUNDED']
        },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' }
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/contributions/list/tickets',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => searchMemberTransactions(
        get(state, 'session.user.id'),
        null,
        {
          platformId: get(state, 'platform.platform.id'),
          type: "CAMPAIGN_EVENT_TICKET",
          status: ['APPROVED','REFUNDED']
        },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' }
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/profile/:memberID/my-information',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      params => fetchMemberById(params.memberID),
      () => fetchCountries(),
      () => fetchProvinces(),
      (params, state) => fetchMemberRoles(get(state, 'session.userId')),
      (params, state) => fetchMemberPlatformRoles(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      )
    ]
  },
  {
    match: {
      path: '/fr/d/profile/:memberID/my-information',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      params => fetchMemberById(params.memberID),
      () => fetchCountries(),
      () => fetchProvinces(),
      (params, state) => fetchMemberRoles(get(state, 'session.userId')),
      (params, state) => fetchMemberPlatformRoles(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/settings/registration',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchCampaignSettings(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/settings/registration',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchCampaignSettings(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/settings/transactions',
      exact: false
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchCampaignSettings(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/settings/transactions',
      exact: false
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchCampaignSettings(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/settings/advanced',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchCampaignSettings(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/settings/advanced',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchCampaignSettings(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/teams/list',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => searchTeamsInCampaign(
        params.campaignID,
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/teams/list',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => searchTeamsInCampaign(
        params.campaignID,
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/teams/:teamID/registration',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchTeam(
        params.teamID,
        null,
        true,
        { showChildrenCount: true }
      ),
      params => searchParticipantsInTeam(
        params.teamID,
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/teams/:teamID/registration',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchTeam(
        params.teamID,
        null,
        true,
        { showChildrenCount: true }
      ),
      params => searchParticipantsInTeam(
        params.teamID,
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/teams/:teamID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchTeam(params.teamID, null, true),
      params => fetchTeamLogs(params.teamID),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/teams/:teamID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchTeam(params.teamID, null, true),
      params => fetchTeamLogs(params.teamID),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/teams/:teamID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchTeam(
        params.teamID,
        null,
        true,
        { showLeader: true }
      ),
      params => fetchTeamDescription(params.teamID, true),
      params => fetchTeamSettings(params.teamID, true),
      params => fetchTeamParticipants(params.teamID),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/teams/:teamID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchTeam(
        params.teamID,
        null,
        true,
        { showLeader: true }
      ),
      params => fetchTeamDescription(params.teamID, true),
      params => fetchTeamSettings(params.teamID, true),
      params => fetchTeamParticipants(params.teamID),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/participants/list',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => searchParticipantsInCampaign(
        params.campaignID,
        null,
        { status: 'ACTIVE' },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/participants/list',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => searchParticipantsInCampaign(
        params.campaignID,
        null,
        { status: 'ACTIVE' },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/participants/:participantID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchParticipant(
        params.participantID,
        true,
        null,
        { showMember: true }
      ),
      params => fetchTeams(params.campaignID, true),
      params => fetchParticipantLogs(params.participantID),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/participants/:participantID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchParticipant(
        params.participantID,
        true,
        null,
        { showMember: true }
      ),
      params => fetchTeams(params.campaignID, true),
      params => fetchParticipantLogs(params.participantID),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/participants/:participantID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignRoles(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchCampaignSettings(params.campaignID, true),
      params => fetchParticipant(
        params.participantID,
        true,
        null,
        { showMember: true }
      ),
      params => fetchParticipantDescription(params.participantID, true),
      params => fetchParticipantSettings(params.participantID, true),
      params => fetchTeams(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/participants/:participantID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignRoles(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchCampaignSettings(params.campaignID, true),
      params => fetchParticipant(
        params.participantID,
        true,
        null,
        { showMember: true }
      ),
      params => fetchParticipantDescription(params.participantID, true),
      params => fetchParticipantSettings(params.participantID, true),
      params => fetchTeams(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/transactions/list/donations',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => searchTransactionsInCampaign(
        params.campaignID,
        null,
        { type: 'DONATION' },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/transactions/list/donations',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => searchTransactionsInCampaign(
        params.campaignID,
        null,
        { type: 'DONATION' },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/transactions/list/tickets',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => searchTransactionsInCampaign(
        params.campaignID,
        null,
        { type: 'CAMPAIGN_EVENT_TICKET' },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/transactions/list/tickets',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => searchTransactionsInCampaign(
        params.campaignID,
        null,
        { type: 'CAMPAIGN_EVENT_TICKET' },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/transactions/add',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      () => fetchCountries(),
      () => fetchProvinces(),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/transactions/add',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      () => fetchCountries(),
      () => fetchProvinces(),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/transactions/:transactionID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignRoles(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchTransactionById(params.transactionID, true),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchTransactionLogs(params.transactionID),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/transactions/:transactionID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignRoles(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchTransactionById(params.transactionID, true),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchTransactionLogs(params.transactionID),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/transactions/:transactionID/donationreceipt',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignRoles(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchTransactionById(params.transactionID, true),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/transactions/:transactionID/donationreceipt',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignRoles(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchTransactionById(params.transactionID, true),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/transactions/:transactionID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignRoles(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchTransactionById(params.transactionID, true),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      () => fetchCountries(),
      () => fetchProvinces(),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/transactions/:transactionID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignRoles(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchTransactionById(params.transactionID, true),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      () => fetchCountries(),
      () => fetchProvinces(),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/settings',
      exact: false
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/settings',
      exact: false
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/list',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/list',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/tickets',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/tickets',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchCampaignLogs(params.campaignID),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchCampaignLogs(params.campaignID),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/attendees/checkin',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/attendees/checkin',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/attendees/:ticketID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/attendees/:ticketID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/attendees/teams/list',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => searchTeamsInCampaign(
        params.campaignID,
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/attendees/teams/list',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => searchTeamsInCampaign(
        params.campaignID,
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/attendees/teams/:teamID/registration',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchTeam(
        params.teamID,
        null,
        true,
        { showChildrenCount: true }
      ),
      params => searchParticipantsInTeam(
        params.teamID,
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/attendees/teams/:teamID/registration',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchTeam(
        params.teamID,
        null,
        true,
        { showChildrenCount: true }
      ),
      params => searchParticipantsInTeam(
        params.teamID,
        null,
        null,
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/attendees/teams/:teamID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchTeam(params.teamID, null, true),
      params => fetchTeamLogs(params.teamID),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/attendees/teams/:teamID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchTeam(params.teamID, null, true),
      params => fetchTeamLogs(params.teamID),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/attendees/teams/:teamID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchTeam(
        params.teamID,
        null,
        true,
        { showLeader: true }
      ),
      params => fetchTeamDescription(params.teamID, true),
      params => fetchTeamSettings(params.teamID, true),
      params => fetchTeamParticipants(params.teamID),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/attendees/teams/:teamID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberTeamPermissions(
        params.teamID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchTeam(
        params.teamID,
        null,
        true,
        { showLeader: true }
      ),
      params => fetchTeamDescription(params.teamID, true),
      params => fetchTeamSettings(params.teamID, true),
      params => fetchTeamParticipants(params.teamID),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/attendees/participants/list',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => searchParticipantsInCampaign(
        params.campaignID,
        null,
        { status: 'ACTIVE' },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/attendees/participants/list',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => searchParticipantsInCampaign(
        params.campaignID,
        null,
        { status: 'ACTIVE' },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/attendees/participants/:participantID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchParticipant(
        params.participantID,
        true,
        null,
        { showMember: true }
      ),
      params => fetchTeams(params.campaignID, true),
      params => fetchParticipantLogs(params.participantID),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/attendees/participants/:participantID/history',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchParticipant(
        params.participantID,
        true,
        null,
        { showMember: true }
      ),
      params => fetchTeams(params.campaignID, true),
      params => fetchParticipantLogs(params.participantID),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/attendees/participants/:participantID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignRoles(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchCampaignSettings(params.campaignID, true),
      params => fetchParticipant(
        params.participantID,
        true,
        null,
        { showMember: true }
      ),
      params => fetchParticipantDescription(params.participantID, true),
      params => fetchParticipantSettings(params.participantID, true),
      params => fetchTeams(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/attendees/participants/:participantID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignRoles(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchCampaignSettings(params.campaignID, true),
      params => fetchParticipant(
        params.participantID,
        true,
        null,
        { showMember: true }
      ),
      params => fetchParticipantDescription(params.participantID, true),
      params => fetchParticipantSettings(params.participantID, true),
      params => fetchTeams(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID/communications',
      exact: true
    },
    actionCreators: [
      params => fetchCampaignNote(params.campaignID, true),
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID/communications',
      exact: true
    },
    actionCreators: [
      params => fetchCampaignNote(params.campaignID, true),
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/campaigns/:campaignID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignDescription(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchCampaignSettings(params.campaignID, true),
      params => fetchCampaignParticipants(params.campaignID),
      (params, state) => fetchMemberParticipations(
        get(state, 'session.user.id'),
        get(state, 'platform.platform.id')
      ),
      (params, state) => searchMembers(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: 7550000000 },
        { orderBy: 'name[asc]' },
        true
      ),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/campaigns/:campaignID',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberCampaignPermissions(
        params.campaignID,
        get(state, 'session.userId')
      ),
      params => fetchCampaign(params.campaignID, true),
      params => fetchCampaignDescription(params.campaignID, true),
      params => fetchCampaignMedia(params.campaignID, true),
      params => fetchCampaignSettings(params.campaignID, true),
      params => fetchCampaignParticipants(params.campaignID),
      (params, state) => fetchMemberParticipations(
        get(state, 'session.user.id'),
        get(state, 'platform.platform.id')
      ),
      (params, state) => searchMembers(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: 7550000000 },
        { orderBy: 'name[asc]' },
        true
      ),
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/members/list',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => searchMembers(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/members/list',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => searchMembers(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/members/:memberID/information',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformMemberById(
        get(state, 'platform.platform.id'),
        params.memberID
      ),
      (params, state) => fetchMemberPlatformPermissions(get(state, 'platform.platform.id')),
      () => fetchCountries(),
      () => fetchProvinces(),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/members/:memberID/information',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformMemberById(
        get(state, 'platform.platform.id'),
        params.memberID
      ),
      (params, state) => fetchMemberPlatformPermissions(get(state, 'platform.platform.id')),
      () => fetchCountries(),
      () => fetchProvinces(),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/members/:memberID/donations',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformMemberById(
        get(state, 'platform.platform.id'),
        params.memberID
      ),
      (params, state) => fetchMemberPlatformPermissions(get(state, 'platform.platform.id')),
      (params, state) => searchPlatformMembersTransactions(
        get(state, 'platform.platform.id'),
        params.memberID,
        null,
        { parentId: "null" },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/members/:memberID/donations',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformMemberById(
        get(state, 'platform.platform.id'),
        params.memberID
      ),
      (params, state) => fetchMemberPlatformPermissions(get(state, 'platform.platform.id')),
      (params, state) => searchPlatformMembersTransactions(
        get(state, 'platform.platform.id'),
        params.memberID,
        null,
        { parentId: "null" },
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/members/:memberID/participations',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformMemberById(
        get(state, 'platform.platform.id'),
        params.memberID
      ),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => searchPlatformMembersParticipations(
        get(state, 'platform.platform.id'),
        params.memberID,
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/members/:memberID/participations',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformMemberById(
        get(state, 'platform.platform.id'),
        params.memberID
      ),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => searchPlatformMembersParticipations(
        get(state, 'platform.platform.id'),
        params.memberID,
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/members/:memberID/contributions',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformMemberById(
        get(state, 'platform.platform.id'),
        params.memberID
      ),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d/members/:memberID/contributions',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformMemberById(
        get(state, 'platform.platform.id'),
        params.memberID
      ),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/en/d/pages/guidelines',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatformContent(get(state, 'platform.platform.id'), true),
    ]
  },
  {
    match: {
      path: '/fr/d/pages/guidelines',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatformContent(get(state, 'platform.platform.id'), true),
    ]
  },
  {
    match: {
      path: '/en/d/pages/refund-policy',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatformContent(get(state, 'platform.platform.id'), true)
    ]
  },
  {
    match: {
      path: '/fr/d/pages/refund-policy',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatformContent(get(state, 'platform.platform.id'), true)
    ]
  },
  {
    match: {
      path: '/en/d/pages/donation-confirmation-email',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatformNote(get(state, 'platform.platform.id'), true)
    ]
  },
  {
    match: {
      path: '/fr/d/pages/donation-confirmation-email',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatformNote(get(state, 'platform.platform.id'), true)
    ]
  },
  {
    match: {
      path: '/en/d/pages/homepage/main-slider',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatformMedia(get(state, 'platform.platform.id'), true)
    ]
  },
  {
    match: {
      path: '/fr/d/pages/homepage/main-slider',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatformMedia(get(state, 'platform.platform.id'), true)
    ]
  },
  {
    match: {
      path: '/en/d/pages/homepage/introduction-text',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatformDescription(get(state, 'platform.platform.id'), true)
    ]
  },
  {
    match: {
      path: '/fr/d/pages/homepage/introduction-text',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatformDescription(get(state, 'platform.platform.id'), true)
    ]
  },
  {
    match: {
      path: '/en/d/pages/homepage/campaign-featured',
      exact: true
    },
    actionCreators: [
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: 9999999 }
      ),
      (params, state) => fetchPlatformFeaturedCampaigns(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformSections(get(state, 'platform.platform.id'), true)
    ]
  },
  {
    match: {
      path: '/fr/d/pages/homepage/campaign-featured',
      exact: true
    },
    actionCreators: [
      (params, state) => searchCampaigns(
        get(state, 'platform.platform.id'),
        null,
        {},
        { pageResults: 9999999 }
      ),
      (params, state) => fetchPlatformFeaturedCampaigns(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformSections(get(state, 'platform.platform.id'), true)
    ]
  },
  {
    match: {
      path: '/en/d/settings/general',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchPlatformLanguages(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformMedia(get(state, 'platform.platform.id'), true)
    ]
  },
  {
    match: {
      path: '/fr/d/settings/general',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchPlatformLanguages(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformMedia(get(state, 'platform.platform.id'), true)
    ]
  },
  {
    match: {
      path: '/en/d/settings/design',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatformMedia(get(state, 'platform.platform.id'), true),
    ]
  },
  {
    match: {
      path: '/fr/d/settings/design',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatformMedia(get(state, 'platform.platform.id'), true),
    ]
  },
  {
    match: {
      path: '/en/d/plugins',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      () => searchPlugins(
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/fr/d/plugins',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      () => searchPlugins(
        null,
        {},
        { pageResults: DEFAULT_PAGER.pageSize },
        { orderBy: 'addedOn[desc]' },
        true
      )
    ]
  },
  {
    match: {
      path: '/en/d/surveys',
      exact: true
    },
    actionCreators: []
  },
  {
    match: {
      path: '/en/d/survey/information',
      exact: true
    },
    actionCreators: []
  },
  {
    match: {
      path: '/en/d/survey/:id/information',
      exact: true
    },
    actionCreators: []
  },
  {
    match: {
      path: '/en/d/survey/:id/field',
      exact: true
    },
    actionCreators: []
  },
  {
    match: {
      path: '/en/d/survey/:id/result',
      exact: true
    },
    actionCreators: []
  },
  {
    match: {
      path: '/fr/d/surveys',
      exact: true
    },
    actionCreators: []
  },
  {
    match: {
      path: '/fr/d/survey/information',
      exact: true
    },
    actionCreators: []
  },
  {
    match: {
      path: '/fr/d/survey/:id/information',
      exact: true
    },
    actionCreators: []
  },
  {
    match: {
      path: '/fr/d/survey/:id/field',
      exact: true
    },
    actionCreators: []
  },
  {
    match: {
      path: '/fr/d/survey/:id/result',
      exact: true
    },
    actionCreators: []
  },
  {
    match: {
      exact: true,
      path: '/en/d/plugins/donationreceipt'
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      )
    ]
  },
  {
    match: {
      exact: true,
      path: '/fr/d/plugins/donationreceipt'
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      )
    ]
  },
  {
    match: {
      exact: true,
      path: '/en/d/plugins/donationreceipt/history'
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      )
    ]
  },
  {
    match: {
      exact: true,
      path: '/fr/d/plugins/donationreceipt/history'
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      )
    ]
  },
  {
    match: {
      exact: true,
      path: '/en/d/plugins/tickets'
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      )
    ]
  },
  {
    match: {
      exact: true,
      path: '/fr/d/plugins/tickets'
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      )
    ]
  },
  {
    match: {
      exact: true,
      path: '/en/d/plugins/tickets/related-campaigns'
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      )
    ]
  },
  {
    match: {
      exact: true,
      path: '/fr/d/plugins/tickets/related-campaigns'
    },
    actionCreators: [
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      )
    ]
  },
  {
    match: {
      path: '/en/d',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  {
    match: {
      path: '/fr/d',
      exact: true
    },
    actionCreators: [
      (params, state) => isCampaignOwner(get(state, 'platform.platform.id')),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => fetchMemberPlatformPermissions(
        get(state, 'platform.platform.id'),
        get(state, 'session.userId')
      ),
      (params, state) => fetchMemberRoles(get(state, 'session.userId'))
    ]
  },
  // STATIC
  {
    match: {
      path: '/en/refund-policy',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/fr/politique-remboursement',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/en/contact-us',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/fr/contactez-nous',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/sitemap_index.xml',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/page-sitemap.xml',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/campaign-sitemap.xml',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => searchTeams(
        get(state, 'platform.platform.id'),
        null,
        {},
        DEFAULT_PAGER_SEARCH_PAGE,
        null
      ),
      (params, state) => searchParticipants(
        get(state, 'platform.platform.id'),
        null,
        {},
        DEFAULT_PAGER_SEARCH_PAGE,
        null
      )
    ]
  },
  // Campaign, team & participant
  {
    match: {
      path: '/en/:campaignAlias/individual-participant/:participantAlias',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPublicInformation(
        get(state, 'platform.platform.id'),
        params.campaignAlias,
        null,
        params.participantAlias
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/fr/:campaignAlias/participant-individuel/:participantAlias',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPublicInformation(
        get(state, 'platform.platform.id'),
        params.campaignAlias,
        null,
        params.participantAlias
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/en/:campaignAlias/:teamAlias/:participantAlias',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPublicInformation(
        get(state, 'platform.platform.id'),
        params.campaignAlias,
        params.teamAlias,
        params.participantAlias
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/fr/:campaignAlias/:teamAlias/:participantAlias',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPublicInformation(
        get(state, 'platform.platform.id'),
        params.campaignAlias,
        params.teamAlias,
        params.participantAlias
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/en/:campaignAlias/:teamAlias',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPublicInformation(
        get(state, 'platform.platform.id'),
        params.campaignAlias,
        params.teamAlias
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/fr/:campaignAlias/:teamAlias',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPublicInformation(
        get(state, 'platform.platform.id'),
        params.campaignAlias,
        params.teamAlias
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/en/:campaignAlias',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPublicInformation(
        get(state, 'platform.platform.id'),
        params.campaignAlias
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/fr/:campaignAlias',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPublicInformation(
        get(state, 'platform.platform.id'),
        params.campaignAlias
      ),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/en',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatform(
        get(state, 'platform.platform.id'),
        false,
        {
          showDescription: true,
          showMedia: true
        }
      ),
      (params, state) => fetchPlatformFeaturedCampaigns(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformSections(get(state, 'platform.platform.id')),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => fetchLastDonationsByPlatformId(get(state, 'platform.platform.id'))
    ]
  },
  {
    match: {
      path: '/fr',
      exact: true
    },
    actionCreators: [
      (params, state) => fetchPlatform(
        get(state, 'platform.platform.id'),
        false,
        {
          showDescription: true,
          showMedia: true
        }
      ),
      (params, state) => fetchPlatformFeaturedCampaigns(get(state, 'platform.platform.id')),
      (params, state) => fetchPlatformSections(get(state, 'platform.platform.id')),
      (params, state) => fetchCampaignList(get(state, 'platform.platform.id')),
      (params, state) => fetchLastDonationsByPlatformId(get(state, 'platform.platform.id'))
    ]
  },
];
