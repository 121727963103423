import { requestApiGet } from '../parts/common/requestUtils';
import { getSessionIp } from '../parts/session/sessionUtils';

export const FETCH_CAMPAIGN_REPORT_START = 'FETCH_CAMPAIGN_REPORT_START';
export const FETCH_CAMPAIGN_REPORT_SUCCESS = 'FETCH_CAMPAIGN_REPORT_SUCCESS';
export const FETCH_CAMPAIGN_REPORT_ERROR = 'FETCH_CAMPAIGN_REPORT_ERROR';

export const FETCH_TEAM_REPORT_START = 'FETCH_TEAM_REPORT_START';
export const FETCH_TEAM_REPORT_SUCCESS = 'FETCH_TEAM_REPORT_SUCCESS';
export const FETCH_TEAM_REPORT_ERROR = 'FETCH_TEAM_REPORT_ERROR';

export const FETCH_PARTICIPANT_REPORT_START = 'FETCH_PARTICIPANT_REPORT_START';
export const FETCH_PARTICIPANT_REPORT_SUCCESS = 'FETCH_PARTICIPANT_REPORT_SUCCESS';
export const FETCH_PARTICIPANT_REPORT_ERROR = 'FETCH_PARTICIPANT_REPORT_ERROR';

export const FETCH_DONATION_REPORT_START = 'FETCH_DONATION_REPORT_START';
export const FETCH_DONATION_REPORT_SUCCESS = 'FETCH_DONATION_REPORT_SUCCESS';
export const FETCH_DONATION_REPORT_ERROR = 'FETCH_DONATION_REPORT_ERROR';

export const FETCH_TRANSACTION_REPORT_START = 'FETCH_TRANSACTION_REPORT_START';
export const FETCH_TRANSACTION_REPORT_SUCCESS = 'FETCH_TRANSACTION_REPORT_SUCCESS';
export const FETCH_TRANSACTION_REPORT_ERROR = 'FETCH_TRANSACTION_REPORT_ERROR';

export const FETCH_PARTICIPATION_REPORT_START = 'FETCH_PARTICIPATION_REPORT_START';
export const FETCH_PARTICIPATION_REPORT_SUCCESS = 'FETCH_PARTICIPATION_REPORT_SUCCESS';
export const FETCH_PARTICIPATION_REPORT_ERROR = 'FETCH_PARTICIPATION_REPORT_ERROR';

export const FETCH_ATTENDEE_REPORT_START = 'FETCH_ATTENDEE_REPORT_START';
export const FETCH_ATTENDEE_REPORT_SUCCESS = 'FETCH_ATTENDEE_REPORT_SUCCESS';
export const FETCH_ATTENDEE_REPORT_ERROR = 'FETCH_ATTENDEE_REPORT_ERROR';

export const FETCH_MEMBER_REPORT_START = 'FETCH_MEMBER_REPORT_START';
export const FETCH_MEMBER_REPORT_SUCCESS = 'FETCH_MEMBER_REPORT_SUCCESS';
export const FETCH_MEMBER_REPORT_ERROR = 'FETCH_MEMBER_REPORT_ERROR';

export const CLEAR_REPORT = 'CLEAR_REPORT';

export function reportTransactions(
  platformId,
  filters = {},
  pagination = {},
  orderBy = {},
  authentication = false,
  totalResults = null
) {
  return dispatch => {
    dispatch({ type: FETCH_TRANSACTION_REPORT_START });

    const pageNumber = 1; //pagination.current || pagination.pageNumber || 1;
    const pageResults =
      pagination.totalResults || pagination.pageSize || pagination.pageResults || undefined;

    /* Only APPROVED for now*/
    const status = 'APPROVED';
    if (filters) {
      filters.status = status;
    } else {
      filters = { status: status };
    }

    let formatedFilters = [];
    for (let key in filters) {
      let filter = Array.isArray(filters[key]) ? filters[key].join('|') : filters[key];
      formatedFilters.push(`${key}[${filter}]`);
    }
    formatedFilters = formatedFilters.length > 0 ? formatedFilters.join(',') : undefined;

    const search = (filters && filters.search) || undefined;

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults,
      export: true
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/transactions`,
      params,
      headers
    )
      .then(result => {
        dispatch({ type: FETCH_TRANSACTION_REPORT_SUCCESS, payload: result });
      })
      .catch(error => {
        dispatch({ type: FETCH_TRANSACTION_REPORT_ERROR });
      });
  };
}

export function reportTransactionsInCampaign(
  campaignId,
  filters = {},
  pagination = {},
  orderBy = {},
  authentication = false,
  totalResults = null
) {
  return dispatch => {
    dispatch({ type: FETCH_TRANSACTION_REPORT_START });

    const pageNumber = 1; //pagination.current || pagination.pageNumber || 1;
    const pageResults =
      pagination.totalResults || pagination.pageSize || pagination.pageResults || undefined;

    /* Only APPROVED for now*/
    const status = 'APPROVED';
    if (filters) {
      filters.status = status;
    } else {
      filters = { status: status };
    }

    let formatedFilters = [];
    for (let key in filters) {
      let filter = Array.isArray(filters[key]) ? filters[key].join('|') : filters[key];
      formatedFilters.push(`${key}[${filter}]`);
    }
    formatedFilters = formatedFilters.length > 0 ? formatedFilters.join(',') : undefined;

    const search = (filters && filters.search) || undefined;

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults,
      export: true
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/campaigns/${campaignId}/transactions`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_TRANSACTION_REPORT_SUCCESS, payload: result });
      })
      .catch(error => {
        dispatch({ type: FETCH_TRANSACTION_REPORT_ERROR });
      });
  };
}

export function reportAttendeesInEvent(
  campaignId,
  filters = {},
  pagination = {},
  orderBy = {},
  authentication = false,
  totalResults = null
) {
  return dispatch => {
    dispatch({ type: FETCH_ATTENDEE_REPORT_START });

    const pageNumber = 1; //pagination.current || pagination.pageNumber || 1;
    const pageResults =
      pagination.totalResults || pagination.pageSize || pagination.pageResults || undefined;

    /* Only APPROVED for now*/
    const status = 'APPROVED';
    if (filters) {
      filters.status = status;
    } else {
      filters = { status: status };
    }

    let formatedFilters = [];
    for (let key in filters) {
      let filter = Array.isArray(filters[key]) ? filters[key].join('|') : filters[key];
      formatedFilters.push(`${key}[${filter}]`);
    }
    formatedFilters = formatedFilters.length > 0 ? formatedFilters.join(',') : undefined;

    const search = (filters && filters.search) || undefined;

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults,
      export: true
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/campaigns/${campaignId}/event/tickets/purchase`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_ATTENDEE_REPORT_SUCCESS, payload: result });
      })
      .catch(error => {
        dispatch({ type: FETCH_ATTENDEE_REPORT_ERROR });
      });
  };
}

export function reportParticipantsInCampaign(
  campaignId,
  filters = {},
  pagination = {},
  orderBy = {},
  authentication = false,
  totalResults = null
) {
  return dispatch => {
    dispatch({ type: FETCH_PARTICIPANT_REPORT_START });

    const pageNumber = 1; //pagination.current || pagination.pageNumber || 1;
    const pageResults =
      pagination.totalResults || pagination.pageSize || pagination.pageResults || undefined;

    let formatedFilters = [];
    for (let key in filters) {
      let filter = Array.isArray(filters[key]) ? filters[key].join('|') : filters[key];
      formatedFilters.push(`${key}[${filter}]`);
    }
    formatedFilters = formatedFilters.length > 0 ? formatedFilters.join(',') : undefined;

    const search = (filters && filters.search) || undefined;

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults,
      //export: true
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/campaigns/${campaignId}/participants/export`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_PARTICIPANT_REPORT_SUCCESS, payload: result });
      })
      .catch(error => {
        dispatch({ type: FETCH_PARTICIPANT_REPORT_ERROR });
      });
  };
}

export function detailedReportParticipantsInCampaign(
  campaignId,
  filters = {},
  pagination = {},
  orderBy = {},
  authentication = false,
  totalResults = null
) {
  return dispatch => {
    dispatch({ type: FETCH_PARTICIPANT_REPORT_START });

    const pageNumber = 1; //pagination.current || pagination.pageNumber || 1;
    const pageResults =
      pagination.totalResults || pagination.pageSize || pagination.pageResults || undefined;

    let formatedFilters = [];
    for (let key in filters) {
      let filter = Array.isArray(filters[key]) ? filters[key].join('|') : filters[key];
      formatedFilters.push(`${key}[${filter}]`);
    }
    formatedFilters = formatedFilters.length > 0 ? formatedFilters.join(',') : undefined;

    const search = (filters && filters.search) || undefined;

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults,
      //export: true
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/campaigns/export/${campaignId}`,
      params,
      headers
    )
      .then(result => {
        return dispatch({ type: FETCH_PARTICIPANT_REPORT_SUCCESS, payload: result });
      })
      .catch(error => {
        dispatch({ type: FETCH_PARTICIPANT_REPORT_ERROR });
      });
  };
}


export function reportMembers(
  platformId,
  filters = {},
  pagination = {},
  orderBy = {},
  authentication = false,
  totalResults = null
) {
  return dispatch => {
    dispatch({ type: FETCH_MEMBER_REPORT_START });

    const pageNumber = 1; //pagination.current || pagination.pageNumber || 1;
    const pageResults =
      pagination.totalResults ||
      pagination.pageSize ||
      pagination.pageResults ||
      undefined;

    let formatedFilters = [];
    for (let key in filters) {
      let filter = Array.isArray(filters[key]) ? filters[key].join('|') : filters[key];
      formatedFilters.push(`${key}[${filter}]`);
    }
    formatedFilters = formatedFilters.length > 0 ? formatedFilters.join(',') : undefined;

    const search = (filters && filters.search) || undefined;

    const params = {
      filterBy: formatedFilters,
      ...orderBy,
      search,
      pageNumber,
      pageResults,
      export: true
    };

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/platforms/${platformId}/members`,
      params,
      headers
    )
      .then(result => {
        dispatch({ type: FETCH_MEMBER_REPORT_SUCCESS, payload: result });
      })
      .catch(error => {
        dispatch({ type: FETCH_MEMBER_REPORT_ERROR });
      });
  };
}

export function clearReport() {
  return dispatch => {
    return dispatch({ type: CLEAR_REPORT });
  };
}
