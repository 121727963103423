import React from 'react';
import { Form, Popconfirm, notification, InputNumber } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { pickBy, isEmpty, get } from 'lodash';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import FundkyModal from 'Parts/ui/components/FundkyModal';
import FundkyCard from 'Parts/ui/components/FundkyCard';
import TextField from 'Parts/ui/components/fields/TextField';
import ProvinceField from 'Parts/ui/components/fields/ProvinceFieldContainer';
import CountryField from 'Parts/ui/components/fields/CountryFieldContainer';
import PostalCodeField from 'Parts/ui/components/fields/PostalCodeField';

import { simpleFieldValidator } from 'Parts/ui/validations/fieldValidations';

import AddressFormEN from './locales/AddressForm_en.json';
import AddressFormFR from './locales/AddressForm_fr.json';

const PERSONAL = 1;
const CORPORATE = 2;

import './AddressForm.less';

class AddressForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      profileTypeId: PERSONAL,
      personalCountry: get(props, 'user.profiles.general.country', null),
      corporateCountry: get(props, 'user.profiles.work.country', null),
      disableModalUpdate: true,
      addressModalVisible: false
    };

    i18next.addResourceBundle('en', 'AddressForm', AddressFormEN);
    i18next.addResourceBundle('fr', 'AddressForm', AddressFormFR);
  }

  onCountryChange = (value, isPersonal) => {
    if (isPersonal) {
      this.setState({
        personalCountry: value
      });
    } else {
      this.setState({
        corporateCountry: value
      });
    }
  };

  // Returns user information as an object
  getUserInformation = profileType => {
    if (profileType === PERSONAL) {
      return {
        country: get(this.props, 'user.profiles.general.country', null),
        honorificId: get(this.props, 'user.profiles.user.honorificId', null),
        address: get(this.props, 'user.profiles.general.address', null),
        unit: get(this.props, 'user.profiles.general.unit', null),
        city: get(this.props, 'user.profiles.general.city', null),
        province: get(this.props, 'user.profiles.general.province', null),
        postalCode: get(this.props, 'user.profiles.general.postalCode', null),
        phone: get(this.props, 'user.profiles.general.phone.number', null),
        ext: get(this.props, 'user.profiles.general.phone.ext', null)
      };
    } else {
      return {
        country: get(this.props, 'user.profiles.work.country', null),
        address: get(this.props, 'user.profiles.work.address', null),
        unit: get(this.props, 'user.profiles.work.unit', null),
        city: get(this.props, 'user.profiles.work.city', null),
        province: get(this.props, 'user.profiles.work.province', null),
        postalCode: get(this.props, 'user.profiles.work.postalCode', null),
        phone: get(this.props, 'user.profiles.work.phone.number', null),
        ext: get(this.props, 'user.profiles.work.phone.ext', null),
        organizationName: get(this.props, 'user.profiles.work.organization.name', null),
        organizationTitle: get(this.props, 'user.profiles.work.organization.title', null)
      };
    }
  };

  showModal = (e, profileTypeId) => {
    e.preventDefault();
    this.props.form.resetFields();
    this.setState({
      profileTypeId: profileTypeId,
      disableModalUpdate: true,
      addressModalVisible: true
    });
  };

  handleUpdateInformation = () => {
    const { props, state } = this,
      { t, form, user } = props,
      { profileTypeId } = state,
      { validateFields, isFieldTouched } = form;

    validateFields({ force: true }, (err, allValues) => {
      if (!err) {
        const updatedValues = pickBy(allValues, (value, key) => isFieldTouched(key));

        const values = {};
        for (let key in updatedValues) {
          values[key] = updatedValues[key]
            ? updatedValues[key]
            : updatedValues[key] === '' || updatedValues[key] === null
              ? null
              : undefined;
        }

        if (updatedValues.postalCode && !updatedValues.country) {
          values.country = allValues.country;
        } else if (updatedValues.country && !updatedValues.postalCode) {
          values.postalCode = allValues.postalCode;
        }

        if (values.number) {
          const number = values.number.toString();
          if (number.length <= 4) {
            values.number = number;
          } else
          if (number.length <= 7) {
            values.number = number.substring(0, 3) + '-' + number.substring(3);
          } else
          if (number.length <= 10) {
            values.number = number.substring(0, 3) + '-' + number.substring(3, 6) + '-' + number.substring(6);
          } else
          if (number.length > 10) {
            const countryLength = number.length - 10;
            values.number = number.substring(0, countryLength) + '-' +
                            number.substring(countryLength, countryLength + 3) + '-' +
                            number.substring(countryLength + 3, countryLength + 6) + '-' +
                            number.substring(countryLength + 6);
          } else {
            values.number = number;
          }
        }

        if (!isEmpty(values)) {
          const platformId = this.props.origin === 'member' ? this.props.platformId : null;
          this.props.updateMemberProfile(values, user.id, profileTypeId, platformId).then(() => {
            notification.success({
              message: t('API_NOTIFICATIONS:success'),
              description: t('API_NOTIFICATIONS:modifications-saved')
            });
            this.props.updateSessionState();
            this.setState({ addressModalVisible: false });
          });
        }
      }
    });
  };

  handleCancelInformation = () => {
    const { form } = this.props;
    form.resetFields();
    this.setState({ addressModalVisible: false });
  };

  deleteCorporate = () => {
    const platformId = this.props.origin === 'member' ? this.props.platformId : null;
    this.props.deleteMemberWorkProfile(this.props.user.id, platformId).then(() => {
      this.props.updateSessionState();
    });
  };

  handleFieldsOnChange = () => {
    this.setState({ disableModalUpdate: false });
  };

  unformatPhoneNumber = value => value
    ? value.toString().split('-').join('').split('(').join('').split(')').join('').split('+').join('').split(' ').join('').split('.').join('')
    : value;

  render() {
    const { t, user, userId, platformId, origin, checkAuthorization } = this.props;
    const { profileTypeId } = this.state;

    const isPersonal = this.state.profileTypeId === PERSONAL ? true : false;

    let personalInformation = {};
    let corporateInformation = {};

    if (user.profiles || user.profile) {
      personalInformation = this.getUserInformation(PERSONAL);
      corporateInformation = this.getUserInformation(CORPORATE);
    }

    const isPersonalProfileComplete =
      personalInformation &&
      personalInformation.address &&
      personalInformation.city &&
      personalInformation.country &&
      personalInformation.postalCode &&
      ((personalInformation.province &&
        (personalInformation.country === 'CA' || personalInformation.country === 'US')) ||
        (personalInformation.country !== 'CA' && personalInformation.country !== 'US'))
        ? true
        : false;

    const isCorporateProfileComplete =
      corporateInformation &&
      corporateInformation.organizationName &&
      corporateInformation.address &&
      corporateInformation.city &&
      corporateInformation.country &&
      corporateInformation.postalCode &&
      ((corporateInformation.province &&
        (corporateInformation.country === 'CA' || corporateInformation.country === 'US')) ||
        (corporateInformation.country !== 'CA' && corporateInformation.country !== 'US'))
        ? true
        : false;

    const doesCorporateExist = get(user, 'profiles.work', null) ? true : false;

    const personalCardContent = personalInformation
      ? [
          personalInformation.address || personalInformation.unit
            ? `${personalInformation.address}${
                personalInformation.unit ? ', ' + personalInformation.unit : ''
              }`
            : null,
          personalInformation.city || personalInformation.province
            ? `${personalInformation.city ? personalInformation.city : ''}${
                personalInformation.province ? ', ' + personalInformation.province : ''
              }`
            : null,
          personalInformation.postalCode ? personalInformation.postalCode : null
        ]
      : null;

    const corporateCardContent = corporateInformation
      ? [
          corporateInformation.organizationName ? corporateInformation.organizationName : null,
          corporateInformation.address || corporateInformation.unit
            ? `${corporateInformation.address}${
                corporateInformation.unit ? ', ' + corporateInformation.unit : ''
              }`
            : null,
          corporateInformation.city || corporateInformation.province
            ? `${corporateInformation.city ? corporateInformation.city : ''}${
                corporateInformation.province ? ', ' + corporateInformation.province : ''
              }`
            : null,
          corporateInformation.postalCode ? corporateInformation.postalCode : null
        ]
      : null;

    const isOwnerOrAdmin = checkAuthorization({ platform: platformId }, [
      ['roles.platform.SUPERADMIN', () => origin !== 'profile'],
      ['roles.platform.OWNER', () => origin !== 'profile'],
      ['roles.platform.ADMINISTRATOR', () => origin !== 'profile']
    ]);

    const notMe = userId !== user.id;

    return (
      <div className="AdditionalInformation">
        <h3>{t('additional-information')}</h3>
        <div className="ProfileTypeCards">
          <FundkyCard
            className={'PersonalCard no-overflow'}
            title={t('personal-address')}
            icon={<FontAwesomeIcon icon={['fal', 'home']} />}
            onCardClick={e => this.showModal(e, PERSONAL)}
            action={!notMe &&
              <a title={t('edit')} onClick={e => this.showModal(e, PERSONAL)}>
                {<FontAwesomeIcon icon={['fal', 'pencil-alt']} />}
              </a> ||
              <a title={t('view')} onClick={e => this.showModal(e, PERSONAL)}>
                {<FontAwesomeIcon icon={['fal', 'eye']} />}
              </a>
            }
          >
            {isPersonalProfileComplete ? (
              personalCardContent.map((content, index) => {
                return <p key={index}>{content}</p>;
              })
            ) : (
              <div>
                <p>{t('profile-incomplete')}</p>
                <a onClick={e => this.showModal(e, PERSONAL)}>{t('add-address')}</a>
              </div>
            )}
          </FundkyCard>

          {doesCorporateExist ? (
            <FundkyCard
              className={'CorporateCard no-overflow'}
              title={t('corporate-address')}
              icon={<FontAwesomeIcon icon={['fal', 'building']} />}
              action={
                <React.Fragment>
                  {!notMe &&
                  <>
                    <a title={t('edit')} onClick={e => this.showModal(e, CORPORATE)}>
                      {<FontAwesomeIcon icon={['fal', 'pencil-alt']} />}
                    </a>
                    <Popconfirm
                      title={t('corporate-delete-confirm')}
                      onConfirm={this.deleteCorporate}
                      okText={t('Yes')}
                      cancelText={t('No')}
                    >
                      <a title={t('delete')}>
                        <FontAwesomeIcon icon={['fal', 'trash-alt']} />
                      </a>
                    </Popconfirm>
                  </> ||
                  <a title={t('view')} onClick={e => this.showModal(e, CORPORATE)}>
                    {<FontAwesomeIcon icon={['fal', 'eye']} />}
                  </a>
                  }
                </React.Fragment>
              }
            >
              {isCorporateProfileComplete ? (
                corporateCardContent.map((content, index) => {
                  return <p key={index}>{content}</p>;
                })
              ) : (
                <div>
                  <p>{t('profile-incomplete')}</p>
                  <a onClick={e => this.showModal(e, CORPORATE)}>{t('add-address')}</a>
                </div>
              )}
            </FundkyCard>
          ) : notMe ? null : ( //isOwnerOrAdmin
            <div
              className="FundkyCard empty-corporate-card"
              onClick={e => this.showModal(e, CORPORATE)}
            >
              <FontAwesomeIcon icon={['fas', 'plus']} />
              <p>{t('add-a-corporate-address')}</p>
            </div>
          )}
        </div>
        <FundkyModal
          displayModal={this.state.addressModalVisible}
          titleIcon={profileTypeId === PERSONAL ? ['fal', 'home'] : ['fal', 'building']}
          title={isPersonal ? t('personal-address') : t('corporate-address')}
          acceptLabel={t('update')}
          acceptDisabled={this.state.disableModalUpdate}
          acceptAction={() => this.handleUpdateInformation(profileTypeId)}
          cancelAction={this.handleCancelInformation}
        >
          <Form className="AdditionalAddressForm" onChange={this.handleFieldsOnChange}>
            <CountryField
              disabled={notMe}
              form={this.props.form}
              initialValue={
                isPersonal && personalInformation
                  ? personalInformation.country
                  : corporateInformation.country
              }
              className={'member-country'}
              label={false}
              onChangeHandler={value => {
                this.handleFieldsOnChange();
                return this.onCountryChange(value, isPersonal);
              }}
              required={true}
              dropdownClassName="overModal"
            />
            {!isPersonal && (
              <React.Fragment>
                <TextField
                  disabled={notMe}
                  form={this.props.form}
                  placeholder={t('organization-name')}
                  fieldId={'organizationName'}
                  initialValue={
                    corporateInformation && corporateInformation.organizationName
                      ? corporateInformation.organizationName
                      : null
                  }
                  fieldOptions={{
                    rules: [...simpleFieldValidator()]
                  }}
                />
                <TextField
                  disabled={notMe}
                  form={this.props.form}
                  placeholder={t('organization-title')}
                  fieldId={'organizationTitle'}
                  initialValue={
                    corporateInformation && corporateInformation.organizationTitle
                      ? corporateInformation.organizationTitle
                      : null
                  }
                  fieldOptions={{
                    rules: [...simpleFieldValidator()]
                  }}
                />
              </React.Fragment>
            )}
            <TextField
              disabled={notMe}
              form={this.props.form}
              placeholder={t('address')}
              fieldId={'address'}
              initialValue={
                isPersonal && personalInformation
                  ? personalInformation.address
                  : corporateInformation.address
              }
              fieldOptions={{
                rules: [
                  ...simpleFieldValidator(),
                  {
                    pattern: /^.{5,128}$/,
                    message: t('maximum')
                  }
                ]
              }}
            />
            <TextField
              disabled={notMe}
              form={this.props.form}
              placeholder={t('unit')}
              fieldId={'unit'}
              initialValue={
                isPersonal && personalInformation
                  ? personalInformation.unit
                  : corporateInformation.unit
              }
            />
            <TextField
              disabled={notMe}
              form={this.props.form}
              placeholder={t('city')}
              fieldId={'city'}
              initialValue={
                isPersonal && personalInformation
                  ? personalInformation.city
                  : corporateInformation.city
              }
              fieldOptions={{
                rules: [
                  ...simpleFieldValidator(),
                  {
                    pattern: /^.{3,128}$/,
                    message: t('city-maximum')
                  }
                ]
              }}
            />
            <ProvinceField
              disabled={notMe}
              form={this.props.form}
              initialValue={
                isPersonal && personalInformation
                  ? personalInformation.province
                  : corporateInformation.province
              }
              label={false}
              className={'member-province'}
              required={
                this.props.form.getFieldValue('country') === 'CA' ||
                this.props.form.getFieldValue('country') === 'US' ||
                false
              }
              dropdownClassName="overModal"
              onChange={this.handleFieldsOnChange}
            />
            <PostalCodeField
              disabled={notMe}
              form={this.props.form}
              className={'member-postal-code'}
              label={false}
              placeholder={true}
              initialValue={
                isPersonal && personalInformation
                  ? personalInformation.postalCode
                  : corporateInformation.postalCode
              }
              country={isPersonal ? this.state.personalCountry : this.state.corporateCountry}
              required={true}
            />
            {/* <TextField
              disabled={notMe}
              form={this.props.form}
              placeholder={t('phone')}
              fieldId={'number'}
              initialValue={isPersonal && personalInformation ? personalInformation.phone : corporateInformation.phone}
              fieldOptions={{rules: [
                {
                  pattern: /^(?:1-)?[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
                  message: t('invalid-phone')
                }
              ]}}
            /> */}
            <Form.Item
              className='NumberField'
              hasFeedback
            >
              {this.props.form.getFieldDecorator('number', {
                initialValue: isPersonal && personalInformation
                  ? this.unformatPhoneNumber(personalInformation.phone)
                  : this.unformatPhoneNumber(corporateInformation.phone),
                rules: [
                  {
                    pattern: /^(?:1)?[0-9]{3}[0-9]{3}[0-9]{4}$/,
                    message: t('invalid-phone')
                  }
                ]
              })(
                <InputNumber
                  disabled={notMe}
                  placeholder={t('phone')}
                  parser={value => {
                    return this.unformatPhoneNumber(value)
                  }}
                  formatter={value => {
                    if (value.length <= 4) {
                      return value;
                    } else
                    if (value.length <= 7) {
                      return value.substring(0, 3) + '-' + value.substring(3);
                    } else
                    if (value.length <= 10) {
                      return value.substring(0, 3) + '-' + value.substring(3, 6) + '-' + value.substring(6);
                    } else
                    if (value.length > 10) {
                      const countryLength = value.length - 10;
                      return value.substring(0, countryLength) + '-' +
                             value.substring(countryLength, countryLength + 3) + '-' +
                             value.substring(countryLength + 3, countryLength + 6) + '-' +
                             value.substring(countryLength + 6);
                    } else {
                      return value;
                    }
                  }}
                />
              )}
            </Form.Item>
            <TextField
              disabled={notMe}
              form={this.props.form}
              placeholder={t('extension')}
              fieldId={'ext'}
              initialValue={
                isPersonal && personalInformation
                  ? personalInformation.ext
                  : corporateInformation.ext
              }
            />
          </Form>
        </FundkyModal>
      </div>
    );
  }
}

AddressForm.defaultProps = {
  member: {
    information: {
      address: '',
      country: '',
      province: '',
      postalCode: '',
      phone: {
        number: ''
      }
    }
  }
};

AddressForm.propTypes = {
  form: PropTypes.object.isRequired,
  origin: PropTypes.oneOf(['member', 'profile']).isRequired
};
export default translate('AddressForm')(AddressForm);
