import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import classnames from 'classnames';
import { Form, Checkbox } from 'antd';
import FundkyTooltip from '../FundkyTooltip';

class CheckboxField extends React.Component {
  render() {
    const { t, form, fieldId, className, initialValue, fieldOptions, checkboxText, label, colon, hasFeedback, onChange, required, requiredMessage, notice, tooltip, ...rest } = this.props;
    const { getFieldDecorator } = form;

    const input = (
      <Checkbox disabled={this.props.disabled}>
        {checkboxText}
      </Checkbox>
    );

    let labelProperties = tooltip ? {
      label: (<span>{label+":"}<FundkyTooltip title={tooltip} /></span>),
      colon: false,
    } : {
      label:label,
      colon: colon
    };

    const rules = this.props.required || this.props.rules ? {
      rules: [
        ...(this.props.required
          ? [{
              required: required,
              transform: value => (value || undefined),
              type: 'boolean',
              message: requiredMessage || t('FIELDS-VALIDATION:required-message')
            }]
          : []),
        ...this.props.rules
      ]
    } : null;

    const options = {
      valuePropName: 'checked',
      initialValue: initialValue,
      ...rules,
      ...fieldOptions,
      onChange:(event) => {
        if(onChange) onChange(event);
        return;
      },
    };

    return (
      <Form.Item
        className={classnames( 'CheckboxField', className )}
        hasFeedback={hasFeedback}
        {...labelProperties}
        {...rest}
      >
        {getFieldDecorator(fieldId, options)(input)}
        {notice}
      </Form.Item>
    );
  }

}

CheckboxField.defaultProps = {
  fieldId: 'checkbox',
  fieldOptions: {},
  disabled: false,
  initialValue: undefined,
  hasFeedback: false,
  required: false,
  requiredMessage: null,
  label: false,
  colon: false,
  notice: null,
  tooltip: null,
  rules: [],
};

CheckboxField.propTypes = {
  form: PropTypes.object.isRequired,
  fieldId: PropTypes.string.isRequired,
  required: PropTypes.bool,
  fieldOptions: PropTypes.object,
  className: PropTypes.string,
  colon: PropTypes.bool,
  hasFeedback: PropTypes.bool,
  disabled: PropTypes.bool,
  checkboxText: PropTypes.node,
  rules: PropTypes.array
};

export default translate('CheckboxField')(CheckboxField);
