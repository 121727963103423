import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { isEqual, get } from 'lodash';

import HeadMetas from '../../common/HeadMetasContainer';
import Plugin from '../../plugin/Plugin';

import { isDateBeforeCurrentDate } from 'Parts/common/dateUtils';
import { isPluginActive } from '../../plugin/pluginActions';

import {
  DONATION_CREATE_WIZARD_DONATION,
  DONATION_CREATE_WIZARD_INFORMATION,
  DONATION_CREATE_WIZARD_PAYMENT,
  DONATION_CREATE_WIZARD_CONFIRMATION,
  DONATION_CREATE_SURVEY
} from '../../ui/uiUtils';
import CreateDonationForm from '../components/CreateDonationFormContainer';
import CreateSurveyAttemptForm from '../components/CreateSurveyAttemptFormContainer';
import CreateInformationForm from '../components/CreateInformationFormContainer';
import CreateInformationFormUser from '../components/CreateInformationFormUserContainer';
import CreatePaymentForm from '../components/CreatePaymentFormContainer';
import CreateConfirmationPanel from '../components/CreateConfirmationPanelContainer';
import AvatarThumbnail from '../../common/AvatarThumbnailContainer';
import HelpText from '../../common/HelpText';
import FundkyButton from 'Parts/ui/components/FundkyButton';
import FundkyModal from 'Parts/ui/components/FundkyModal';
import ChangeRecipientModalContent from '../components/ChangeRecipientModalContentContainer';

import DonationPage_en from './locales/DonationPage_en.json';
import DonationPage_fr from './locales/DonationPage_fr.json';

import './DonationPage.less';

const { TabPane } = Tabs;

class DonationPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      cancel: false,
      clearTaxReceiptDonation: () => {},
      clearTaxReceiptDonationImported: false,
    };

    this.modalRef = React.createRef();
    this.pluginRef = React.createRef();

    // TODO Don't use location.state to know where we come from: when navigate back and come back to this page, the info is lost --> use react-router-last-location instead
    // No need to put it in the state because it is immutable
    this.from = props.location.state && props.location.state.from;

    i18next.addResourceBundle('en', 'DonationPage', DonationPage_en);
    i18next.addResourceBundle('fr', 'DonationPage', DonationPage_fr);
  }

  componentDidMount = async () => {
    await this.props.fetchPlatformIdBySurveyList(this.props.platformId, this.props.campaign.id, [], "donation-form", this.props.locale)
    this.props.activateCreationMode();
    //this.props.fetchAllSurveys();
    this.selectEntity();

    if (
      isPluginActive(this.props.platform, 'TaxReceipt') &&
      this.pluginRef.current &&
      this.pluginRef.current.actions &&
      this.pluginRef.current.actions.clearTaxReceiptDonation
    ) {
      this.setState({
        clearTaxReceiptDonation: this.pluginRef.current.actions.clearTaxReceiptDonation,
        clearTaxReceiptDonationImported: true
      });
    }
  }

  componentWillUnmount() {
    const { props } = this,
      { t, history, clearDonation, deactivateCreationMode } = props,
      nextLocation = (history && history.location && history.location.pathname) || null;
    const baseUrl = this.getBaseUrl(false);

    if (
      !nextLocation.includes(`${baseUrl}/${t('URL:give')}`) &&
      !nextLocation.includes(`${baseUrl}/${t('URL:give/information')}`) &&
      !nextLocation.includes(`${baseUrl}/${t('URL:give/payment')}`) &&
      !nextLocation.includes(`${baseUrl}/${t('URL:give/confirmation')}`)
    ) {
      this.props.clearDonation();
      this.props.deactivateCreationMode();
      if (this.state.clearTaxReceiptDonation) {
        this.state.clearTaxReceiptDonation();
      }
    } else if (
      this.props.location.pathname.includes(t('URL:give/confirmation')) &&
      !nextLocation.includes(`${baseUrl}/${t('URL:give/confirmation')}`) &&
      !nextLocation.includes(`${baseUrl}/${t('URL:give/information')}`) &&
      !nextLocation.includes(`${baseUrl}/${t('URL:give/payment')}`) &&
      nextLocation.includes(`${baseUrl}/${t('URL:give')}`)
    ) {
      this.props.clearDonation();
      this.props.deactivateCreationMode();
      if (this.state.clearTaxReceiptDonation) {
        this.state.plugins.TaxReceipt.actions.clearTaxReceiptDonation();
      }
    }
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.campaign !== this.props.campaign && this.props.campaign.id !== undefined) {
      await this.props.fetchPlatformIdBySurveyList(this.props.platformId, this.props.campaign.id, [], "donation-form", this.props.locale)
    }
    const badge = document.getElementsByClassName("grecaptcha-badge")[0];
    if (badge && badge.classList && badge.classList.contains("show")) {
      badge.classList.remove("show");
    }
    const {
      participant,
      team,
      campaign,
      organization,
      selectParticipant,
      selectTeam,
      selectCampaign,
      selectOrganization
    } = this.props;
    const pageType = this.getPageType();

    switch (pageType) {
      case 'participant':
      case 'participant-solo':
        if (!isEqual(prevProps.participant, participant)) {
          selectParticipant(participant);
        }
        break;

      case 'team':
        if (!isEqual(prevProps.team, team)) {
          selectTeam(team);
        }
        break;

      case 'campaign':
        if (!isEqual(prevProps.campaign, campaign)) {
          selectCampaign(campaign);
        }
        break;

      case 'organization':
        if (!isEqual(prevProps.organization, organization)) {
          organization.logo = platform.logo;
          selectOrganization(organization);
        }
        break;
    }

    if (
      !this.state.clearTaxReceiptDonationImported &&
      isPluginActive(this.props.platform, 'TaxReceipt') &&
      this.pluginRef.current &&
      this.pluginRef.current.actions &&
      this.pluginRef.current.actions.clearTaxReceiptDonation
    ) {
      this.setState({
        clearTaxReceiptDonation: this.pluginRef.current.actions.clearTaxReceiptDonation,
        clearTaxReceiptDonationImported: true
      });
    }
  }

  selectEntity = () => {
    const { platform, organization, campaign, team, participant } = this.props;
    const pageType = this.getPageType();

    switch (pageType) {
      case 'participant':
      case 'participant-solo':
        this.props.selectParticipant(participant);
        break;

      case 'team':
        this.props.selectTeam(team);
        break;

      case 'campaign':
        this.props.selectCampaign(campaign);
        break;

      case 'organization':
        organization.logo = platform.logo;
        this.props.selectOrganization(organization);
        break;
    }
  };

  getPageType = () => {
    const { t, location, match } = this.props;
    const { params } = match;
    return location.pathname.includes(t('URL:organization'))
      ? 'organization'
      : params.participantAlias && params.teamAlias
      ? 'participant'
      : params.participantAlias
      ? 'participant-solo'
      : params.teamAlias
      ? 'team'
      : params.campaignAlias
      ? 'campaign'
      : null;
  };

  getBaseUrl = (withLocale = true) => {
    const pageType = this.getPageType();
    const { t, locale, match } = this.props;
    const { params } = match;

    const localeUrl = withLocale ? `/${locale}` : '';

    switch (pageType) {
      case 'participant':
        return `${localeUrl}/${params.campaignAlias}/${params.teamAlias}/${params.participantAlias}`;
      case 'participant-solo':
        return `${localeUrl}/${params.campaignAlias}/${t('URL:individual-participant')}/${
          params.participantAlias
        }`;
      case 'team':
        return `${localeUrl}/${params.campaignAlias}/${params.teamAlias}`;
      case 'campaign':
        return `${localeUrl}/${params.campaignAlias}`;
      case 'organization':
        return `${localeUrl}/${t('URL:organization')}`;
    }
  };

  getActiveKey = () => {
    const { location, history, t, currentStep, checkout } = this.props;
    const baseUrl = this.getBaseUrl();
    if (
      currentStep === DONATION_CREATE_WIZARD_CONFIRMATION &&
      !location.pathname.includes(t('URL:give/confirmation'))
    ) {
      switch (get(checkout, 'checkout.transactionStatus', 'ERROR')) {
        case 'APPROVED':
          history.push(`${baseUrl}/${t('URL:give/confirmation/success')}`);
          break;

        case 'DECLINED':
          history.push(`${baseUrl}/${t('URL:give/confirmation/failed')}`);
          break;

        case 'PENDING':
          //history.push(`${baseUrl}/${t('URL:give/confirmation/pending')}`);
          break;

        case 'ERROR':
          history.push(`${baseUrl}/${t('URL:give/confirmation/error')}`);
          break;
      }
    }

    if (location.pathname.includes(t('URL:give/information'))) {
      return DONATION_CREATE_WIZARD_INFORMATION;
    } else if (location.pathname.includes(t('URL:give/survey'))) {
      return DONATION_CREATE_SURVEY;
    } else if (location.pathname.includes(t('URL:give/payment'))) {
      return DONATION_CREATE_WIZARD_PAYMENT;
    } else if (location.pathname.includes(t('URL:give/confirmation'))) {
      return DONATION_CREATE_WIZARD_CONFIRMATION;
    } else if (location.pathname.includes(t('URL:give'))) {
      return DONATION_CREATE_WIZARD_DONATION;
    } else {
      return null;
    }
  };

  handleTabChange = (activeKey, cancel = false) => {
    const { history, t, checkout } = this.props;
    const baseUrl = this.getBaseUrl();

    this.setState({
      cancel
    });

    if (activeKey === DONATION_CREATE_WIZARD_DONATION) {
      history.push(`${baseUrl}/${t('URL:give')}`);
    } else if (activeKey === DONATION_CREATE_SURVEY) {
      history.push(`${baseUrl}/${t('URL:give/survey')}`);
    } else if (activeKey === DONATION_CREATE_WIZARD_INFORMATION) {
      history.push(`${baseUrl}/${t('URL:give/information')}`);
    } else if (activeKey === DONATION_CREATE_WIZARD_PAYMENT) {
      history.push(`${baseUrl}/${t('URL:give/payment')}`);
    } else if (activeKey === DONATION_CREATE_WIZARD_CONFIRMATION) {
      if (cancel) {
        history.push(`${baseUrl}/${t('URL:give/confirmation/cancelled')}`);
      } else {
        switch (get(checkout, 'checkout.transactionStatus', 'ERROR')) {
          case 'APPROVED':
            history.push(`${baseUrl}/${t('URL:give/confirmation/success')}`);
            break;

          case 'DECLINED':
            history.push(`${baseUrl}/${t('URL:give/confirmation/failed')}`);
            break;

          case 'PENDING':
            //history.push(`${baseUrl}/${t('URL.confirmation.pending')}`);
            break;

          case 'ERROR':
            history.push(`${baseUrl}/${t('URL:give/confirmation/error')}`);
            break;
        }
      }
    }
  };

  handleBackClick = back => {
    this.handleTabChange(back);
  };

  handleCancelClick = () => {
    this.props.cancelTransaction();
    this.handleTabChange(DONATION_CREATE_WIZARD_CONFIRMATION, true);
  };

  handleShowModal = () => {
    this.modalRef.current.getWrappedInstance('FundkyModal').showModal();
  };

  handleHideModal = () => {
    this.modalRef.current.getWrappedInstance('FundkyModal').hideModal();
  };

  render() {
    const {
      t,
      locale,
      selectedOrganization,
      selectedCampaign,
      selectedTeam,
      selectedParticipant,
      donationInformation,
      userTypeId,
      donationDisabledOn
    } = this.props;
    const activeKey = this.getActiveKey();

    if (donationDisabledOn && isDateBeforeCurrentDate(donationDisabledOn, true)) {
      const baseUrl = this.getBaseUrl();
      return <Redirect to={`${baseUrl}`} />;
    }

    // A recipient has been provided, but it is not in the store yet.
    if (
      !activeKey ||
      (activeKey !== DONATION_CREATE_WIZARD_DONATION && donationInformation === null)
    ) {
      if (!this.state.cancel) {
        const baseUrl = this.getBaseUrl();
        return <Redirect to={`${baseUrl}/${t('URL:give')}`} />;
      }
    }

    // A recipient has been provided, but it is not in the store yet.
    let type, entity;
    if ((entity = selectedOrganization)) {
      type = 'organization';
    } else if ((entity = selectedCampaign)) {
      type = 'campaign';
    } else if ((entity = selectedTeam)) {
      type = 'team';
    } else if ((entity = selectedParticipant)) {
      type = 'participant';
    }

    if (!entity) {
      return null;
    }

    const changeRecipientButton =
      this.props.donationCreateWizardState !== 'DONATION_CREATE_WIZARD_CONFIRMATION' &&
      ((this.props.platform &&
        this.props.platform.settings &&
        this.props.platform.settings.campaign &&
        Number(this.props.platform.settings.campaign.enabled)) ||
        (this.props.lastCampaigns && this.props.lastCampaigns.length > 0)) ? (
        <FundkyButton
          className="DonationPage__ChangeRecipientBtn"
          type="text-primary"
          icon={['fal', 'pencil-alt']}
          action={{ func: this.handleShowModal }}
        />
      ) : null;

    const recipientInfo = entity ? (
      <div className="DonationPage__Divider DonationPage__Divider--title DonationPage__Header FundkyWrap">
        <AvatarThumbnail
          entity={entity}
          primaryContent={entity.name || (entity.member && entity.member.name) || ''}
          secondaryContent={<HelpText>{t('recipient')}</HelpText>}
          className="AvatarThumbnail--title"
          avatarSize={127}
        />
        {changeRecipientButton}
        <FundkyModal
          ref={this.modalRef}
          titleIcon={['fal', 'user-edit']}
          title={t('update-recipient')}
          displayActions={false}
        >
          <ChangeRecipientModalContent
            entity={entity}
            type={type}
            handleHideModal={this.handleHideModal}
          />
        </FundkyModal>
      </div>
    ) : null;

    const routes = [
      { path: `/${locale}/:campaignAlias` },
      { path: `/${locale}/:campaignAlias/:teamAlias` },
      { path: `/${locale}/:campaignAlias/${t('URL:individual-participant')}/:participantAlias` },
      { path: `/${locale}/:campaignAlias/:teamAlias/:participantAlias` }
    ];

    const InformationForm = userTypeId === 2 ? CreateInformationFormUser : CreateInformationForm;
    let hideSurveyTab = this.props.surveyId ? false : true;

    return (
      <div className="DonationPage">
        <HeadMetas title={t('make-a-donation.title')} />
        {isPluginActive(this.props.platform, 'TaxReceipt') &&
          this.props.plugin &&
          this.props.platform &&
          this.props.platform.statusId === 1 &&
          this.props.organization.typeId === 2 && <Plugin ref={this.pluginRef} name="TaxReceipt" />}
        {recipientInfo}
        <Tabs className="FillActive" activeKey={activeKey} onChange={this.handleTabChange}>
          <TabPane
            tab={
              <span>
                {t('contribution')}{' '}
                {activeKey !== DONATION_CREATE_WIZARD_DONATION && !this.state.cancel && (
                  <FontAwesomeIcon icon={['fas', 'check']} />
                )}
              </span>
            }
            disabled={activeKey !== DONATION_CREATE_WIZARD_DONATION}
            key={DONATION_CREATE_WIZARD_DONATION}
          >
            <Switch>
              <Route
                path={`/${locale}/${t('URL:organization/give')}`}
                render={props => (
                  <CreateDonationForm
                    {...props}
                    from={this.from}
                    handleTabChange={this.handleTabChange}
                    handleCancelClick={this.handleCancelClick}
                  />
                )}
              />
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={`${route.path}/${t('URL:give')}`}
                  render={props => (
                    <CreateDonationForm
                      {...props}
                      from={this.from}
                      handleTabChange={this.handleTabChange}
                      handleCancelClick={this.handleCancelClick}
                    />
                  )}
                />
              ))}
            </Switch>
          </TabPane>
          <TabPane
            tab={
              <span>
                {t('information')}{' '}
                {
                  activeKey !== DONATION_CREATE_WIZARD_INFORMATION &&
                  activeKey !== DONATION_CREATE_WIZARD_DONATION &&
                  !this.state.cancel && <FontAwesomeIcon icon={['fas', 'check']} />
                }
              </span>
            }
            disabled={
              activeKey !== DONATION_CREATE_WIZARD_INFORMATION
            }
            key={DONATION_CREATE_WIZARD_INFORMATION}
          >
            <Switch>
              <Route
                path={`/${locale}/${t('URL:organization/give/information')}`}
                render={props => (
                  <InformationForm
                    {...props}
                    from={this.from}
                    handleTabChange={this.handleTabChange}
                  />
                )}
              />
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={`${route.path}/${t('URL:give/information')}`}
                  render={props => (
                    <InformationForm
                      {...props}
                      from={this.from}
                      handleTabChange={this.handleTabChange}
                    />
                  )}
                />
              ))}
            </Switch>
          </TabPane>
          {
            !hideSurveyTab &&
            (<TabPane
              tab={
                <span>
                  {t('survey')}{' '}
                  {
                    activeKey !== DONATION_CREATE_WIZARD_DONATION &&
                    activeKey !== DONATION_CREATE_SURVEY &&
                    activeKey !== DONATION_CREATE_WIZARD_INFORMATION &&
                    !this.state.cancel && <FontAwesomeIcon icon={['fas', 'check']} />
                  }
                </span>
              }
              disabled={
                activeKey !== DONATION_CREATE_SURVEY
              }
              key={DONATION_CREATE_SURVEY}
            >
              <Switch>
                <Route
                  path={`/${locale}/${t('URL:organization/give/survey')}`}
                  render={props => (
                    <CreateSurveyAttemptForm
                      {...props}
                      from={this.from}
                      handleTabChange={this.handleTabChange}
                      handleCancelClick={this.handleCancelClick}
                    />
                  )}
                />
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={`${route.path}/${t('URL:give/survey')}`}
                    render={props => (
                      <CreateSurveyAttemptForm
                        {...props}
                        from={this.from}
                        handleTabChange={this.handleTabChange}
                        handleCancelClick={this.handleCancelClick}
                      />
                    )}
                  />
                ))}
              </Switch>
            </TabPane>)
          }
          <TabPane
            tab={
              <span>
                {t('payment')}{' '}
                {activeKey === DONATION_CREATE_WIZARD_CONFIRMATION && !this.state.cancel && (
                  <FontAwesomeIcon icon={['fas', 'check']} />
                )}
              </span>
            }
            disabled={
              activeKey !== DONATION_CREATE_WIZARD_PAYMENT
            }
            key={DONATION_CREATE_WIZARD_PAYMENT}
          >
            <Switch>
              <Route
                path={`/${locale}/${t('URL:organization/give/payment')}`}
                render={props => (
                  <CreatePaymentForm
                    {...props}
                    from={this.from}
                    handleTabChange={this.handleTabChange}
                    handleBackClick={this.handleBackClick}
                    handleCancelClick={this.handleCancelClick}
                  />
                )}
              />
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={`${route.path}/${t('URL:give/payment')}`}
                  render={props => (
                    <CreatePaymentForm
                      {...props}
                      from={this.from}
                      handleTabChange={this.handleTabChange}
                      handleBackClick={this.handleBackClick}
                      handleCancelClick={this.handleCancelClick}
                    />
                  )}
                />
              ))}
            </Switch>
          </TabPane>
          <TabPane
            tab={<span>{t('confirmation')}</span>}
            disabled={activeKey !== DONATION_CREATE_WIZARD_CONFIRMATION}
            key={DONATION_CREATE_WIZARD_CONFIRMATION}
          >
            <Switch>
              <Route
                path={`/${locale}/${t('URL:organization/give/confirmation')}`}
                render={props => (
                  <CreateConfirmationPanel
                    {...props}
                    from={this.from}
                    handleTabChange={this.handleTabChange}
                    cancel={this.state.cancel}
                  />
                )}
              />
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={`${route.path}/${t('URL:give/confirmation')}`}
                  render={props => (
                    <CreateConfirmationPanel
                      {...props}
                      from={this.from}
                      entity={entity}
                      type={type}
                      handleTabChange={this.handleTabChange}
                      cancel={this.state.cancel}
                    />
                  )}
                />
              ))}
            </Switch>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

DonationPage.propTypes = {
  location: PropTypes.object,
  selectedOrganization: PropTypes.object,
  selectedCampaign: PropTypes.object,
  selectedTeam: PropTypes.object,
  selectedParticipant: PropTypes.object,
  selectOrganization: PropTypes.func,
  selectCampaign: PropTypes.func,
  selectTeam: PropTypes.func,
  selectParticipant: PropTypes.func,
  clearDonation: PropTypes.func,
  currentStep: PropTypes.oneOf([
    DONATION_CREATE_WIZARD_DONATION,
    DONATION_CREATE_SURVEY,
    DONATION_CREATE_WIZARD_INFORMATION,
    DONATION_CREATE_WIZARD_PAYMENT,
    DONATION_CREATE_WIZARD_CONFIRMATION
  ])
};

export default translate('DonationPage')(DonationPage);
