import React from 'react';
import { Form, Button, Popconfirm, notification } from 'antd';
import FundkyModal from 'Parts/ui/components/FundkyModal';
import FundkyCard from 'Parts/ui/components/FundkyCard';
import { pickBy, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { nameFieldValidator } from 'Parts/ui/validations/fieldValidations';

import { isPluginActive } from '../../plugin/pluginActions';
import Plugin from '../../plugin/Plugin';
import { getLanguageIdByKey } from "Parts/common/langUtils";

import SubmitButton from 'Parts/common/SubmitButton';
import LanguageField from './fields/LanguageField';
import AddressField from 'Parts/ui/components/fields/AddressField';
import UnitField from 'Parts/ui/components/fields/UnitField';
import TitleField from 'Parts/ui/components/fields/TitleField';
import CityField from 'Parts/ui/components/fields/CityField';
import ProvinceField from 'Parts/ui/components/fields/ProvinceField';
import PostalCodeField from 'Parts/ui/components/fields/PostalCodeField';
import CountryField from 'Parts/ui/components/fields/CountryFieldContainer';
import OrganizationNameField from 'Parts/ui/components/fields/NameField';
import OrganizationTitleField from 'Parts/ui/components/fields/NameField';
//import WinkTooltip from '../../common/WinkTooltip';
import WinkNotification from '../../common/WinkNotification';

import {
  DONATION_CREATE_WIZARD_DONATION,
  DONATION_CREATE_WIZARD_PAYMENT,
  DONATION_CREATE_WIZARD_CONFIRMATION,
  DONATION_CREATE_SURVEY
} from '../../ui/uiUtils';

import CreateInformationForm_en from './locales/CreateInformationForm_en.json';
import CreateInformationForm_fr from './locales/CreateInformationForm_fr.json';

import './CreateInformationForm.less';
import TextField from 'Parts/ui/components/fields/TextField';

class CreateInformationFormUser extends React.Component {
  constructor(props) {
    super(props);

    const { infos, user } = props;
    const { profiles } = user;

    let general;
    let work;

    if (profiles) {
      general = profiles.general ? profiles.general : null;
      work = profiles.work ? profiles.work : null;
    }

    const entity = infos.type === 2 ? work : general;

    this.state = {
      country: (entity && entity.country) || null
    };

    this.modalRef = React.createRef();

    i18next.addResourceBundle('en', 'CreateInformationForm', CreateInformationForm_en);
    i18next.addResourceBundle('fr', 'CreateInformationForm', CreateInformationForm_fr);
  }

  handleSubmit = e => {
    e.preventDefault();

    const userInformation = this.getUserInformation();

    const organizationValues =
      this.props.infos.type === 2
        ? {
            organizationName: userInformation.organizationName,
            organizationTitle: userInformation.organizationTitle
          }
        : null;

    const values = {
      email: this.props.user.email,
      country: userInformation.country,
      gender: userInformation.genderId,
      firstname: userInformation.firstName,
      lastname: userInformation.lastName,
      locale: userInformation.languageId,
      address: userInformation.address,
      unit: userInformation.unit,
      city: userInformation.city,
      province: userInformation.province,
      postalCode: userInformation.postalCode,
      ...organizationValues,
      bulletinOptin: true,
      acceptedTermsConditions: true
    };
    this.props.storeUserInformation(values);
    this.props.surveyId ? this.props.handleTabChange(DONATION_CREATE_SURVEY) : this.props.handleTabChange(DONATION_CREATE_WIZARD_PAYMENT) 
  };

  // Returns user information as an object
  getUserInformation = () => {
    const { infos, user } = this.props;
    const { profiles } = user;

    let general;
    let work;

    if (profiles) {
      general = profiles.general ? profiles.general : null;
      work = profiles.work ? profiles.work : null;
    }
    const entity = infos.type === 2 ? work : general;

    return {
      country: (entity && entity.country) || null,
      genderId: user.honorificId || null,
      firstName: user.firstName || user.firstName,
      lastName: user.lastName || user.lastName,
      languageId: user.languageId,
      address: (entity && entity.address) || null,
      unit: (entity && entity.unit) || undefined,
      city: (entity && entity.city) || null,
      province: (entity && entity.province) || null,
      postalCode: (entity && entity.postalCode) || null,
      organizationName: (work && work.organization && work.organization.name) || null,
      organizationTitle: (work && work.organization && work.organization.title) || null
    };
  };

  handleBackClick = e => {
    e.preventDefault();
    this.props.handleTabChange(DONATION_CREATE_WIZARD_DONATION);
  };

  handleCancelClick = e => {
    e.preventDefault();
    this.props.handleTabChange(DONATION_CREATE_WIZARD_CONFIRMATION, true);
  };

  handleWinkCTA = e => {
    e.preventDefault();
    this.props.openLoginModal();
  };

  onChangeHandler = value => {
    this.setState({
      country: value
    });
  };

  showModal = e => {
    e.preventDefault();
    this.modalRef.current.getWrappedInstance('FundkyModal').showModal();
  };

  handleUpdateInformation = () => {
    const { form } = this.props;
    const { validateFields, isFieldTouched } = form;
    validateFields({ force: true }, (err, values) => {
      if (!err) {
        const {
          t,
          infos,
          user,
          updateMember,
          updateMemberProfile,
          updateSessionState
        } = this.props;

        const updatedValues = pickBy(values, (value, key) => isFieldTouched(key));

        if (!isEmpty(updatedValues)) {
          // MEMBER UPDATE
          const memberValues = (
            updatedValues.gender ||
            updatedValues.firstname ||
            updatedValues.lastname ||
            updatedValues.locale
          )
            ? {
                honorificId: updatedValues.gender || null,
                firstName: updatedValues.firstname || null,
                lastName: updatedValues.lastname || null,
                languageId: updatedValues.locale || null
              }
            : null;

          var isMemberEmpty = isEmpty(memberValues);

          if (!isMemberEmpty) {
            updateMember(memberValues, user.id).then(() => {
              updateSessionState();
            });
          }

          // PROFILE UPDATE
          const organizationValues =
            infos.type === 2 && (updatedValues.organizationName || updatedValues.organizationTitle)
              ? {
                  organizationName: updatedValues.organizationName,
                  organizationTitle: updatedValues.organizationTitle
                }
              : null;

          var country;
          if (updatedValues.postalCode && !updatedValues.country) {
            country = { country: values.country };
          }

          const profileValues =
            updatedValues.country ||
            updatedValues.address ||
            updatedValues.unit ||
            updatedValues.city ||
            updatedValues.province ||
            updatedValues.province === '' ||
            updatedValues.postalCode ||
            organizationValues
              ? {
                  country: updatedValues.country,
                  address: updatedValues.address,
                  unit: updatedValues.unit,
                  city: updatedValues.city,
                  province: updatedValues.province === '' ? null : updatedValues.province,
                  postalCode: updatedValues.postalCode,
                  ...organizationValues,
                  ...country
                }
              : null;

          if (!isEmpty(profileValues)) {
            const donationType = infos.type === 2 ? 2 : 1;
            updateMemberProfile(profileValues, user.id, donationType).then(() => {
              if (isMemberEmpty) {
                notification.success({
                  message: t('API_NOTIFICATIONS:success'),
                  description: t('API_NOTIFICATIONS:modifications-saved')
                });
              }
              updateSessionState();
            });
          }
        }
        this.modalRef.current.getWrappedInstance('FundkyModal').hideModal();
      }
    });
  };

  render() {
    const { t, infos, user, locale, form, platform } = this.props;

    const userInformation = this.getUserInformation();
    const gender = user.honorificId ? t(`gender-${user.honorificId}`) : null;

    const isCorporate = infos.type == 2 ? true : false;
    const icon = isCorporate ? 'building' : 'home';
    const isProvinceRequired = userInformation.country === 'CA' || userInformation.country === 'US';
    const isProvinceCompleted = !isProvinceRequired ? true : userInformation.city;
    const isProfileComplete = !isCorporate
      ? userInformation.address &&
        userInformation.city &&
        isProvinceCompleted &&
        userInformation.postalCode &&
        gender
        ? true
        : false
      : userInformation.address &&
        userInformation.city &&
        isProvinceCompleted &&
        userInformation.country &&
        userInformation.postalCode &&
        userInformation.organizationName &&
        userInformation.organizationTitle &&
        gender
        ? true
        : false;

    const cardContent = isProfileComplete ? (
      <p>
        {!isCorporate
          ? `${gender} ${userInformation.firstName} ${userInformation.lastName}`
          : userInformation.organizationName}
        <br />
        {userInformation.address}
        {userInformation.unit ? `, ${userInformation.unit}` : null}
        <br />
        {userInformation.city && userInformation.city} {userInformation.province}
        <br />
        {userInformation.postalCode}
        <br /><br />
        {t('preferred-language')}
        <br />
        {t("LANGUAGES:" + userInformation.languageId)}
      </p>
    ) : (
      <p>
        {t('profile-incomplete')}
        <br />
        <a onClick={this.showModal}>{t('add-address')}</a>
      </p>
    );

    const modalTitle = isCorporate ? t('modal-title-corporate') : t('modal-title-general');

    const OrganizationField = isCorporate ? (
      <div className="CreateInformationForm__Divider OrgNameTitle">
        <OrganizationNameField
          className="CreateInformationForm__Field"
          form={form}
          initialValue={userInformation.organizationName}
          fieldId="organizationName"
          label={false}
          placeholder={true}
          required={true}
          identity="company"
          maxlength={75}
        />
        <TitleField
          className="CreateInformationForm__Field"
          form={form}
          initialValue={userInformation.genderId}
          fieldFormat="radio"
          label={false}
        />
        <OrganizationTitleField
          className="CreateInformationForm__Field"
          form={form}
          initialValue={userInformation.organizationTitle}
          fieldId="organizationTitle"
          label={false}
          placeholder={true}
          required={true}
          identity="job"
          maxlength={75}
        />
      </div>
    ) : null;

    const infoDesc = (
      <React.Fragment>
        {t('get connected')}
        <br />
        <br />
        <Button className="WinkTooltip__CTA" onClick={this.handleWinkCTA}>
          {t('Connection')}
        </Button>
      </React.Fragment>
    );

    const defaultLocale =
      infos && infos.locale ||
      user && user.languageId ||
      getLanguageIdByKey(locale) ||
      undefined;

    return (
      <div className="FundkyWrap">
        <div id="CreateInformationForm" className="CreateInformationForm">
          <h2>{t('title')}</h2>
          {this.props.userTypeId === 1 && (
            <WinkNotification title={t('Fundky account')} description={infoDesc} closable={false} />
          )}

          <p>
            {t('connected-as')} <strong>{user.name}</strong>.{' '}
            <a onClick={() => this.props.logout()}>{t('not-you-log-out')}</a>{' '}
          </p>

          <FundkyCard
            className={'UserCard'}
            title={isCorporate ? t('corporate-address') : t('personal-address')}
            icon={<FontAwesomeIcon icon={['fal', icon]} />}
            action={<a onClick={this.showModal}>{t('edit')}</a>}
            onCardClick={e => this.showModal(e)}
          >
            {cardContent}
          </FundkyCard>

          {infos.type !== 3 &&
            infos.taxReceipt &&
            isPluginActive(platform, 'TaxReceipt') &&
            this.props.organization.typeId === 2 && (
              <Plugin name="TaxReceipt" component="GenerationNotice" />
            )}

          <FundkyModal
            ref={this.modalRef}
            displayModal={false}
            titleIcon={['fal', icon]}
            title={modalTitle}
            acceptLabel={t('update')}
            acceptAction={this.handleUpdateInformation}
          >
            <Form className="UpdateInformationForm" onSubmit={this.handleModalSubmit}>
              <p>{!isCorporate ? t('personal-update-info') : t('corporate-update-info')}</p>
              <div className="CreateInformationForm__Divider">
                <CountryField
                  className="CreateInformationForm__Field"
                  form={form}
                  initialValue={this.state.country}
                  label={false}
                  placeholder={true}
                  onChangeHandler={this.onChangeHandler}
                  required={true}
                  identity={isCorporate ? 'company' : 'user'}
                  dropdownClassName="overModal"
                />
              </div>

              {OrganizationField}

              {!isCorporate && (
                <React.Fragment>
                  <TitleField
                    className=""
                    form={form}
                    initialValue={userInformation.genderId}
                    fieldFormat="radio"
                    label={false}
                  />

                  <div className="CreateInformationForm__Divider">
                    <TextField
                      className="CreateInformationForm__Field"
                      form={form}
                      fieldId="firstname"
                      initialValue={userInformation.firstName}
                      placeholder={t('FIELDS:first-name')}
                      fieldOptions={{
                        rules: [...nameFieldValidator(true, 75)]
                      }}
                    />
                    <TextField
                      className="CreateInformationForm__Field"
                      form={form}
                      fieldId="lastname"
                      initialValue={userInformation.lastName}
                      placeholder={t('FIELDS:last-name')}
                      fieldOptions={{
                        rules: [...nameFieldValidator(true, 75)]
                      }}
                    />
                  </div>
                </React.Fragment>
              )}

              {(this.props.organization.typeId === 2 &&
                isPluginActive(platform, 'TaxReceipt') && (
                  <Plugin
                    name="TaxReceipt"
                    component="PublicInformationFields"
                    resolve={() =>
                      import(
                        /* webpackChunkName: "TaxReceipt" */ '../../plugin/plugins/TaxReceipt/TaxReceipt.jsx'
                      )
                    }
                    {...{
                      form: form,
                      provinces: this.props.provinces,
                      locale: locale,
                      organization: isCorporate,
                      savedValues: {
                        province: userInformation.province,
                        address: userInformation.address,
                        unit: userInformation.unit,
                        city: userInformation.city
                      },
                      dropdownClassName: 'overModal'
                    }}
                  />
                )) || (
                <>
                  <div className="CreateInformationForm__Divider AddressUnit">
                    <AddressField
                      className="CreateInformationForm__Field"
                      form={form}
                      label={false}
                      placeholder={true}
                      required={true}
                      initialValue={userInformation.address || undefined}
                      identity={isCorporate ? 'company' : 'user'}
                    />
                    <UnitField
                      className="CreateInformationForm__Field"
                      form={form}
                      label={false}
                      placeholder={true}
                      initialValue={userInformation.unit || undefined}
                      identity={isCorporate ? 'company' : 'user'}
                    />
                  </div>
                  <div className="CreateInformationForm__Divider CityProvince">
                    <CityField
                      className="CreateInformationForm__Field"
                      form={form}
                      label={false}
                      placeholder={true}
                      required={true}
                      initialValue={userInformation.city || undefined}
                      identity={isCorporate ? 'company' : 'user'}
                    />
                    <ProvinceField
                      className="CreateInformationForm__Field"
                      dropdownClassName="overModal"
                      form={form}
                      provinces={this.props.provinces}
                      locale={locale}
                      label={false}
                      placeholder={true}
                      required={
                        form.getFieldValue('country') === 'CA' ||
                        form.getFieldValue('country') === 'US'
                      }
                      initialValue={userInformation.province || undefined}
                      identity={isCorporate ? 'company' : 'user'}
                    />
                  </div>
                </>
              )}
              <div className="CreateInformationForm__Divider">
                <PostalCodeField
                  className="CreateInformationForm__Field"
                  form={form}
                  initialValue={userInformation.postalCode}
                  label={false}
                  placeholder={true}
                  required={true}
                  country={this.state.country}
                  identity={isCorporate ? 'company' : 'user'}
                />
              </div>
              <p>{t('preferred-language')}</p>
              <div className="CreateInformationForm__Divider">
                <LanguageField
                  className="CreateInformationForm__Field"
                  form={form}
                  savedValue={defaultLocale}
                  dropdownClassName="overModal"
                />
              </div>
            </Form>
          </FundkyModal>

          <div className="CreateInformationForm__SubmitWrapper">
            <Button className="Back-btn" onClick={this.handleBackClick}>
              {t('back')}
            </Button>
            <SubmitButton overwriteSubmit={this.handleSubmit} disabled={!isProfileComplete}>
              {t('next')}
            </SubmitButton>
          </div>
          <div className="CreateInformationForm__CancelWrapper">
            <Popconfirm
              placement="topLeft"
              getPopupContainer={() => document.getElementById('popContainer2')}
              title={t('DonationPage:cancelText')}
              onConfirm={this.handleCancelClick}
              okText={t('DonationPage:yes')}
              cancelText={t('DonationPage:no')}
            >
              <span id="popContainer2">{t('cancel')}</span>
            </Popconfirm>
          </div>
          {/*this.props.userTypeId === 1 &&
          <WinkTooltip
            title={t('Fundky account')}
            details={t('get connected')}
            elements={
              <Button className="WinkTooltip__CTA" onClick={this.handleWinkCTA}>
                {t('Connection')}
              </Button>
            }
            display={true}
          />
          */}
        </div>
      </div>
    );
  }
}

CreateInformationFormUser.propTypes = {
  form: PropTypes.object.isRequired,
  storeUserInformation: PropTypes.func.isRequired,
  history: PropTypes.object
};

export default translate('CreateInformationForm')(CreateInformationFormUser);
