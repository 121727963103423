import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { get } from 'lodash';

import dataFetcher from 'Parts/chore/dataFetcher';
import { reportParticipantsInCampaign } from '../../../report/reportActions';
import { searchParticipantsInCampaign, clearSearch } from '../../../search/searchActions';
import CampaignsParticipantsListPage from './CampaignsParticipantsListPage';

const mapStateToProps = state => ({
  platform: state.platform.platform,
  campaignId: state.campaign.campaign.id,
  campaign: state.campaign.campaign,
  participants: state.search.participants.results,
  pagination: state.search.participants.pagination,
  filters: state.search.participants.filterBy,
  search: state.search.participants.search,
  orderBy: state.search.participants.orderBy,
  locale: state.session.language,
  report: get(state, 'report.participants')
});

const mapDispatchToProps = dispatch => bindActionCreators({
  searchParticipantsInCampaign,
  reportParticipantsInCampaign,
  clearSearch
 }, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  dataFetcher
)(CampaignsParticipantsListPage);
