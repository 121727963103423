import { notification } from 'antd';
import i18next from 'i18next';
import { isEmpty, get } from 'lodash';

import {
  requestApiGet,
  requestApiPost,
  requestApiPut,
  requestApiDelete,
  // getErrorMessage
} from 'Parts/common/requestUtils';

import { createCreditCard } from '../../../donation/donationActions';

export const FETCH_EVENT_START = 'FETCH_EVENT_START';
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_ERROR = 'FETCH_EVENT_ERROR';

export const UPDATE_EVENT_START = 'UPDATE_EVENT_START';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_ERROR = 'UPDATE_EVENT_ERROR';

export const UPDATE_EVENT_SETTINGS_START = 'UPDATE_EVENT_SETTINGS_START';
export const UPDATE_EVENT_SETTINGS_SUCCESS = 'UPDATE_EVENT_SETTINGS_SUCCESS';
export const UPDATE_EVENT_SETTINGS_ERROR = 'UPDATE_EVENT_SETTINGS_ERROR';

export const CLEAR_EVENT = 'CLEAR_EVENT';

export const FETCH_TICKET_LIST_START = 'FETCH_TICKET_LIST_START';
export const FETCH_TICKET_LIST_SUCCESS = 'FETCH_TICKET_LIST_SUCCESS';
export const FETCH_TICKET_LIST_ERROR = 'FETCH_TICKET_LIST_ERROR';

export const FETCH_TICKET_START = 'FETCH_TICKET_START';
export const FETCH_TICKET_SUCCESS = 'FETCH_TICKET_SUCCESS';
export const FETCH_TICKET_ERROR = 'FETCH_TICKET_ERROR';

export const SAVE_TICKET_START = 'SAVE_TICKET_START';
export const SAVE_TICKET_SUCCESS = 'SAVE_TICKET_SUCCESS';
export const SAVE_TICKET_ERROR = 'SAVE_TICKET_ERROR';

export const UPDATE_TICKET_START = 'UPDATE_TICKET_START';
export const UPDATE_TICKET_SUCCESS = 'UPDATE_TICKET_SUCCESS';
export const UPDATE_TICKET_ERROR = 'UPDATE_TICKET_ERROR';

export const DELETE_TICKET_START = 'DELETE_TICKET_START';
export const DELETE_TICKET_SUCCESS = 'DELETE_TICKET_SUCCESS';
export const DELETE_TICKET_ERROR = 'DELETE_TICKET_ERROR';

export const CLEAR_TICKET = 'CLEAR_TICKET';

export const SAVE_TICKET_SELECTION = 'SAVE_TICKET_SELECTION';
export const CLEAR_TICKET_SELECTION = 'CLEAR_TICKET_SELECTION';

export const SAVE_PURCHASE_INFORMATION = 'SAVE_PURCHASE_INFORMATION';
export const CLEAR_PURCHASE_INFORMATION = 'CLEAR_PURCHASE_INFORMATION';

export const PURCHASE_TICKET_START = 'PURCHASE_TICKET_START';
export const PURCHASE_TICKET_SUCCESS = 'PURCHASE_TICKET_SUCCESS';
export const PURCHASE_TICKET_DECLINED = 'PURCHASE_TICKET_DECLINED';
export const PURCHASE_TICKET_ERROR = 'PURCHASE_TICKET_ERROR';

export const CANCEL_PURCHASE = 'CANCEL_PURCHASE';
export const CLEAR_PURCHASE = 'CLEAR_PURCHASE';

export const FETCH_PURCHASED_TICKET_LIST_START = 'FETCH_PURCHASED_TICKET_LIST_START';
export const FETCH_PURCHASED_TICKET_LIST_SUCCESS = 'FETCH_PURCHASED_TICKET_LIST_SUCCESS';
export const FETCH_PURCHASED_TICKET_LIST_ERROR = 'FETCH_PURCHASED_TICKET_LIST_ERROR';

export const CLEAR_PURCHASED_LIST = 'CLEAR_PURCHASED_LIST';

export const FETCH_PURCHASED_TICKET_START = 'FETCH_PURCHASED_TICKET_START';
export const FETCH_PURCHASED_TICKET_SUCCESS = 'FETCH_PURCHASED_TICKET_SUCCESS';
export const FETCH_PURCHASED_TICKET_ERROR = 'FETCH_PURCHASED_TICKET_ERROR';

export const UPDATE_PURCHASED_TICKET_START = 'UPDATE_PURCHASED_TICKET_START';
export const UPDATE_PURCHASED_TICKET_SUCCESS = 'UPDATE_PURCHASED_TICKET_SUCCESS';
export const UPDATE_PURCHASED_TICKET_ERROR = 'UPDATE_PURCHASED_TICKET_ERROR';

export const ATTEND_EVENT_START = 'ATTEND_EVENT_START';
export const ATTEND_EVENT_SUCCESS = 'ATTEND_EVENT_SUCCESS';
export const ATTEND_EVENT_ERROR = 'ATTEND_EVENT_ERROR';

export const FETCH_TICKET_RESERVE_START = 'FETCH_TICKET_RESERVE_START';
export const FETCH_TICKET_RESERVE_SUCCESS = 'FETCH_TICKET_RESERVE_SUCCESS';
export const FETCH_TICKET_RESERVE_ERROR = 'FETCH_TICKET_RESERVE_ERROR';

export const CREATE_TICKET_RESERVE_START = 'CREATE_TICKET_RESERVE_START';
export const CREATE_TICKET_RESERVE_SUCCESS = 'CREATE_TICKET_RESERVE_SUCCESS';
export const CREATE_TICKET_RESERVE_ERROR = 'CREATE_TICKET_RESERVE_ERROR';

export const CLEAR_TICKET_RESERVE = 'CLEAR_TICKET_RESERVE';

export const PURCHASE_OFFLINE_TICKET_START = 'PURCHASE_OFFLINE_TICKET_START';
export const PURCHASE_OFFLINE_TICKET_SUCCESS = 'PURCHASE_OFFLINE_TICKET_SUCCESS';
export const PURCHASE_OFFLINE_TICKET_ERROR = 'PURCHASE_OFFLINE_TICKET_ERROR';

export function fetchEvent(campaignId, authentication = false, params = {}) {
  return dispatch => {
    dispatch({
      type: FETCH_EVENT_START
    });

    // PARAMS
    // showLocation = true
    // showSettings = true

    const headers = {
      ...(authentication
        ? { authentication: 'true' }
        : {}
      )
    };

    return requestApiGet(`/campaigns/${campaignId}/event`, params, headers)
      .then(event => {
        return dispatch({ type: FETCH_EVENT_SUCCESS, payload: event });
      })
      .catch(error => {
        return dispatch({ type: FETCH_EVENT_ERROR, error });
      });
  }
}

export function updateEvent(campaignId, formValues) {
  return dispatch => {
    dispatch({ type: UPDATE_EVENT_START });

    const values = {
      ...(formValues.locationId
        ? { locationId: formValues.locationId }
        : {}
      ),
      ...(formValues.startOn
        ? { startOn: formValues.startOn }
        : {}
      ),
      ...(formValues.endOn
        ? { endOn: formValues.endOn }
        : {}
      )
    };

    if (!isEmpty(values))
      return requestApiPut(`/campaigns/${campaignId}/event`, values)
        .then(updated => {
          return dispatch(fetchEvent(campaignId, true, { showLocation: true, showSettings: true }));
        })
        .then(fetched => {
          notification.success({
            message: i18next.t('API_NOTIFICATIONS:success'),
            description: i18next.t('API_NOTIFICATIONS:modifications-saved'),
          });

          return dispatch({ type: UPDATE_EVENT_SUCCESS });
        })
        .catch(error => {
          const description = error.description || error;

          notification.error({
            message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
            description: description
          });

          return dispatch({ type: UPDATE_EVENT_ERROR, error: error });
        });
  };
}

export function updateEventSettings(campaignId, formValues) {
  return dispatch => {
    dispatch({ type: UPDATE_EVENT_SETTINGS_START });

    const values = {
      ...(formValues.attendee
        ? { attendee: formValues.attendee}
        : {}
      ),
      ...(formValues.ticketLimit || formValues.ticketLimit === null
        ? { ticket: {
            limit: formValues.ticketLimit
          }}
        : {}
      ),
      ...(formValues.taxReceipt === true || formValues.taxReceipt === false
          ? { taxReceipt: {
            enabled: formValues.taxReceipt
          }}
        : {}
      ),
    };

    if (!isEmpty(values))
      return requestApiPut(`/campaigns/${campaignId}/event/settings`, values)
        .then(updated => {
          return dispatch(fetchEvent(campaignId, true, { showLocation: true, showSettings: true }));
        })
        .then(fetched => {
          notification.success({
            message: i18next.t('API_NOTIFICATIONS:success'),
            description: i18next.t('API_NOTIFICATIONS:modifications-saved'),
          });

          return dispatch({ type: UPDATE_EVENT_SETTINGS_SUCCESS });
        })
        .catch(error => {
          const description = error.description || error;

          notification.error({
            message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
            description: description
          });

          return dispatch({ type: UPDATE_EVENT_SETTINGS_ERROR, error: error });
        });
  };
}

export function clearEvent() {
  return dispatch => {
    dispatch({ type: CLEAR_EVENT });
  }
}

export function fetchTicketList(campaignId, _params = {}, authentication = false) {
  return dispatch => {
    dispatch({ type: FETCH_TICKET_LIST_START });

    // PARAMS
    // filterBy = typeId ([1]: SINGLE, [2]: GROUP)
    // orderBy = price | addedOn ([asc] | [desc])
    // showDescription = true
    // showAdvantage = true

    let params = {
      // Until adding pagination correctly
      pageResults: 1000,
      ..._params
    };

    const headers = {
      ...(authentication ? {
        authentication: 'true'
      } : {})
    };

    return requestApiGet(`/campaigns/${campaignId}/event/tickets`, params, headers)
      .then(payload => dispatch({ type: FETCH_TICKET_LIST_SUCCESS, payload }))
      .catch(error => dispatch({ type: FETCH_TICKET_LIST_ERROR, error }));
  }
}

export function fetchTicket(campaignId, ticketId, params = {}, authentication = false) {
  return dispatch => {
    dispatch({
      type: FETCH_TICKET_START
    });

    // PARAMS
    // showDescription = true
    // showAdvantage = true
    // showMedia = true

    const headers = {
      ...(authentication ? {
        authentication: 'true'
      } : {})
    };

    return requestApiGet(`/campaigns/${campaignId}/event/tickets/${ticketId}`, params, headers)
      .then(ticket => {
        return dispatch({
          type: FETCH_TICKET_SUCCESS,
          payload: ticket
        })
      })
      .catch(error => {
        return dispatch({
          type: FETCH_TICKET_ERROR,
          error
        })
      });
  }
}

export function createTicket(campaignId, formValues, tickets) {
  return dispatch => {
    dispatch({ type: SAVE_TICKET_START });

    return requestApiPost(`/campaigns/${campaignId}/event/tickets`, formValues)
      .then(newTicket => Promise.all([
        ...tickets.map((id, index) => dispatch(updateTicket(
          campaignId,
          id,
          { weight: (tickets.length - index + 1) * 100 },
          null,
          null,
          null,
          false, // notif
          false,  // fetch
        )))
      ]))
      .then(updated => {
        return dispatch(fetchTicketList(campaignId, { showDescription: true }, true));
      })
      .then(list => {
        return dispatch({ type: SAVE_TICKET_SUCCESS });
      })
      .catch((error) => {
        const description = error.description || error;

        notification.error({
          message: i18next.t('API_NOTIFICATIONS:creation-failed'),
          description: description
        });

        return dispatch({ type: SAVE_TICKET_ERROR, error: error });
      });
  };
}

export function updateTicket(campaignId, ticketId, formValues = null, description = null, advantage = null, media = null, notif = true, fetch = true, list = false) {
  return dispatch => {
    dispatch({ type: UPDATE_TICKET_START });

    let _updateDescription, _updateAvantage, _updateMedia, _deleteDescription, _deleteAvantage, _deleteMedia;

    if (Array.isArray(description)) {
      description.forEach((desc, index) => {
        if (desc.name !== '' && desc.description !== '') {
          _updateDescription = [
            ...(Array.isArray(_updateDescription) ? _updateDescription : []),
            desc
          ];
        } else {
          _deleteDescription = [
            ...(Array.isArray(_deleteDescription) ? _deleteDescription : []),
            { languageId: desc.languageId }
          ];
        }
      });
    }

    if (Array.isArray(advantage)) {
      advantage.forEach((adv, index) => {
        if (adv.description !== '') {
          _updateAvantage = [
            ...(Array.isArray(_updateAvantage) ? _updateAvantage : []),
            adv
          ];
        } else {
          _deleteAvantage = [
            ...(Array.isArray(_deleteAvantage) ? _deleteAvantage : []),
            { languageId: adv.languageId }
          ];
        }
      });
    }

    if (Array.isArray(media)) {
      media.forEach((med, index) => {
        if (med.image !== null) {
          _updateMedia= { images: [
            ...(_updateMedia && Array.isArray(_updateMedia.images) ? _updateMedia.images : []),
            med
          ]};
        } else {
          _deleteMedia = [
            ...(Array.isArray(_deleteMedia) ? _deleteMedia : []),
            { languageId: med.languageId }
          ];
        }
      });
    }

    if (formValues || _updateDescription || _updateAvantage || _updateMedia || _deleteDescription || _deleteAvantage || _deleteMedia)
      return Promise.all([
        // UPDATE ticket
        ...(formValues
          ? [ requestApiPut(`/campaigns/${campaignId}/event/tickets/${ticketId}`, formValues) ]
          : []
        ),
        // UPDATE description
        ...(_updateDescription
          ? [ requestApiPut(`/campaigns/${campaignId}/event/tickets/${ticketId}/description`, _updateDescription) ]
          : []
        ),
        // UPDATE advantage
        ...(_updateAvantage
          ? [ requestApiPut(`/campaigns/${campaignId}/event/tickets/${ticketId}/advantage`, _updateAvantage) ]
          : []
        ),
        // UPDATE media
        ...(_updateMedia
          ? [ requestApiPut(`/campaigns/${campaignId}/event/tickets/${ticketId}/media`, _updateMedia) ]
          : []
        ),
        // DELETE description
        ...(_deleteDescription
          ? _deleteDescription.map(desc => requestApiDelete(`/campaigns/${campaignId}/event/tickets/${ticketId}/description`, desc))
          : []
        ),
        // DELETE advantage
        ...(_deleteAvantage
          ? _deleteAvantage.map(adv => requestApiDelete(`/campaigns/${campaignId}/event/tickets/${ticketId}/advantage`, adv))
          : []
        ),
        // DELETE media
        ...(_deleteMedia
          ? _deleteMedia.map(med => requestApiDelete(`/campaigns/${campaignId}/event/tickets/${ticketId}/media`, { images: med }))
          : []
        )
      ])
        .then(updated => {
          return Promise.all([
            ...(fetch
              ? [dispatch(fetchTicket(campaignId, ticketId, {showDescription: true, showAdvantage: true, showMedia:true }, true))]
              : []
            ),
            ...(list
              ? [dispatch(fetchTicketList(campaignId, { showDescription: true }, true))]
              : []
            )
          ]);
        })
        .then(fetched => {
          notif && notification.success({
            message: i18next.t('API_NOTIFICATIONS:success'),
            description: i18next.t('API_NOTIFICATIONS:modifications-saved'),
          });
          return dispatch({ type: UPDATE_TICKET_SUCCESS });
        })
        .catch((error) => {
          const description = error.description || error;
          notification.error({
            message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
            description: description
          });
          return dispatch({ type: UPDATE_TICKET_ERROR, error: error });
        });
  };
}

export function deleteTicket(campaignId, ticketId) {
  return dispatch => {
    dispatch({ type: DELETE_TICKET_START });

    return requestApiDelete(`/campaigns/${campaignId}/event/tickets/${ticketId}`)
      .then(deleted => dispatch(fetchTicketList(campaignId, { showDescription: true }, true)))
      .then(list => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved')
        });
        return dispatch({ type: DELETE_TICKET_SUCCESS });
      })
      .catch(error => {
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
          description: error.description || error
        });
        return dispatch({ type: DELETE_TICKET_ERROR, error: error });
      });
  };
}

export function clearTicket() {
  return dispatch => dispatch({ type: CLEAR_TICKET });
}

export function saveTicketsSelection(formValues) {
  return dispatch => dispatch({ type: SAVE_TICKET_SELECTION, payload: formValues });
}

export function clearTicketsSelection() {
  return dispatch => dispatch({ type: CLEAR_TICKET_SELECTION });
}

export function savePurchaseInformation(formValues) {
  return dispatch => dispatch({ type: SAVE_PURCHASE_INFORMATION, payload: formValues });
}

export function clearPurchaseInformation() {
  return dispatch => dispatch({ type: CLEAR_PURCHASE_INFORMATION });
}

export function processTransaction(information, cardInformation, target, stripeTools = null) {
  return dispatch => {
    dispatch({ type: "PURCHASE_TICKET_PROCESS_START" });
    if (stripeTools) {
      return stripeTools.stripe.createToken(stripeTools.cardElement)
        .then(function(result) {
          // Handle result.error or result.token
          if (!result.error) {
            const values = {
              tickets: information.tickets,
              ...(information.participation
                ? { participation: information.participation }
                : {}
              ),
              transaction: information.transaction || undefined,
              donationTypeId: information.donationTypeId || undefined,
              payment: {
                stripe: {
                  cardTokenId: result.token.id
                }
              },
              reCAPTCHA: information.reCAPTCHA
            };
            return dispatch(purchaseTickets(target.id, values));
          } else {
            return;
          }
        });
    } else {
      return dispatch(createCreditCard(cardInformation, information.transaction.information.email))
        .then(({ payload, error }) => {
          if (!error) {
            const values = {
              tickets: information.tickets,
              ...(information.participation
                ? { participation: information.participation }
                : {}
              ),
              transaction: information.transaction || undefined,
              donationTypeId: information.donationTypeId || undefined,
              payment: {
                wepay: {
                  creditCardId: payload.credit_card_id
                }
              },
              reCAPTCHA: information.reCAPTCHA
            };
            return dispatch(purchaseTickets(target.id, values));
          }
        });
    }
  }
}

export function purchaseTickets(campaignId, formValues = {}) {
  return dispatch => {
    dispatch({ type: PURCHASE_TICKET_START });

    return requestApiPost(`/campaigns/${campaignId}/event/tickets/purchase`, formValues)
      .then(purchase => {
        if (get(purchase, "checkout.transactionStatus", get(purchase, "transaction.status")) === 'APPROVED') {
          // notification.success({
          //   message: i18next.t('API_NOTIFICATIONS:success'),
          //   description: i18next.t('API_NOTIFICATIONS:transaction-approved')
          // });
          return dispatch({ type: PURCHASE_TICKET_SUCCESS, payload: purchase });
        } else {
          // notification.error({
          //   message: i18next.t('API_NOTIFICATIONS:error'),
          //   description: i18next.t('API_NOTIFICATIONS:transaction-failed')
          // });
          return dispatch({ type: PURCHASE_TICKET_DECLINED, payload: purchase });
        }
      })
      .catch(error => {
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
          description: error.description || error
        });
        return dispatch({ type: PURCHASE_TICKET_ERROR, error: error });
      });
  };
}

export function cancelTransaction() {
  return dispatch => dispatch({ type: CANCEL_PURCHASE });
}

export function clearPurchase() {
  return dispatch => dispatch({ type: CLEAR_PURCHASE });
}

export function fetchPurchasedTicketList(campaignId, params = {}, authentication = false) {
  return dispatch => {
    dispatch({ type: FETCH_PURCHASED_TICKET_LIST_START });

    // PARAMS
    // filterBy =
    // orderBy = addedOn || attendedOn ([asc] | [desc])
    // showDescription = true
    // showAdvantage = true

    const headers = {
      ...(authentication ? {
        authentication: 'true'
      } : {})
    };

    return requestApiGet(`/campaigns/${campaignId}/event/tickets/purchase`, params, headers)
      .then(payload => dispatch({ type: FETCH_PURCHASED_TICKET_LIST_SUCCESS, payload }))
      .catch(error => dispatch({ type: FETCH_PURCHASED_TICKET_LIST_ERROR, error }));
  };
}

export function clearPurchasedTicketList() {
  return dispatch => dispatch({ type: CLEAR_PURCHASED_LIST });
}

export function fetchPurchasedTicket(campaignId, purchaseId, params = {}, authentication = false) {
  return dispatch => {
    dispatch({ type: FETCH_PURCHASED_TICKET_START });

    const headers = {
      ...(authentication ? {
        authentication: 'true'
      } : {})
    };

    return requestApiGet(`/campaigns/${campaignId}/event/tickets/purchase/${purchaseId}`, params, headers)
      .then(payload => dispatch({ type: FETCH_PURCHASED_TICKET_SUCCESS, payload }))
      .catch(error => dispatch({ type: FETCH_PURCHASED_TICKET_ERROR, error }));
  };
}

export function updatePurchasedTicket(campaignId, purchaseId, formValues) {
  return dispatch => {
    dispatch({ type: UPDATE_PURCHASED_TICKET_START });

    return requestApiPut(`/campaigns/${campaignId}/event/tickets/purchase/${purchaseId}`, formValues)
      .then(payload => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved'),
        });
        dispatch(fetchPurchasedTicket(campaignId, purchaseId, true));
        return dispatch({ type: UPDATE_PURCHASED_TICKET_SUCCESS, payload });
      })
      .catch(error => {
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
          description: error.description || error
        });
        return dispatch({ type: UPDATE_PURCHASED_TICKET_ERROR, error });
      });
  };
}

export function attendEvent(campaignId, purchaseId) {
  return dispatch => {
    dispatch({ type: ATTEND_EVENT_START });

    return requestApiPut(`/campaigns/${campaignId}/event/tickets/purchase/${purchaseId}/attend`)
      .then(payload => {
        notification.success({
          message: i18next.t('API_NOTIFICATIONS:success'),
          description: i18next.t('API_NOTIFICATIONS:modifications-saved'),
        });
        return dispatch({ type: ATTEND_EVENT_SUCCESS })
      })
      .catch(error => {
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
          description: error.description || error
        });
        return dispatch({ type: ATTEND_EVENT_ERROR, error })
      });
  };
}

export function fetchTicketReserve(campaignId) {
  return dispatch => {
    dispatch({ type: FETCH_TICKET_RESERVE_START });

    return requestApiGet(`/campaigns/${campaignId}/event/tickets/reserve`)
      .then(event => {
        return dispatch({ type: FETCH_TICKET_RESERVE_SUCCESS, payload: event });
      })
      .catch(error => {
        return dispatch({ type: FETCH_TICKET_RESERVE_ERROR, error });
      });
  };
}

export function createTicketReserve(campaignId, values) {
  return dispatch => {
    dispatch({ type: CREATE_TICKET_RESERVE_START });

    return requestApiPost(`/campaigns/${campaignId}/event/tickets/reserve`, values)
      .then(reserve => {
        return dispatch(fetchTicketReserve(campaignId))
          .then(() => dispatch({ type: CREATE_TICKET_RESERVE_SUCCESS, payload: reserve }));
      })
      .catch(error => {
        notification.error({
          message: i18next.t('ERROR:'+ error.code),
        });
        dispatch(fetchTicketList(campaignId, { showDescription: true }));
        return dispatch({ type: CREATE_TICKET_RESERVE_ERROR, error });
      });
  };
}

export function clearTicketReserve() {
  return dispatch => {
    dispatch({ type: CLEAR_TICKET_RESERVE });
  };
}

export function purchaseOfflineTicket(campaignId, values) {
  return dispatch => {
    dispatch({ type: PURCHASE_OFFLINE_TICKET_START });

    return requestApiPost(`/campaigns/${campaignId}/event/tickets/purchase/offline`, values)
      .then(payload => {
        return dispatch({ type: PURCHASE_OFFLINE_TICKET_SUCCESS, payload: payload });
      })
      .catch(error => {
        //let message = error.code === 'FK-JOI-SCH-1000' ? : error.code;
        notification.error({
          message: i18next.t('ERROR:'+ error.code),
        });
        return dispatch({ type: PURCHASE_OFFLINE_TICKET_ERROR, error });
      });
  };
}
