import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Tooltip } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { get, has } from 'lodash';

import HeadMetas from '../../common/HeadMetasContainer';
import PublicTitleDropdown from '../../common/PublicTitleDropdownContainer';

import PublicThermometer from '../../common/PublicThermometer';
import PublicPanel from '../../common/PublicPanel';
import HelpText from '../../common/HelpText';
import PublicTeamPanel from '../components/PublicTeamPanelContainer';
import PublicParticipantPanel from '../../common/PublicParticipantPanelContainer';
import PublicDonationPanel from '../../common/PublicDonationPanelContainer';
import PublicPartnerPanel from '../components/PublicPartnerPanel';
import PublicPageClosed from '../../common/PublicPageClosed';
import SocialSharing from '../../common/SocialSharingContainer';
import PublicPageMobileScripts from '../../common/PublicPageMobileScripts';
import Can from 'Parts/session/components/CanContainer';
import Plugin from '../../plugin/Plugin';
import { isPluginActive } from '../../plugin/pluginActions';

import { isDateBeforeCurrentDate } from 'Parts/common/dateUtils';
import {
  CAMPAIGN_TEMPLATE_PARTICIPANTS,
  CAMPAIGN_TEMPLATE_SOLO,
  CAMPAIGN_TEMPLATE_TEAMS,
  CAMPAIGN_TEMPLATE_MIXED
} from '../campaignUtils';
import { getCdnUrl, getPlatformUrl } from 'Parts/common/environment';
import { getLanguageKeyByName } from 'Parts/common/langUtils';

import CampaignPublicPage_en from './locales/CampaignPublicPage_en.json';
import CampaignPublicPage_fr from './locales/CampaignPublicPage_fr.json';

import './CampaignPublicPage.less';

class CampaignPublicPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //TODO: Remove this temporary hardcoded campaign functionality
      hideDonation: props.campaign.id === 354 && props.platform.id === 77
    };

    i18next.addResourceBundle('en', 'CampaignPublicPage', CampaignPublicPage_en);
    i18next.addResourceBundle('fr', 'CampaignPublicPage', CampaignPublicPage_fr);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isUserLoggedIn !== this.props.isUserLoggedIn &&
      this.props.match.params.campaignAlias
    ) {
      this.props.fetchCampaign(
        this.props.match.params.campaignAlias,
        false,
        this.props.platformId,
        { showChildrenCount: true, showDescription: true, showMedia: true, showSettings: true }
      );
    }
  }

  render() {
    const { props, state } = this,
      { t, locale, campaign, location, platformAlias, platform } = props,
      { hideDonation } = state;

    const participantsLimit = get(campaign, 'settings.participant.limit', 0);
    const participantsCount = get(campaign, 'childrenCount.participants', 0);
    const teamsLimit = get(campaign, 'settings.team.limit', 0);
    const teamsCount = get(campaign, 'childrenCount.teams', 0);
    const campaignBanner = get(campaign, 'media.banner.banner', false)
      ? `${getCdnUrl()}${get(campaign, 'media.banner.banner', '')}`
      : null;

    const settingsThermometer = get(campaign, 'settings.thermometer.behavior');
    const showThermometer = settingsThermometer === 'all' || settingsThermometer === 'parent';

    const isCampaignClosed = campaign && campaign.statusId === 4 ? true : false;

    const isRegistrationDisabled = has(campaign, 'settings.registration') && get(campaign, 'settings.registration.disabledOn')
      ? isDateBeforeCurrentDate(campaign.settings.registration.disabledOn, true)
      : has(campaign, 'settings.registration')
        ? false
        : true;

    const isDonationsDisabled = get(campaign, 'settings.donation.disabledOn')
      ? isDateBeforeCurrentDate(campaign.settings.donation.disabledOn, true)
      : false;

    const participateLink =
      campaign.typeId !== CAMPAIGN_TEMPLATE_SOLO ? (
        campaign.participating ? (
          <Tooltip placement="top" title={t('already-in')}>
            <div>
              <Link
                disabled
                className="CampaignPublicPage__MenuLink"
                to={{
                  pathname: `/${locale}/${t('URL:participant-registration')}`,
                  state: { campaign: campaign }
                }}
              >
                <span className="CampaignPublicPage__MenuLinkIcon">
                  <FontAwesomeIcon icon={['fal', 'user-plus']} transform="right-2" />
                </span>
                {t('participate')}
              </Link>
            </div>
          </Tooltip>
        ) : campaign.typeId === CAMPAIGN_TEMPLATE_MIXED ||
          participantsLimit == 0 ||
          (campaign.typeId === CAMPAIGN_TEMPLATE_PARTICIPANTS &&
            participantsLimit > participantsCount) ||
          (campaign.typeId === CAMPAIGN_TEMPLATE_TEAMS &&
            (teamsLimit == 0 || participantsLimit * teamsLimit > participantsCount)) ? (
          <Link
            className="CampaignPublicPage__MenuLink"
            to={{
              pathname: `/${locale}/${t('URL:participant-registration')}`,
              state: { campaign: campaign }
            }}
          >
            <span className="CampaignPublicPage__MenuLinkIcon">
              <FontAwesomeIcon icon={['fal', 'user-plus']} transform="right-2" />
            </span>
            {t('participate')}
          </Link>
        ) : (
          <Tooltip placement="top" title={t('full')}>
            <div>
              <Link
                disabled
                className="CampaignPublicPage__MenuLink"
                to={{
                  pathname: `/${locale}/${t('URL:participant-registration')}`,
                  state: { campaign: campaign }
                }}
              >
                <span className="CampaignPublicPage__MenuLinkIcon">
                  <FontAwesomeIcon icon={['fal', 'user-plus']} transform="right-2" />
                </span>
                {t('participate')}
              </Link>
            </div>
          </Tooltip>
        )
      ) : null;

    const createTeamLink =
      campaign.typeId === CAMPAIGN_TEMPLATE_TEAMS ||
      campaign.typeId === CAMPAIGN_TEMPLATE_MIXED
        ? campaign.participating
          ? <Tooltip placement="top" title={t('already-in')}>
              <div>
                <Link
                  disabled
                  className="CampaignPublicPage__MenuLink"
                  to={{
                    pathname: `/${locale}/${t('URL:create-a-team')}`,
                    state: { campaign: campaign }
                  }}
                >
                  <span className="fa-layers CampaignPublicPage__MenuLinkIcon">
                    <FontAwesomeIcon icon={['fal', 'users']} transform="left-1" />
                    <FontAwesomeIcon icon={['far', 'plus']} transform="shrink-10 right-11 up-1" />
                  </span>
                  {t('create-team')}
                </Link>
              </div>
            </Tooltip>
          : teamsLimit == 0 ||
            teamsLimit > teamsCount
              ? <Link
                  className="CampaignPublicPage__MenuLink"
                  to={{
                    pathname: `/${locale}/${t('URL:create-a-team')}`,
                    state: { campaign: campaign }
                  }}
                >
                  <span className="fa-layers CampaignPublicPage__MenuLinkIcon">
                    <FontAwesomeIcon icon={['fal', 'users']} transform="left-1" />
                    <FontAwesomeIcon icon={['far', 'plus']} transform="shrink-10 right-11 up-1" />
                  </span>
                  {t('create-team')}
                </Link>
              : <Tooltip placement="top" title={t('full')}>
                  <div>
                    <Link
                      disabled
                      className="CampaignPublicPage__MenuLink"
                      to={{
                        pathname: `/${locale}/${t('URL:create-a-team')}`,
                        state: { campaign: campaign }
                      }}
                    >
                      <span className="fa-layers CampaignPublicPage__MenuLinkIcon">
                        <FontAwesomeIcon icon={['fal', 'users']} transform="left-1" />
                        <FontAwesomeIcon icon={['far', 'plus']} transform="shrink-10 right-11 up-1" />
                      </span>
                      {t('create-team')}
                    </Link>
                  </div>
                </Tooltip>
        : null;

    const buyTicketLink = campaign &&
      campaign.behaviorId === 2 &&
      campaign.typeId !== CAMPAIGN_TEMPLATE_SOLO &&
      isPluginActive(platform, 'Event')
        ? <Plugin
            name="Event"
            component="BuyTicketBtn"
            // resolve={() =>
            //   import(/* webpackChunkName: "Event" */ '../../plugin/plugins/Event/Event')
            // }
            size="2x"
            margin="1em 0"
            {...{
              // Props
              template: "menu",
              plural: false
            }}
          />
        : null;

    const teamPanel = campaign.typeId === CAMPAIGN_TEMPLATE_TEAMS ||
      campaign.typeId === CAMPAIGN_TEMPLATE_MIXED
        ? <PublicTeamPanel isClosed={isCampaignClosed} />
        : null;

    const participantPanel = campaign.typeId === CAMPAIGN_TEMPLATE_PARTICIPANTS
      ? <PublicParticipantPanel full="campaign" isClosed={isCampaignClosed} />
      : null;

    const urlToShare = `${getPlatformUrl(platformAlias)}${location.pathname}`;

    const descriptionLanguageKey = getLanguageKeyByName(get(campaign, 'description.language', ''));

    const showDescriptionLangNotice = descriptionLanguageKey
      ? descriptionLanguageKey !== locale
      : null;

    const langInNotice = showDescriptionLangNotice
      ? descriptionLanguageKey !== 'en'
        ? `${t(`LANGUAGES:${descriptionLanguageKey}`)}.`
        : `${t(`LANGUAGES:${descriptionLanguageKey}`).toLowerCase()}.`
      : null;

    const descriptionLangNotice = showDescriptionLangNotice
      ? <p className="info-notice-language">
          <FontAwesomeIcon icon={['fal', 'info-circle']} /> {t('notice-language')} {langInNotice}
        </p>
      : null;

    const descriptionText = get(campaign, 'description.description', null);

    const description = descriptionText
      ? ReactHtmlParser(descriptionText)
      : null;

    const showNoDescriptionNotice = !description
      ? <p className="info-notice-language">
          <FontAwesomeIcon icon={['fal', 'info-circle']} /> {t('notice-no-description')}
        </p>
      : null;

    const donateUnderThermo =
      this.props.isBillingConfigured
      !isCampaignClosed &&
      !isDonationsDisabled &&
      !hideDonation
        ? <NavLink
            className="CampaignPublicPage__MenuLink"
            to={{ pathname: `/${locale}/${campaign.alias}/${t('URL:give')}` }}
          >
            <span className="CampaignPublicPage__MenuLinkIcon">
              <FontAwesomeIcon icon={['fal', 'usd-circle']} />
            </span>
            {t('donate')}
          </NavLink>
        : null;

    const subscribeUnderThermo = !isCampaignClosed && !isRegistrationDisabled
      ? buyTicketLink
        ? buyTicketLink
        : <>
            {participateLink}
            {createTeamLink}
          </>
      : null;

    const donateSubscribeUnderThermo = donateUnderThermo ||
      subscribeUnderThermo
        ? <>
            {donateUnderThermo}
            {subscribeUnderThermo}
          </>
        : null;

    const campaignEnabled = Number(platform.settings.campaign.creation.enabled) || false;

    const campaignEditUrl = `/${locale}/d/campaigns/${campaign.id}`;

    const editCampaignLink = (
      <Can campaignId={campaign.id} rules={['roles.campaign.OWNER']}>
        <Link
          to={campaignEditUrl}
          className="edit-campaign"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('edit-campaign')}
        </Link>
      </Can>
    );

    return (
      <Layout className="CampaignPublicPage FundkyWrap">
        <HeadMetas />
        {campaignBanner && (
          <img
            className="CampaignPublicPage__Banner globalMobileOnly"
            src={campaignBanner}
            alt="Campaign Banner"
          />
        )}
        <div className="CampaignPublicPage__Menu" id="titleDropdown">
          <div className="CampaignPublicPage__TitleThermo">
            <HelpText>{t('campaign-label')}</HelpText>
            <PublicTitleDropdown
              helpLabel={t('last-campaigns-label')}
              title={campaign.name}
              entities={this.props.lastCampaigns.filter(_campaign => _campaign.id !== campaign.id)}
              getEntityLinkUrl={entity => `/${locale}/${entity.alias}`}
              additionalLinks={
                campaignEnabled ? (
                  <Link to={`/${locale}/${t('URL:create-a-campaign')}`}>
                    <FontAwesomeIcon icon={['fal', 'arrow-right']} />
                    {t('create-campaign')}
                  </Link>
                ) : null
              }
            />
            {(campaign && campaign.behaviorId === 2 && (
              <Plugin
                name="Event"
                component="PublicEventInfo"
                size="2x"
                margin="1em 0"
                {...{
                  // Props
                  className: 'CampaignPublicPage__EventInfo globalDesktopOnly',
                  locale: locale,
                  entity: campaign,
                  compact: true,
                  history: this.props.history,
                  modal: this.props.modal
                }}
              />
            )) || (
              <PublicThermometer
                className="CampaignPublicPage__Thermometer globalDesktopOnly"
                locale={locale}
                entity={campaign}
                compact
                giveUrl={{ pathname: `/${locale}/${campaign.alias}/${t('URL:give')}` }}
                donationClosed={isDonationsDisabled || isCampaignClosed}
				        showThermometer={showThermometer}
                isBillingConfigured={this.props.isBillingConfigured}
              />
            )}
            <PublicPageClosed location="Thermo" campaign={campaign} />
          </div>
          <div className="CampaignPublicPage__MenuNavSocialSharing globalDesktopOnly">
            <div className="CampaignPublicPage__MenuNav">
              <NavLink
                className="CampaignPublicPage__MenuLink"
                activeClassName="CampaignPublicPage__MenuLink--Active"
                to={location.pathname}
              >
                <span className="CampaignPublicPage__MenuLinkIcon">
                  <FontAwesomeIcon icon={['fal', 'home']} />
                </span>
                {t('information')}
              </NavLink>
              {/* <a className="CampaignPublicPage__MenuLink" href="/"><FontAwesomeIcon className="CampaignPublicPage__MenuLinkIcon" icon={["fal", "envelope"]} />{t('contact')}</a> */}
              {donateSubscribeUnderThermo}
            </div>
            <div className="CampaignPublicPage__SocialSharing">
              <SocialSharing identity={'campaign'} urlToShare={urlToShare} />
            </div>
          </div>
        </div>
        <div className="CampaignPublicPage__Content">
          <div className="globalDesktopOnly">
            <PublicPageClosed location={'Banner'} campaign={campaign} />
          </div>
          {campaignBanner && (
            <div className="CampaignPublicPage__BannerHolder">
              <img
                className="CampaignPublicPage__Banner globalDesktopOnly"
                src={campaignBanner}
                alt="Campaign Banner"
              />
              <Can
                campaignId={campaign.id}
                rules={['roles.campaign.OWNER']}
                key={this.props.userId}
              >
                <Link
                  to={campaignEditUrl}
                  className={`ant-btn ant-btn-primary Btn__EditBanner`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('edit-banner')}
                </Link>
              </Can>
            </div>
          )}
          {(campaign && campaign.behaviorId === 2 && showThermometer && (
              <Plugin
                name="Event"
                component="PublicEventThermometer"
                {...{
                  // Props
                  className: 'CampaignPublicPage__EventThermometer globalDesktopOnly',
                  locale: locale,
                  entity: campaign,
                  history: this.props.history,
                  giveUrl: { pathname: `/${locale}/${campaign.alias}/${t('URL:give')}` },
                  donationClosed: isDonationsDisabled || isCampaignClosed
                }}
              />
            ))}
          <PublicPageMobileScripts
            topPosition={campaign && campaign.behaviorId === 2 && (this.props.event && this.props.event.startOn) ? "eventWithDate" : campaign && campaign.behaviorId === 2 ? "eventWithoutDate": "normal"}
            donationURL={`/${locale}/${campaign.alias}/${t('URL:give')}`}
            hideButtons={isCampaignClosed}
            donationDisabled={isDonationsDisabled}
            registrationDisabled={isRegistrationDisabled}
            isSinglePageCampaign={campaign.typeId === CAMPAIGN_TEMPLATE_SOLO}
            pageType="campaign"
            entity={campaign}
            locale={locale}
            socialShare={
              <SocialSharing identity={'campaign'} urlToShare={urlToShare} showTitle={false} />
            }
            showThermometer={showThermometer}
            platform={platform}
            campaign={campaign}
            isBillingConfigured={this.props.isBillingConfigured}
          />
          <div className="globalMobileOnly">
            <PublicPageClosed location={'Banner'} campaign={campaign} />
            <PublicPageClosed location={'Thermo'} campaign={campaign} />
          </div>
          <PublicPanel title={t('about')} id="about" editAction={editCampaignLink}>
            {descriptionLangNotice}
            {showNoDescriptionNotice}
            {description ? (
              (Array.isArray(description) && typeof description[0] === 'string') ||
              typeof description === 'string' ? (
                <p>{description}</p>
              ) : (
                description
              )
            ) : null}
          </PublicPanel>
          {teamPanel}
          {participantPanel}
          {!hideDonation &&
          <PublicDonationPanel type="campaign" isClosed={isCampaignClosed} id="donors" />
          }
          {/* <PublicPartnerPanel /> */}
        </div>
      </Layout>
    );
  }
}

CampaignPublicPage.propTypes = {
  campaign: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  locale: PropTypes.oneOf(['fr', 'en']).isRequired,
  lastCampaigns: PropTypes.array.isRequired
};

CampaignPublicPage.defaultProps = {
  campaign: {
    description: null,
    descriptions: {
      french: {
        description: null
      },
      english: {
        description: null
      }
    }
  }
};

export default translate('CampaignPublicPage')(CampaignPublicPage);
