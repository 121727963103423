import React from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox, Select } from 'antd';
import CheckboxField from 'Parts/ui/components/fields/CheckboxField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { getDonationAmount, setAssociatedPPFField } from './../../../common/donationAmount';

import PPFField_en from './locales/PPFField_en.json';
import PPFField_fr from './locales/PPFField_fr.json';

class PPFField extends React.Component {
  
  constructor(props) {
    super(props);

    this.checked = false;

    setAssociatedPPFField(this);

    i18next.addResourceBundle('en', 'PPFField', PPFField_en);
    i18next.addResourceBundle('fr', 'PPFField', PPFField_fr);
  }
  
  managePPF = chk => {  
    let _self = this;
    if(chk.target.checked){
      _self.checked = true;
      _self.ppf();
    }else{
        _self.checked = false;
        _self.regularTotal();
    }
    _self.updateDonationAmountPreview()
  }

  payerPaysFee(amount){
    var fixed = parseFloat(gFees.fixed);
    var perc = ((gFees.perc)/100);
  
    var total = (Number(amount) + fixed) / (1 - perc);
  
    return {
      goal: amount,
      total: total,
      difference: +(total-amount).toFixed(2)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let _self = this;
    // PPF
    let amountOptions = document.querySelector("#amount").childNodes;

    // The position #4 corresponds to the custom amount field.
    amountOptions[4].firstChild.firstChild.addEventListener('keyup', this.onDonationAmountUpdated);
  }

  componentDidMount(prevProps, prevState) {
    var ppfAmounts = this.payerPaysFee(0);
    document.querySelectorAll('.js-additional-fees').forEach(e => e.innerText = "-");
    this.regularTotal();
  }

  onDonationAmountUpdated() {
    if(this.checked)
      this.ppf();
    else
      this.regularTotal();
    this.updateDonationAmountPreview();
  }

  toMoney = amount => {
    if(!amount){
      amount = 0;
    }
    amount = amount *1;
    var value = (amount != 0) ? amount : 0;
    value = value.toFixed(2);
    var n = value.toString().split('.');
    var num = n[0];
      var dec = n[1];
    if (num.length > 4){
      num = num.split('').reverse().join('');
      num = num.replace(/(\d{3})/g, "$1,");
      num = num.split('').reverse().join('');
      num = num.replace(/^\s+/g, '');
      num = num.replace(/^,/g, ' ');
    }
    // if (dec == '00'){
    // 	dec = null
    // }

    value = num + (dec != null? '.'+dec: '');
    try {
      this.lang = i18next.language;// for front-end javascript
    } catch(e) {
      console.log(e);
    }

    if (this.lang == 'fr') value = value.replace(/,/g, ' ').replace('.', ',');
    return this.lang == 'en' ? '$'+value : value + ' $';
  }


  updateDonationAmountPreview = async function(){
    let amount = getDonationAmount();
    if(this.checked){
      var ppfAmounts = this.payerPaysFee(amount);
      amount = ppfAmounts.total;
      amount = (Math.round(amount * 100) / 100).toFixed(2);
    }
    document.querySelector('.js-finalAmount').innerText = amount; 
  }

  ppf = function(){
      var goal = getDonationAmount() *1;
      var ppfAmounts = this.payerPaysFee(goal);
      if(goal > 0) {
        document.querySelectorAll('.js-additional-fees').forEach(e => e.innerText = this.toMoney(ppfAmounts.difference));
        document.querySelector('.js-fees').innerText = this.props.t('amount') + ' : ' + this.toMoney(goal) + ' + ' + this.props.t('transaction_fees') + ' : ' + this.toMoney(ppfAmounts.difference) + ' '
        document.querySelector('.js-total-fees').innerText = this.props.t('total') + ' : ' + this.toMoney(ppfAmounts.total);
      }
  }

  getTotalAmount() {
    if (this.checked) {
      let fees =  this.payerPaysFee(getDonationAmount())
      return fees ? fees.total : fees;
    }
    else
      return getDonationAmount();
  }

  regularTotal() {
    if(getDonationAmount() * 1 > 0)
      document.querySelectorAll('.js-additional-fees').forEach(e => e.innerText = this.toMoney(this.payerPaysFee(getDonationAmount() *1).difference));
    else
      document.querySelectorAll('.js-additional-fees').forEach(e => e.innerText = "-");
    document.querySelector('.js-fees').innerText = "";
    document.querySelector('.js-total-fees').innerText = this.props.t('total') + ' : ' + this.toMoney(getDonationAmount());
  }

  render() {
    const { t, form, className } = this.props;
    const { getFieldDecorator } = form;

    const placeholder = this.props.fieldFormat === 'select' ?
      (
        this.props.placeholder === true ?
          t('anonymous') :
          this.props.placeholder || null
      ) : null;
    
    const input =  
    <CheckboxField
        form={form}
        label={t('payers_pay_fees')}
        tooltip={t('payers_pay_fees_info')}
        onChange={this.managePPF}
        className="labelCheckbox js-ppf-option"
        labelCol={{ style: { order: 2 } }}
    />

    return (
      <Form.Item
        className={`PPFField ${className} ppfRow`}
        label={null}
      >
        {input}
      </Form.Item>
    );
  }

}

PPFField.defaultProps = {
  fieldFormat: 'checkbox',
  label: true,
  placeholder: false,
  initialValue: undefined,
};

PPFField.propTypes = {
  form: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  fieldFormat: PropTypes.oneOf(['checkbox', 'select'])
};

export default translate('PPFField')(PPFField);
