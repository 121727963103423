import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'antd';
import { translate } from 'react-i18next';
import i18next from 'i18next';
import { setDonationAmount, reset } from './../../../common/donationAmount';

import AmountOptions_en from './locales/AmountOptions_en.json';
import AmountOptions_fr from './locales/AmountOptions_fr.json';

import './AmountOptions.less';
import { set } from 'lodash';
import { faTreasureChest } from '@fortawesome/pro-light-svg-icons';

class AmountOptions extends React.Component {
  constructor(props) {
    super(props);

    i18next.addResourceBundle('en', 'AmountOptions', AmountOptions_en);
    i18next.addResourceBundle('fr', 'AmountOptions', AmountOptions_fr);

    // Resets donation amount.
    reset();

    // Update value with savedValue
    if(props.savedValue) {
      props.onChange(props.savedValue);
    }

    this.handleOtherAmountInputChange = this.handleOtherAmountInputChange.bind(this);
  }

  handleOptionClick(value, event) {
    event.preventDefault();
    this.props.onChange(value);
    setDonationAmount(value);
    if(this.props.custom) {
      document.querySelectorAll('.AmountOptions__OtherAmountInput input')[0].value = '';
      document.querySelectorAll('.AmountOptions__OtherAmountInput input')[0].classList.toggle('AmountOptions__Option--Selected', false);
    }
  }

  handleOtherAmountInputChange(event) {
    event.preventDefault();

    const { value, classList } = event.target;
    const parsedFValue = parseFloat(value);

    const match = /^[0-9]{1,5}([\.]{1}[0-9]{1,2})?$/g;

    if (value.match(match)
     && parsedFValue >= 5 // TODO get custom limit
     && parsedFValue <= 10000) { // TODO get custom limit
      this.props.onChange(Number(value));
      setDonationAmount(Number(value));
      classList.toggle('AmountOptions__Option--Selected', true);
    } else {
      this.props.onChange(null);
      setDonationAmount(0);
      classList.toggle('AmountOptions__Option--Selected', false);
    }
  }

  render() {
    const { t, locale, value, savedValue } = this.props;

    const amounts = this.props.amounts || [20, 50, 60, 90];

    const options = amounts.map(amount => (
      <Button
        key={`option${amount}`}
        className={classnames('AmountOptions__Option', { 'AmountOptions__Option--Selected': value === amount || savedValue === amount })}
        type={value === amount ? 'primary' : 'default'}
        size="large"
        onClick={this.handleOptionClick.bind(this, amount)}
      >
        {`${locale == 'en' ? '$' : ""}${amount}${locale == 'fr' ? ' $' : ""}`}
      </Button>
    ));

    const customAmount = savedValue ? (amounts.indexOf(savedValue) == -1 ? savedValue : null) : null;

    return (
      <div className={`AmountOptions AmountOptions-${locale == 'en' ? 'left' : 'right'}`} id="amount">
        {options}
        {this.props.custom &&
          <Input
            className="AmountOptions__OtherAmountInput"
            placeholder={t('other')}
            onChange={this.handleOtherAmountInputChange}
            addonAfter="$"
            defaultValue={customAmount}
          />
        }
      </div>
    );
  }
}

AmountOptions.defaultProps = {
  onChange: () => {},
  savedValue: null
};

AmountOptions.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  savedValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
};

export default translate('AmountOptions')(AmountOptions);
