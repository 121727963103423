import React from 'react';

import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './FundkyTooltip.less';

class FundkyTooltip extends React.Component {

  render() {
    const { title, className, popupContainer, placement } = this.props;

    return (
      <Tooltip
        title={<div dangerouslySetInnerHTML={{__html: title}}/>}
        className={classnames('Fundky__Tooltip', className)}
        overlayClassName="Fundky__Tooltip__Overlay"
        getPopupContainer={popupContainer}
        placement={placement}
        trigger={['click']}
      >
        <FontAwesomeIcon icon={["fas", "info-circle"]} />
      </Tooltip>
    );
  }

}

FundkyTooltip.defaultProps = {
  className: null,
  title: '',
  placement: 'top',
  popContainer: () => {
    document.body
  }
};

FundkyTooltip.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  className: PropTypes.string,
  popUpContainer: PropTypes.func,
  placement: PropTypes.string
};

export default FundkyTooltip;
