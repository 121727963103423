import { notification } from 'antd';
import i18next from 'i18next';
import { isEmpty, get } from 'lodash';

import {
  requestApiPost,
  requestApiGet,
  requestApiPut,
  requestApiDelete
} from 'Parts/common/requestUtils';
import { getSessionIp } from 'Parts/session/sessionUtils';
import { fetchTeams } from '../team/teamActions';
import { getLanguageIdByKey } from 'Parts/common/langUtils';
import { moneyToNumber } from 'Parts/common/moneyFormat';

export const FETCH_PARTICIPANT_START = 'FETCH_PARTICIPANT_START';
export const FETCH_PARTICIPANT_SUCCESS = 'FETCH_PARTICIPANT_SUCCESS';
export const FETCH_PARTICIPANT_ERROR = 'FETCH_PARTICIPANT_ERROR';

export const UPDATE_PARTICIPANT_START = 'UPDATE_PARTICIPANT_START';
export const UPDATE_PARTICIPANT_SUCCESS = 'UPDATE_PARTICIPANT_SUCCESS';
export const UPDATE_PARTICIPANT_ERROR = 'UPDATE_PARTICIPANT_ERROR';

export const FETCH_PARTICIPANT_DESCRIPTION_START = 'FETCH_PARTICIPANT_DESCRIPTION_START';
export const FETCH_PARTICIPANT_DESCRIPTION_SUCCESS = 'FETCH_PARTICIPANT_DESCRIPTION_SUCCESS';
export const FETCH_PARTICIPANT_DESCRIPTION_ERROR = 'FETCH_PARTICIPANT_DESCRIPTION_ERROR';

export const FETCH_PARTICIPANT_SETTINGS_START = 'FETCH_PARTICIPANT_SETTINGS_START';
export const FETCH_PARTICIPANT_SETTINGS_SUCCESS = 'FETCH_PARTICIPANT_SETTINGS_SUCCESS';
export const FETCH_PARTICIPANT_SETTINGS_ERROR = 'FETCH_PARTICIPANT_SETTINGS_ERROR';

export const FETCH_PARTICIPANT_LOGS_START = 'FETCH_PARTICIPANT_LOGS_START';
export const FETCH_PARTICIPANT_LOGS_SUCCESS = 'FETCH_PARTICIPANT_LOGS_SUCCESS';
export const FETCH_PARTICIPANT_LOGS_ERROR = 'FETCH_PARTICIPANT_LOGS_ERROR';

export const FETCH_PARTICIPANTS_START = 'FETCH_PARTICIPANTS_START';
export const FETCH_PARTICIPANTS_SUCCESS = 'FETCH_PARTICIPANTS_SUCCESS';
export const FETCH_PARTICIPANTS_ERROR = 'FETCH_PARTICIPANTS_ERROR';

export const FETCH_INDIVIDUAL_PARTICIPANTS_START = 'FETCH_INDIVIDUAL_PARTICIPANTS_START';
export const FETCH_INDIVIDUAL_PARTICIPANTS_SUCCESS = 'FETCH_INDIVIDUAL_PARTICIPANTS_SUCCESS';
export const FETCH_INDIVIDUAL_PARTICIPANTS_ERROR = 'FETCH_INDIVIDUAL_PARTICIPANTS_ERROR';

export const SAVE_PARTICIPANT_CREATE_START = 'SAVE_PARTICIPANT_CREATE_START';
export const SAVE_PARTICIPANT_CREATE_SUCCESS = 'SAVE_PARTICIPANT_CREATE_SUCCESS';
export const SAVE_PARTICIPANT_CREATE_ERROR = 'SAVE_PARTICIPANT_CREATE_ERROR';

export const FETCH_MEMBER_PARTICIPATIONS_START = 'FETCH_MEMBER_PARTICIPATIONS_START';
export const FETCH_MEMBER_PARTICIPATIONS_SUCCESS = 'FETCH_MEMBER_PARTICIPATIONS_SUCCESS';
export const FETCH_MEMBER_PARTICIPATIONS_ERROR = 'FETCH_MEMBER_PARTICIPATIONS_ERROR';

export const FETCH_MEMBER_PARTICIPATION_START = 'FETCH_MEMBER_PARTICIPATION_START';
export const FETCH_MEMBER_PARTICIPATION_SUCCESS = 'FETCH_MEMBER_PARTICIPATION_SUCCESS';
export const FETCH_MEMBER_PARTICIPATION_ERROR = 'FETCH_MEMBER_PARTICIPATION_ERROR';

export const UI_PARTICIPANT_CAMPAIGN_SELECT = 'UI_PARTICIPANT_CAMPAIGN_SELECT';
export const UI_PARTICIPANT_CAMPAIGN_CLEAR = 'UI_PARTICIPANT_CAMPAIGN_CLEAR';
export const UI_PARTICIPANT_INFORMATION_SELECT = 'UI_PARTICIPANT_INFORMATION_SELECT';

export const CLEAR_PARTICIPANT = 'CLEAR_PARTICIPANT';

export const PARTICIPANT_SAVE_SUCCESS = 'PARTICIPANT_SAVE_SUCCESS';

export function fetchCampaignParticipants(campaignId, _params = {}) {
  return dispatch => {
    dispatch({ type: FETCH_PARTICIPANTS_START });

    const params = {
      pageResults: 999999,
      ..._params
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/campaigns/${campaignId}/participants`,
      params,
      headers
    )
      .then(result => {
        const payload = result.results.map(participant => {
          return {
            ...participant,
            goal: Number(get(participant, 'goal', 0).toString().replace('NaN', 0)),
            collected: Number(get(participant, 'collected', 0).toString().replace('NaN', 0)),
            firstName: participant.firstname, // TODO camelCase ?
            lastName: participant.lastname
          };
        });

        return dispatch({
          type: FETCH_PARTICIPANTS_SUCCESS,
          payload: payload
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PARTICIPANTS_ERROR,
          error
        });
      });
  };
}

export function fetchTeamParticipants(teamId, _params = {}) {
  return dispatch => {
    dispatch({ type: FETCH_PARTICIPANTS_START });

    const params = {
      pageResults: 999999,
      ..._params
    };

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/teams/${teamId}/participants`,
      params,
      headers
    )
      .then(result => {
        // TODO the cast to number in the API
        const payload = result.results.map(participant => ({
          ...participant,
          goal: Number(get(participant, 'goal', 0).toString().replace('NaN', 0)),
          collected: Number(get(participant, 'collected', 0).toString().replace('NaN', 0)),
          firstName: participant.firstname, // TODO camelCase ?
          lastName: participant.lastname
        }));

        return dispatch({
          type: FETCH_PARTICIPANTS_SUCCESS,
          payload: payload
        });
      })
      .catch(error => {
        return dispatch({
          type: FETCH_PARTICIPANTS_ERROR,
          error
        });
      });
  };
}

export function fetchParticipant(
  idOrAlias,
  authentication = false,
  campaignId = null,
  params = {}
) {
  return dispatch => {
    dispatch({
      type: FETCH_PARTICIPANT_START
    });

    //NB: campaignId is required only if participant Alias is used.

    //PARAMS
    // showMember=true (member info was moved ot the member object)
    // showDescription=true
    // showSettings=true

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/participants/${idOrAlias}${campaignId ? `/${campaignId}` : ''}`,
      params,
      headers
    )
      .then(participant => {
        // TODO the cast to Number in the API
        const goal = Number(get(participant, 'settings.goal', 0)) || null;
        const payload = {
          ...participant,
          ...(goal
            ? {
                goal: goal
              }
            : {}),
          collected: Number(get(participant, 'collected', 0).toString().replace('NaN', 0))
        };

        return dispatch({
          type: FETCH_PARTICIPANT_SUCCESS,
          payload: payload
        });
      })
      .catch(error =>
        dispatch({
          type: FETCH_PARTICIPANT_ERROR,
          error
        })
      );
  };
}

export function fetchParticipantByMemberId(
  authentication = false,
  campaignId = null,
  params = {}
) {
  return dispatch => {
    dispatch({
      type: FETCH_PARTICIPANT_START
    });

    //NB: campaignId is required only if participant Alias is used.

    //PARAMS
    // showMember=true (member info was moved ot the member object)
    // showDescription=true
    // showSettings=true

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/campaigns/${campaignId}/participant`,
      params,
      headers
    )
      .then(participant => {
        // TODO the cast to Number in the API
        const goal = Number(get(participant, 'settings.goal', 0)) || null;
        const payload = {
          ...participant,
          ...(goal
            ? {
                goal: goal
              }
            : {}),
          collected: Number(get(participant, 'collected', 0).toString().replace('NaN', 0))
        };

        return dispatch({
          type: FETCH_PARTICIPANT_SUCCESS,
          payload: payload
        });
      })
      .catch(error =>
        dispatch({
          type: FETCH_PARTICIPANT_ERROR,
          error
        })
      );
  };
}

export function updateParticipant(formValues, participantId, locale = 'en') {
  return dispatch => {
    dispatch({
      type: UPDATE_PARTICIPANT_START
    });

    const settings = formValues.participant_goal
      ? {
          goal: moneyToNumber(formValues.participant_goal) || undefined
        }
      : null;

    const description = formValues.participant_description
      ? [
          {
            description:
              formValues.participant_description === '<p><br></p>'
                ? null
                : formValues.participant_description,
            languageId: getLanguageIdByKey(locale)
          }
        ]
      : null;

    const values = {
      teamId: formValues.participant_team || undefined,
      avatar: formValues.file || (formValues.file === null ? null : undefined),
      statusId: formValues.statusId || undefined,
      rejectReason: formValues.rejectReason || undefined
    };

    let key;
    for (key in values) {
      if (values[key] === undefined) {
        delete values[key];
      }
    }

    if (!isEmpty(values) || !isEmpty(description) || !isEmpty(settings)) {
      const headers = {
        ...dispatch(getSessionIp())
      };

      return Promise.all([
        ...(!isEmpty(values)
          ? [requestApiPut(
              `/participants/${participantId}`,
              values,
              headers
            )]
          : []),
        ...(!isEmpty(description) && description[0].description
          ? [requestApiPut(
              `/participants/${participantId}/description`,
              description,
              headers
            )]
          : []),
        ...(!isEmpty(description) && description[0].description === null
          ? [requestApiDelete(
              `/participants/${participantId}/description`,
              { languageId: description.languageId },
              headers
            )]
          : []),
        ...(!isEmpty(settings)
          ? [requestApiPut(
              `/participants/${participantId}/settings`,
              settings,
              headers
            )]
          : []),
      ])
        .then(updated => {
          return Promise.all([
            dispatch(
              fetchParticipant(participantId, true, null, {
                showMember: true
              })
            ),
            dispatch(fetchParticipantDescription(participantId, true)),
            dispatch(fetchParticipantSettings(participantId, true))
          ]);
        })
        .then(fetched => {
          notification.success({
            message: i18next.t('API_NOTIFICATIONS:success'),
            description: i18next.t('API_NOTIFICATIONS:modifications-saved')
          });
          return dispatch({
            type: UPDATE_PARTICIPANT_SUCCESS
          });
        })
        .catch(error => {
          const description = error.description || error;
          notification.error({
            message: i18next.t('API_NOTIFICATIONS:modifications-failed'),
            description: description
          });
          return dispatch({
            type: UPDATE_PARTICIPANT_ERROR,
            error: error
          });
        });
    }
  };
}

export function fetchParticipantDescription(id, authentication = false) {
  return dispatch => {
    dispatch({ type: FETCH_PARTICIPANT_DESCRIPTION_START });

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/participants/${id}/description`,
      null,
      headers
    )
      .then(description => dispatch({ type: FETCH_PARTICIPANT_DESCRIPTION_SUCCESS, payload: description }))
      .catch(error => dispatch({ type: FETCH_PARTICIPANT_DESCRIPTION_ERROR, error }));
  }
}

export function fetchParticipantSettings(id, authentication = false) {
  return dispatch => {
    dispatch({ type: FETCH_PARTICIPANT_SETTINGS_START });

    const headers = {
      ...(authentication ? { authentication: 'true' } : {}),
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/participants/${id}/settings`,
      null,
      headers
    )
      .then(settings => dispatch({ type: FETCH_PARTICIPANT_SETTINGS_SUCCESS, payload: settings }))
      .catch(error => dispatch({ type: FETCH_PARTICIPANT_SETTINGS_ERROR, error }));
  }
}

export function fetchParticipantLogs(id) {
  return dispatch => {
    dispatch({ type: FETCH_PARTICIPANT_LOGS_START });

    const headers = {
      authentication: 'true',
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/participants/${id}/logs`,
      { pageResults: 100 },
      headers
    )
      .then(logs => dispatch({ type: FETCH_PARTICIPANT_LOGS_SUCCESS, payload: logs }))
      .catch(error => dispatch({ type: FETCH_PARTICIPANT_LOGS_ERROR, error }));
  }
}

export function fetchMemberParticipations(memberId, platformId) {
  return dispatch => {
    dispatch({ type: FETCH_MEMBER_PARTICIPATIONS_START });

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiGet(
      `/members/${memberId}/participations/${platformId}`,
      null,
      headers
    )
      .then(result => dispatch({ type: FETCH_MEMBER_PARTICIPATIONS_SUCCESS, payload: result }))
      .catch(error => dispatch({ type: FETCH_MEMBER_PARTICIPATIONS_ERROR, error }));
  }
}

export function selectCampaign(campaign) {
  return dispatch => {
    dispatch(
      fetchTeams(campaign.id, false, {
        showChildrenCount: true
      })
    );
    return dispatch({
      type: UI_PARTICIPANT_CAMPAIGN_SELECT,
      payload: campaign
    });
  };
}

export function createParticipant(formValues, campaignId) {
  return dispatch => {
    dispatch({
      type: SAVE_PARTICIPANT_CREATE_START,
      payload: {
        formValues,
        campaignId
      }
    });

    const teamId = formValues.teamId
      ? {
          teamId: formValues.teamId
        }
      : null;

    const values = {
      campaignId,
      //communicationApproval: formValues.communicationApproval || false,
      ...teamId
    };

    //delete values.communicationApproval; // TODO Should we store that ? (we store the acceptedTermsConditions)
    //delete values.participationType; // This field is only for UI purpose

    const headers = {
      ...dispatch(getSessionIp())
    };

    return requestApiPost(
      '/participants',
      values,
      headers
    )
      .then(participant => {
        dispatch({ type: SAVE_PARTICIPANT_CREATE_SUCCESS, payload: participant });
      })
      .catch(error => {
        const description = error.description || error;
        notification.error({
          message: i18next.t('API_NOTIFICATIONS:creation-failed'),
          description: description
        });
        return dispatch({
          type: SAVE_PARTICIPANT_CREATE_ERROR,
          error: error
        });
      });
  };
}

export function participantInfoAdd(formValues, campaignId) {
  return dispatch => {
    dispatch({ type: UI_PARTICIPANT_INFORMATION_SELECT });
    dispatch({ type: PARTICIPANT_SAVE_SUCCESS, payload: {formValues, campaignId} });
  }
}

export function clearSelectedCampaign() {
  return dispatch => {
    dispatch({
      type: UI_PARTICIPANT_CAMPAIGN_CLEAR
    });
  };
}

export function clearParticipant() {
  return dispatch => {
    dispatch({
      type: CLEAR_PARTICIPANT
    });
  };
}
